import { useState } from 'react';
import { CollapsedAreaLabels, CommonArea, TopBottomPadding } from 'Components/Common';
import BackgroundImage from "Components/BackgroundImage";
import ColorPicker from "Components/ColorPicker";
import FormFloatingLabel from "Components/FloatingInput";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import InputPrefix from "Components/PrefixInput";
import CustomSelect from "Components/CustomSelect";
import ToolTip from 'Components/Tooltip';

import { BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { setDesignOptions } from "Store/slices/EditFormSlice";


const Header = () => {
    const dispatch = useDispatch();

    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);

    const [HideHeadlineText, setHideHeadlineText] = useState(false);
    const [showBgImg, setShowBgImg] = useState(false);
    const [headlineFontWeight, setHeadlineFontWeight] = useState([
        { value: 400, label: '400' },
        { value: 500, label: '500' },
        { value: 600, label: '600' },
        { value: 700, label: '700' },
        { value: 900, label: '900' },
    ]);
    return (
        <>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Hide Header (Popup)
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <MaterialDesignSwitch
                            defaultchecked={designOptions?.header?.hideHeader}
                            id={"Hide_Header"}
                            checked={(checked) => {
                                dispatch(setDesignOptions({ key: "header", data: { hideHeader: checked } }));
                            }}
                        />

                    </div>

                </div>

            </TopBottomPadding>
            {designOptions?.header?.hideHeader === false ? <div>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Hide Headline Text (Popup)
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform `}>
                            <MaterialDesignSwitch defaultchecked={HideHeadlineText} id={"HideHeadlineText"} checked={(checked) => setHideHeadlineText(checked)} />

                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <FormFloatingLabel onInput={(value) => { dispatch(setDesignOptions({ key: "header", data: { headlineText: value } })) }} label={"Headline Text"} defaultValue={designOptions.header.headlineText} />

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Background Color
                            <div className='ms-2'>
                                <ToolTip text={"You can change the top color (also known as the header color) of your form by adjusting the top background color setting."} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {

                                dispatch(setDesignOptions({ key: "header", data: { headlineBgColor: color } }))
                            }} defaultValue={designOptions.header.headlineBgColor} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div onClick={() => setShowBgImg(!showBgImg)} className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Background Image
                            <div className='ms-2'>
                                <ToolTip text={`To add a background image to your form, simply expand the field and upload an image and click Save. NOTE!! To see the image you may have to adjust the transparency of your bottom background color.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>

                            <CommonArea className={`${showBgImg === true ? "rotateit" : ""}`}>
                                <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                            </CommonArea>
                        </div>

                    </div>

                </TopBottomPadding>
                {
                    showBgImg === true ?
                        <TopBottomPadding>
                            <BackgroundImage getimg={(img) => dispatch(setDesignOptions({ key: "header", data: { headlineBgimg: img } }))} preview={designOptions.header.headlineBgimg} />
                        </TopBottomPadding>
                        : null
                }
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Headline Font Color
                            <div className='ms-2'>
                                <ToolTip text={`This setting adjusts the headline text color at the top of your form. For example, if the headline is "Get a Free Quote" then the font color of the free quote text will change.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {

                                dispatch(setDesignOptions({ key: "header", data: { headlineFontColor: color } }))
                            }} defaultValue={designOptions.header.headlineFontColor} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Headline Font Size
                            <div className='ms-2'>
                                <ToolTip text={`This setting adjusts the headline text size at the top of your form. For example, if the headline is "Get a Free Quote" then the font size of the free quote text will change.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <InputPrefix defaultValue={designOptions.header.headlineFontSize} onInput={(value) => dispatch(setDesignOptions({ key: "header", data: { headlineFontSize: value } }))} prefix={"pixels"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Headline Font Size Mobile

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <InputPrefix prefix={"pixels"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Headline Font Weight

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform   w-50`}>
                            <CustomSelect defaultValue={designOptions.header.headlineFontWeight} onChange={(selected) => dispatch(setDesignOptions({ key: "header", data: { headlineFontWeight: selected } }))} data={headlineFontWeight} name={"headlineFontWeight"} />


                        </div>

                    </div>

                </TopBottomPadding>
            </div>
                : null}
        </>
    );
}

export default Header;