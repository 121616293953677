import { useState } from 'react';
import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import InputPrefix from "Components/PrefixInput";
import CustomSelect from "Components/CustomSelect";
import ToolTip from 'Components/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setDesignOptions } from 'Store/slices/EditFormSlice';



const SubmitButton = () => {
    const dispatch = useDispatch();

    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);

    const [submitButtonFontWeight, setSubmitButtonFontWeight] = useState([
        { value: 400, label: '400' },
        { value: 500, label: '500' },
        { value: 600, label: '600' },
        { value: 700, label: '700' },
        { value: 900, label: '900' },
    ]);

    return (
        <>

            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Width
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the color of the submit button, which is applied to form field steps only. (i.e, zip code, name, email, phone, etc)`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.submitButton.submitButtonWidth} onInput={(value) => dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonWidth: value } }))} prefix={"%"} />



                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Width Mobile
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the color of the submit button, which is applied to form field steps only. (i.e, zip code, name, email, phone, etc)`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.submitButton.submitButtonWidthMobile} onInput={(value) => dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonWidthMobile: value } }))} prefix={"%"} />



                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Color
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the color of the submit button, which is applied to form field steps only. (i.e, zip code, name, email, phone, etc)`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonColor: color } }))
                        }} defaultValue={designOptions.submitButton.submitButtonColor} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Color On Hover
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the hover color of the submit button, which is applied to form field steps only. (i.e, name, email, phone, etc)`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonColorOnHover: color } }))
                        }} defaultValue={designOptions.submitButton.submitButtonColorOnHover} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Border Color
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the border color of the submit button, which is applied to form field steps only. (i.e, name, email, phone, etc)`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonBorderColor: color } }))
                        }} defaultValue={designOptions.submitButton.submitButtonBorderColor} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Text Color
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the text color of the submit button, which is applied to form field steps only. (i.e, name, email, phone, etc)`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonTextColor: color } }))
                        }} defaultValue={designOptions.submitButton.submitButtonTextColor} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Text Color On Hover
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the hover text color of the submit button, which is applied to form field steps only. (i.e, name, email, phone, etc)`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonTextColorOnHover: color } }))
                        }} defaultValue={designOptions.submitButton.submitButtonTextColorOnHover} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Font Size
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the font size of the submit button, which is applied to form field steps only. (i.e, name, email, phone, etc)`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.submitButton.submitButtonFontSize} onInput={(value) => dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonFontSize: value } }))} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Font Size Mobile
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the font size of the submit button on mobile, which is applied to form field steps only. (i.e, name, email, phone, etc)`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.submitButton.submitButtonFontSizeOnMobile} onInput={(value) => dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonFontSizeOnMobile: value } }))} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Font Weight
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the font thickness of your response button text.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <CustomSelect defaultValue={designOptions.submitButton.submitButtonFontWeight} onChange={(selected) => dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonFontWeight: selected } }))} data={submitButtonFontWeight} name={"submitButtonFontWeight"} />



                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Submit Button Additional Text Color

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonAdditionalTextColor: color } }))
                        }} defaultValue={designOptions.submitButton.submitButtonAdditionalTextColor} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Buttons Border Radius
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the border radius of the submit button. Which is applied to form field steps only. Increase the value of the border radius to make your buttons round. Decrease the value to make your buttons square.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.submitButton.submitButtonBorderRadius} onInput={(value) => dispatch(setDesignOptions({ key: "submitButton", data: { submitButtonBorderRadius: value } }))} prefix={"pixels"} />

                    </div>

                </div>

            </TopBottomPadding>

        </>
    );
}

export default SubmitButton;