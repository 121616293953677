import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AxiosLite } from 'Helpers';
import { SignInManager } from 'Helpers/Authentication/SignInManager';
import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { setActivenavbar } from "Store/slices/EditFormSlice";
import styled from 'styled-components';

export const Navbar = (props: { onlyLogo?: boolean }) => {
    const { onlyLogo } = props;
    const { formId } = useParams();
    const navigate = useNavigate();
    const activeIndex = useSelector((state: AppStore) => state.editor.activeNavBar)
    const dispatch = useDispatch();

    const setActiveIndex = (key: FormActiveNav) => {
        dispatch(setActivenavbar(key));
    }
    return (
        <Navcontainer>
            <Navbox centerLogo={onlyLogo}>
                <Logocontainer>
                    <Link to={'/'}>
                        <Logobox>
                            Lead Page Magnet
                        </Logobox>
                    </Link>
                </Logocontainer>
                {!onlyLogo &&
                    <><Menucontainer>
                        <Menubox>
                            <Menulist>
                                <MenuItem>
                                    <Menulink className={activeIndex === 'Design' ? 'active' : undefined} onClick={() => setActiveIndex("Design")}>
                                        Design
                                    </Menulink>
                                    <Menulink className={activeIndex === 'Steps' ? 'active' : undefined} onClick={() => setActiveIndex("Steps")}>
                                        Steps
                                    </Menulink>
                                    <Menulink className={activeIndex === 'Settings' ? 'active' : undefined} onClick={() => setActiveIndex("Settings")}>
                                        Settings
                                    </Menulink>
                                    <Menulink className={activeIndex === 'LeadDelivery' ? 'active' : undefined} onClick={() => setActiveIndex("LeadDelivery")}>
                                        LeadDelivery
                                    </Menulink>
                                    <Menulink className={activeIndex === 'Publish' ? 'active' : undefined} onClick={() => setActiveIndex("Publish")}>
                                        Publish
                                    </Menulink>
                                </MenuItem>
                            </Menulist>
                        </Menubox>
                    </Menucontainer>
                        <Headrightcontainer>
                            <Headrightbox>
                                <Headright>
                                    {/* <Rightmenubox>
                                        <Rightmenu>
                                            <Rightmenuitem>
                                                Form view
                                            </Rightmenuitem>
                                            <Rightmenuitem style={{
                                                borderTopLeftRadius: '0',
                                                borderBottomLeftRadius: '0',
                                            }}>
                                                Flow View
                                            </Rightmenuitem>
                                            <Rightmenuitem style={{
                                                borderTopLeftRadius: '0',
                                                borderBottomLeftRadius: '0',
                                                borderTopRightRadius: '8px',
                                                borderBottomRightRadius: '8px',
                                            }}>
                                                Metrics
                                            </Rightmenuitem>
                                        </Rightmenu>
                                    </Rightmenubox> */}
                                    <Headerbtnbox>
                                        <Headerbtn>
                                            <Button variant='contained'>
                                                <Link to={`/${formId}/viewer?mode=view`} target='_blank' className='text-white text-decoration-none'>Preview</Link>
                                            </Button>
                                        </Headerbtn>
                                        <Headerbtn>
                                            <Button
                                                variant='contained'
                                                className='head-right-btn'
                                                onClick={e => {
                                                    AxiosLite('/api/Forms/CopyForms|POST',
                                                        {
                                                            formId: formId,
                                                            clientId: new SignInManager().ParseJwt.sub
                                                        },
                                                        navigate,
                                                        dispatch
                                                    ).then(res => {
                                                        const a = document.createElement('a');
                                                        a.target = '_blank';
                                                        a.href = `/editForm/${res?.data.data}`;
                                                        a.click();
                                                        a.remove();
                                                    });
                                                }}
                                            >Duplicate Form</Button>
                                        </Headerbtn>
                                        <Closebtnbox className='pointer-event'>
                                            <Tooltip title={'Close'} onClick={e => navigate('/', { replace: true })}>
                                                <IconButton>
                                                    <IoCloseSharp className='text-white' fontSize={20} />
                                                </IconButton>
                                            </Tooltip>
                                        </Closebtnbox>
                                    </Headerbtnbox>
                                </Headright>
                            </Headrightbox>
                        </Headrightcontainer>
                    </>
                }
            </Navbox>
        </Navcontainer>
    );
}

const Navcontainer = styled.div`
background: rgb(8, 7, 61);
padding: 6px 20px 10px;
height: 63px;
border-bottom: 1px solid black;
`
const Navbox = styled.div`
box-sizing: border-box;
display: flex;
flex: 0 1 auto;
flex-flow: row wrap;
-webkit-box-align: center;
-webkit-box-pack: justify;
justify-content: ${(props: { centerLogo?: boolean }) => props.centerLogo ? "center" : "space-between"};
align-items: center;
margin-right: -0.5rem;
margin-left: -0.5rem;
`
const Logocontainer = styled.div`
box-sizing: border-box;
flex: 0 0 auto;
padding-right: 0.5rem;
padding-left: 0.5rem;
`
const Logobox = styled.div`
text-align: center;
`
// const Logo = styled.img`
// width: 200px;
// height: auto;
// cursor: pointer;
// margin-bottom: 0px;
// `
const Menucontainer = styled.div`
    width: 33.3333%;
    display: block;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    @media (min-width:1800px){
        width: 25%;
    }
`
const Menubox = styled.div`
box-sizing: border-box;
    width: 100%;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    -webkit-box-pack: start;
    justify-content: flex-start;
`
const Menulist = styled.div`
width: 100%;
box-sizing: border-box;
margin-right: -0.5rem;
margin-left: -0.5rem;
`
const MenuItem = styled.div`
width: 100%;
box-sizing: border-box;
display: flex;
flex: 0 1 auto;
flex-flow: row wrap;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: space-between;
padding: 12px 5px;
cursor: pointer;
padding-right: 0.5rem;
padding-left: 0.5rem;
`
const Menulink = styled.div`
color: rgb(255, 255, 255);
border-width: 3px;
border-style: solid;
${props => props.className === `active` ? `
border-image: 
  linear-gradient(
    to right, #E80A89 0%, #F15B2A 90%
  ) 0 0 100% 0;

  ` : ``}
transition: all 0.16s ease-in-out 0s;
padding-bottom: 2px;
border-color: transparent;
font-weight: bold;
text-shadow: rgb(255 255 255 / 50%) 0px 0px 1px;
`
const Headrightcontainer = styled.div`
width: 45%;
display: block;
box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    @media (min-width: 1800px){
        width: 35%;
    }
`
const Headrightbox = styled.div`
margin: 0px 15px 0px 0px;
    padding: 0px 10px;
    height: 50px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
`
const Headright = styled.div`
box-sizing: border-box;
display: flex;
flex: 0 1 auto;
flex-flow: row wrap;
justify-content:space-between;
margin-right: -0.5rem;
margin-left: -0.5rem;
`
const Rightmenubox = styled.div`
display: block;
box-sizing: border-box;
flex: 0 0 auto;
padding-right: 0.5rem;
padding-left: 0.5rem;
`
// const Rightmenu = styled.div`
// box-sizing: border-box;
//     display: flex;
//     flex: 0 1 auto;
//     flex-flow: row wrap;
//     -webkit-box-pack: start;
//     justify-content: flex-start;
// `
// const Rightmenuitem = styled.div`
// padding: 6px 11px;
// cursor: pointer;
// color: rgb(255, 255, 255);
// font-weight: 500;
// background-color: rgb(11, 33, 90);
// border-top-left-radius: 8px;
// border-bottom-left-radius: 8px;
// `
const Headerbtnbox = styled.div`
box-sizing: border-box;
display: flex;
flex: 0 1 auto;
flex-flow: row wrap;
-webkit-box-pack: end;
justify-content: flex-end;
margin-right: -0.5rem;
margin-left: -0.5rem;
`
const Headerbtn = styled.div`
box-sizing: border-box;
flex: 0 0 auto;
padding-right: 0.5rem;
padding-left: 0.5rem;
`
const Closebtnbox = styled.div`
display: flex;
align-items: center;
`