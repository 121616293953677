export const ValueNumberFormatOptions: LabelValuePair<NumberFormats>[] = [
    {
        value: "Plain", label: "100000"
    },
    {
        value: "Fixed", label: "100000.00"
    },
    {
        value: "Comma", label: "100,000"
    },
    {
        value: "CommaFixed", label: "100,000.00"
    },
    {
        value: "Quoted", label: "100'000"
    },
    {
        value: "QuotedFix", label: "100'000.00"
    },
]
export const FormatNumberToString = (value: number, format: NumberFormats) => {
    switch (format) {
        case 'Plain':
            return value.toLocaleString();
        case 'Fixed':
            return value.toFixed(2);
        case 'Comma':
            return value.toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        case 'CommaFixed':
            return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        case 'Quoted':
            return value.toLocaleString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1'`);
        case 'QuotedFix':
            return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1'`);
        default:
            return value.toString();
    }
}