import InlineEdit from '@atlaskit/inline-edit';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Flexbetween } from 'Components/Common';
import { AxiosLite, CallAxios, useDebounce } from 'Helpers';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Link, HashRouter as Router, LinkProps } from "react-router-dom";
import { SignInManager } from 'Helpers/Authentication/SignInManager';
import { confirmAlert } from 'react-confirm-alert';

const Forms = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [query, setQuery] = useDebounce('', 800);
    const [status, setStatus] = useState('All');
    const [domain, setDomain] = useState('');
    const [openModel, setOpenModel] = useState(false);

    const [clients, setClients] = useState<UserModel[]>([]);
    const [client, setClient] = useState(new SignInManager().ParseJwt.sub);
    React.useEffect(() => {
        if(new SignInManager().UserRole == 'Agent'){
                navigate("/leads");
        }
        AxiosLite('/api/Clients/ListUsers|GET', undefined, navigate, dispatch).then(s => {
            setClients(s?.data.list || []);
        })
    }, []);

    const isAdmin = new SignInManager().UserRole == 'Admin';

    const [tabledata, setTabledata] = useState<FormsTableData[]>([]);
    const LoadList = () => {
        CallAxios('/api/Forms/List|POST',
            {
                query,
                status,
                domain,
                clientId: client || new SignInManager().ParseJwt.sub
            },
            undefined,
            undefined,
            navigate,
            dispatch).then(res => {
                setTabledata(res?.data?.list || []);
            });
    }
    React.useEffect(() => {
        LoadList();
    }, [query, status, domain, client]);

    //const [preBindResponse] = useAxios<ApiResponse<PreBindFormsPageData>>('/api/General/PreBindFormsPage|GET');
    const [preBind, setPrebind] = useState<PreBindFormsPageData>({
        status: [],
        domains: []
    });
    const [categories, setCategories] = useState<KeyPairValue<string>[]>([]);

    React.useEffect(() => {
        CallAxios('/api/General/GetCategories|GET',
            undefined,
            undefined,
            undefined,
            navigate,
            dispatch)
            .then(res => {
                setCategories(res?.data.data || []);
            });
    }, []);

    React.useEffect(() => {
        AxiosLite('/api/General/PreBindFormsPage|GET', undefined, navigate, dispatch).then(res => {
            if (res?.data.data)
                setPrebind(res?.data.data);
        });
    }, []);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<SetTemplateModel>({
        defaultValues: {
            categoryId: '',
            description: '',
            formId: '',
            name: '',
        },
        mode: 'all',
        reValidateMode: 'onChange'
    });

    const DeleteForm = (id: string) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'This will permanently delete the form and all its leads.\n\rThis action can`t be undone.',
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: 'Yes',
                    className: 'bg-danger',
                    onClick: _ => {
                        AxiosLite('/api/Forms/DeleteForm|DELETE', { id: id }, navigate, dispatch)
                            .then(res => {
                                toast(res?.data.message, { type: res?.data.success ? 'success' : 'error' });
                                setTimeout(() => {
                                    LoadList();
                                }, 500);
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: _ => {
                        return
                    }
                }
            ]
        })
    }

    return (
        <>
            <Row>
                <Col md={6}>
                    <h2>{tabledata?.length} of {tabledata?.length} LeadForms</h2>
                </Col>
                <Col className='text-end' md={6}>
                    {
                        new SignInManager().UserRole == 'Admin' &&
                        <Button sx={{
                            textTransform: 'none'
                        }}
                            variant="contained"
                            color='primary'
                            onClick={(e) => navigate("/templates")}>Create LeadForm</Button>
                    }
                </Col>
                <Col md={6} className="mt-4">
                    <Flexbetween className='mb-2'>
                        <Selectbox>
                            <FloatingLabel controlId="floatingSelect" label="Showing" className="lead-floating-label">
                                <Form.Select onChange={e => setStatus(e.target.value)} value={status}>
                                    {
                                        preBind.status.map((s, i) =>
                                            <option key={s.value + '_' + i} value={s.value}>{s.label}</option>
                                        )
                                    }
                                </Form.Select>
                            </FloatingLabel>
                        </Selectbox>
                        <Selectbox>
                            <FloatingLabel controlId="floatingSelect" className="lead-floating-label" label="Sorted by">
                                <Form.Select onChange={e => setDomain(e.target.value)} value={domain}>
                                    <option value={''}>All Domains</option>
                                    {
                                        preBind.domains.map((s, i) =>
                                            <option key={s.value + '_' + i} value={s.value}>{s.label}</option>
                                        )
                                    }
                                </Form.Select>
                            </FloatingLabel>
                        </Selectbox>
                        {
                            new SignInManager().UserRole == 'Admin' &&
                            <div className='w-25'>
                                <FloatingLabel label="Client" className="lead-floating-label">
                                    <Form.Select value={client} onChange={e => setClient(e.target.value)}>
                                        <option value={new SignInManager().ParseJwt.sub}>Self</option>
                                        {
                                            clients.map(s =>
                                                <option key={'a_' + s.id} value={s.id}>{s.name}</option>
                                            )
                                        }
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                        }
                    </Flexbetween>

                </Col>
                <Col md={12} className="ms-auto">
                    <FloatingLabel
                        controlId="PageUrl"
                        label="Search by LeadForm name / page URL"
                        className="lead-floating-label">
                        <Form.Control size='sm' onChange={e => setQuery(e.target.value)} />
                    </FloatingLabel>
                </Col>
            </Row>

            <Row>
                {
                    tabledata.map((x, i) => (
                        <Col key={'cards_list_' + i} className='mt-4' md={12} lg={6} xl={6} xxl={6}>
                            <Card className='rounded-3'>
                                <Card.Body className='FCB-p'>
                                    <Row>
                                        <Col md={10}>
                                            <CardTitleItem x={x} />
                                            <Domainlabel>{x.domain}</Domainlabel>
                                        </Col>
                                        <Col md={2}>
                                            <Formstatus>{x.status}</Formstatus>
                                        </Col>
                                        <Col md={12}>
                                            <FormDetails className='mt-4'>
                                                <Formbuttonbox>
                                                    <MyButton
                                                        size='small'
                                                        className='text-capitalize mb-1 me-1'
                                                        LinkComponent={Link}
                                                        to={'/editForm/' + x.id}
                                                        variant='contained'>
                                                        View Form
                                                    </MyButton>
                                                    <MyButton
                                                        size='small'
                                                        className='text-capitalize mb-1'
                                                        LinkComponent={Link}
                                                        to={'/leads/' + x.id}
                                                        variant='contained'
                                                        color='success'>
                                                        View Leads
                                                    </MyButton>
                                                    {
                                                        isAdmin && <>
                                                            <br />
                                                            <Button
                                                                size='small'
                                                                className='text-capitalize mb-1 me-1'
                                                                onClick={e => {
                                                                    setValue('formId', x.id);
                                                                    setOpenModel(true);
                                                                }}
                                                                variant='contained' color='secondary'>Set Template</Button>
                                                            <Button
                                                                size='small'
                                                                className='text-capitalize mb-1'
                                                                onClick={e => {
                                                                    DeleteForm(x.id);
                                                                }}
                                                                variant='contained' color='error'>Delete</Button>
                                                        </>
                                                    }
                                                </Formbuttonbox>
                                                <FormDetailContent>
                                                    <Formdetailitem>
                                                        <p className='mb-0 fw-bold font-15'>{x.engagements}</p>
                                                        <p className='mb-0 font-12'>Engagements</p>
                                                    </Formdetailitem>
                                                    <Formdetailitem className='px-2'>
                                                        <p className='mb-0 fw-bold font-15'>{x.leads}</p>
                                                        <p className='mb-0 font-12'>Leads</p>
                                                    </Formdetailitem>
                                                    <Formdetailitem className='px-2'>
                                                        <p className='mb-0 fw-bold font-15'>{x.convrate}</p>
                                                        <p className='mb-0 font-12'>Conv Rate</p>
                                                    </Formdetailitem>
                                                    <Formdetailitem className='px-2 border-0'>
                                                        <p className='mb-0 fw-bold font-15'>{x.leadvalue || 'N/A'}</p>
                                                        <p className='mb-0 font-12'>Lead Value</p>
                                                    </Formdetailitem>
                                                </FormDetailContent>
                                            </FormDetails>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
                {
                    tabledata.length === 0 && <p className='text-danger'>No forms found</p>
                }
            </Row>

            <Modal
                centered
                show={openModel}
                onHide={() => setOpenModel(false)}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Template Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit((v, e) => {
                        console.log(v, e);
                        if (e && e.target) {
                            const formData = new FormData(e.target);
                            formData.append('formId', v.formId.toString());
                            CallAxios('/api/Forms/MarkAsTemplate|POST',
                                formData,
                                undefined,
                                undefined,
                                navigate,
                                dispatch).then(res => {
                                    toast(res?.data.message);
                                    LoadList();
                                })
                        }
                    }, e => {
                        console.log(e);
                    })}>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    {...register('name', {
                                        required:
                                            { value: true, message: 'Required' }
                                    })}
                                    error={!!errors['name']?.message}
                                    label='Template name'
                                    className='w-100 mb-2' />
                            </Col>
                            <Col md={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        {...register('categoryId', {
                                            required:
                                                { value: true, message: 'Required' }
                                        })}
                                        error={!!errors['categoryId']?.message}
                                        label='Category'
                                        className='w-100 mb-2'>
                                        <MenuItem value=''>Please Select</MenuItem>
                                        {
                                            categories.map((s, i) =>
                                                <MenuItem key={'category_' + i} value={s.key}>{s.value}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col md={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        {...register('image', {
                                            required:
                                                { value: true, message: 'Required' }
                                        })}
                                        error={!!errors['image']?.message}
                                        type={'file'}
                                        className='w-100 mb-2' />
                                </FormControl>
                            </Col>
                            <Col md={12}>
                                <TextField
                                    {...register('description', {
                                        required:
                                            { value: true, message: 'Required' }
                                    })}
                                    error={!!errors['description']?.message}
                                    label='Template Description'
                                    className='w-100 mb-2'
                                    multiline
                                    rows={4} />
                            </Col>
                        </Row>
                        <Button className='ms-auto' variant="contained" type='submit'>Submit</Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color='error' className={'me-2'} onClick={_ => setOpenModel(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default Forms;

const CardTitleItem = (props: {
    x: FormsTableData
}) => {
    const navigate = useNavigate();

    const { x } = props;

    const [editValue, setEditValue] = useState(x.title);

    React.useEffect(() => {
        setEditValue(x.title);
    }, [x, x.title]);

    useEffect(() => {
        if (editValue && x.title != editValue)
            AxiosLite('/api/Forms/RenameForm|PATCH',
                {
                    id: x.id,
                    name: editValue
                },
                navigate);
    }, [editValue]);

    return (
        <InlineEdit
            defaultValue={editValue}
            onConfirm={setEditValue}
            editView={(fieldProps, ref) => (
                <input
                    className='form-control'
                    type='text'
                    value={fieldProps.value}
                    id={fieldProps.id}
                    name={fieldProps.name}
                    onBlur={fieldProps.onBlur}
                    onChange={(v) => {
                        if (v.target instanceof HTMLInputElement)
                            fieldProps.onChange(v.target.value)
                    }}
                    onFocus={fieldProps.onFocus}
                    ref={ref} />
            )}
            readView={() => (
                <Formtitle>{editValue}</Formtitle>
            )} />
    );
}

const Selectbox = styled.div`
width:250px;
`
const Formtitle = styled.div`
color: rgb(36, 35, 40);
font-size: 18px;
font-weight: 700;
`
const Formstatus = styled.div`
display: inline-block;
color: rgb(32, 200, 117);
border: 1px solid rgb(32, 200, 117);
padding: 1px 10px 4px;
text-align: center;
border-radius: 20px;
font-size: 12px;
`
const Domainlabel = styled.div`
color: rgb(163, 167, 194);
font-weight: 300;
font-size: 14px;
`
const FormDetails = styled.div`
box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
`
const Formbuttonbox = styled.div`
flex-basis: 30%;
max-width: 30%;
display: block;
`
const FormDetailContent = styled.div`
box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
`
const Formdetailitem = styled.div`
text-align: center;
    color: rgb(145, 150, 182);
    border-right: 1px solid rgb(238, 238, 238);
    padding-right: 10px;
`;

const MyButton = styled(Button)<LinkProps>(({ theme }) => ({
    ':hover': {
        color: '#fff'
    }
}));