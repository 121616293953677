import { SettingsCollapse } from "commonJSON";
import { CollapseTrigger, CommonArea, SideBarHeaderWrapper, TopBottomPadding } from 'Components/Common';
import MaterialDesignSwitch from "Components/MaterialSwitch";
import ToolTip from 'Components/Tooltip';
import { AxiosLite, CallAxios } from 'Helpers';
import { useState } from 'react';
import { BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setSettings } from "Store/slices/EditFormSlice";
import styled from 'styled-components';
import Avatartab from "./Sidebars/SettingsTab/Avatartab";
import CustomizeErrorText from "./Sidebars/SettingsTab/CustomizeErrorText";
import FormMode from "./Sidebars/SettingsTab/FormMode";
import GeneralTab from "./Sidebars/SettingsTab/GeneralTab";
import GoogleAnalytics from "./Sidebars/SettingsTab/GoogleAnalytics";
import LeadValue from "./Sidebars/SettingsTab/LeadValue";
import OnsiteMessages from "./Sidebars/SettingsTab/OnsiteMessages";
import PopupBehavior from "./Sidebars/SettingsTab/PopupBehavior";
import Recaptcha from "./Sidebars/SettingsTab/Recaptcha";

const CollapseTriggerHeading = styled.div`
font-size: 17px;

`
const CollapsedArea = styled.div`
padding: 0px 35px;
    position: relative;
    font-size: 16px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
    background: rgb(247, 247, 247);
`



const Settings = () => {
    const { formId } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settings = useSelector((state: AppStore) => state.editor.settings);

    const [SettingsList, setSettingsList] = useState(SettingsCollapse);

    const showCollapsedDiv = (index: number) => {
        const newData = [...SettingsList];
        newData[index].isCollapsed = !newData[index].isCollapsed;
        setSettingsList(newData)
    }
    const LoadComponent = {
        'General': <GeneralTab dispatch={dispatch} settings={settings} setSettings={setSettings} />,
        "Form Mode (Popup or Embedded)": <FormMode dispatch={dispatch} settings={settings} setSettings={setSettings} />,
        "Popup behavior": <PopupBehavior settings={settings} setSettings={setSettings} />,
        "Onsite Message": <OnsiteMessages dispatch={dispatch} settings={settings} setSettings={setSettings} />,
        "Avatar": <Avatartab dispatch={dispatch} settings={settings} setSettings={setSettings} />,
        "Google Analytics": <GoogleAnalytics dispatch={dispatch} settings={settings} setSettings={setSettings} />,
        "Lead Value": <LeadValue dispatch={dispatch} settings={settings} setSettings={setSettings} />,
        "reCAPTCHA": <Recaptcha dispatch={dispatch} settings={settings} setSettings={setSettings} />,
        "Customize Error Text": <CustomizeErrorText dispatch={dispatch} settings={settings} setSettings={setSettings} />
    }

    const SaveChanges = () => {
        AxiosLite('/api/Forms/RenameForm|PATCH',
            { id: formId, name: settings.FormName },
            navigate,
            dispatch
        ).then(res => {
            toast(res?.data?.message, { type: res?.data.success ? 'success' : 'warning' });
        });
        CallAxios('/api/Forms/SaveStepData|PATCH',
            {
                Data: JSON.stringify(settings),
                FormId: formId,
                Step: 'Settings'
            },
            undefined,
            undefined,
            navigate,
            dispatch
        ).then(res => {
            toast(res?.data?.message, { type: res?.data.success ? 'success' : 'warning' });
        });
    }


    return (
        <>
            <SideBarHeaderWrapper>
                <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='mb-0 fw-bold'>Settings</h6>
                    <button className='btn btn-primary btn-sm' onClick={SaveChanges}> Save changes</button>
                </div>
            </SideBarHeaderWrapper>

            <div className='CollapseAbleWrapper'>
                {SettingsList.map((item, index) =>
                    <div key={'settings_' + index}>
                        <CollapseTrigger cursor={(item.label === "Auto-Fill Zip Code" || item.label === "Drop-off Mode") ? "default" : "pointer"} key={index} onClick={(e) => item.label === "Auto-Fill Zip Code" || item.label === "Drop-off Mode" ? null : showCollapsedDiv(index)}>
                            <TopBottomPadding>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <CollapseTriggerHeading className='d-flex '>
                                        {item.label}
                                        <div className='ms-2'>
                                            {item.tooltip.hasTooltip === true ?
                                                <ToolTip text={item.tooltip.title} />
                                                : null}
                                        </div>
                                    </CollapseTriggerHeading>
                                    {item.label === "Auto-Fill Zip Code" || item.label === "Drop-off Mode" ?
                                        <div className={`itemsPerform `}>
                                            <MaterialDesignSwitch defaultchecked={item.label === "Auto-Fill Zip Code" ? settings.autofillZipCode : settings.dropoffMode} id={item.label.trim()} checked={(checked) => item.label === "Auto-Fill Zip Code" ? dispatch(setSettings({ autofillZipCode: checked })) : dispatch(setSettings({ dropoffMode: checked }))} />
                                        </div>
                                        :
                                        <CommonArea className={`${item.isCollapsed === true ? "rotateit" : ""}`}>
                                            <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                                        </CommonArea>
                                    }
                                </div>
                            </TopBottomPadding>
                        </CollapseTrigger>

                        {item.isCollapsed === true ?
                            <div>
                                <CollapsedArea id={"example-collapse-text_s" + index}>
                                    <TopBottomPadding>
                                        {(LoadComponent as any)[item.label]}
                                    </TopBottomPadding>
                                </CollapsedArea>
                            </div>
                            : null}
                    </div>
                )}
            </div>

        </>

    );
}

export default Settings;




