import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CustomSelect from "Components/CustomSelect";
import { CollapsedAreaLabels } from "Components/Common"

const FormMode = (props: { dispatch: any; settings: FormEditorOptionsSettings; setSettings: any; }) => {
    const { dispatch, settings, setSettings } = props;
    const [option, setOption] = useState([
        { value: "all", label: "All" },
        { value: "popup", label: "Popup" },
        { value: "embedded", label: "Embedded" },
    ]);
    return (
        <>
            <div className='d-flex justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Choose mode
                </CollapsedAreaLabels>
                <div className={`itemsPerform w-50`}>
                    <CustomSelect onChange={(value) => dispatch(setSettings({ chooseMode: value }))} defaultValue={settings.chooseMode} data={option} name={"formMode"} />
                </div>

            </div>

        </>
    )
}

export default FormMode