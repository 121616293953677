
import ToolTip from 'Components/Tooltip';

import { useDispatch } from 'react-redux';
import { CollapsedAreaLabels } from "Components/Common";
import CustomSelect from "Components/CustomSelect";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import InputPrefix from "Components/PrefixInput";

const PopupBehavior = (props: { settings: FormEditorOptionsSettings; setSettings: any; }) => {
    const { settings, setSettings } = props;

    const dispatch = useDispatch();
    const PopUpDevices = [{ value: "all", label: "All" }, { value: "desktop", label: "Desktop" }, { value: "mobile", label: "Mobile" }];
    const PopUpfrequency = [{ value: "every_visit", label: "Every Visit" }, { value: "every_x_visit", label: "Every (X) Visits" }, { value: "once_PerSession", label: "Once Per Session" }, { value: "once_Pervisitor", label: "Once Per Visitor" }, { value: "until_FormSubmit", label: "Until Form Submit" }];
    return (
        <>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Timed PopUp
                    <div className='ms-2'>
                        <ToolTip text={"Leadform will automatically popup after certain delay."} />
                    </div>
                </CollapsedAreaLabels>
                <div className={`itemsPerform `}>
                    <MaterialDesignSwitch defaultchecked={settings.timedPopup} id={"timedPopUp"} checked={(checked) => dispatch(setSettings({ timedPopup: checked }))} />
                </div>
            </div>
            {settings.timedPopup === true ? <div className='border-top pt-2'>
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Popup On Devices
                        <div className='ms-2'>
                            <ToolTip text={`This setting allows you to choose which devices you'd like your form to popup on. To maximize leads we recommend choosing "All"`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform w-50`}>
                        <CustomSelect onChange={(value) => dispatch(setSettings({ popupOnDevices: value }))} defaultValue={settings.popupOnDevices} data={PopUpDevices} name={"PopupDevices"} />                </div>
                </div>
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Popup Delay
                        <div className='ms-2'>
                            <ToolTip text={`We recommend setting a delay when using a timed popup. The best practice is to base your delay on your average session duration. If you don't know this metric then try a delay of 15-30 seconds to start.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <InputPrefix defaultValue={settings.popupDelay?.toString()} onInput={(value) => dispatch(setSettings({ popupDelay: value }))} prefix={'second(s)'} />
                    </div>
                </div>
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Mobile Specific Delay
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch defaultchecked={settings.mobileSpecificDelay} id={"MobileSpecify"} checked={(checked) => dispatch(setSettings({ mobileSpecificDelay: checked }))} />

                    </div>
                </div>
                {settings.mobileSpecificDelay === true ?
                    <div className='d-flex mb-3 justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Mobile Popup Delay

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform `}>
                            <InputPrefix defaultValue={settings.mobilePopupDelay} onInput={(value) => dispatch(setSettings({ mobilePopupDelay: value }))} prefix={'second(s)'} />
                        </div>
                    </div>
                    : null}
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Popup Frequency
                        <div className='ms-2'>
                            <ToolTip text={`We recommend using "once per session." This will ensure that your website visitor only sees your timed popup once during a browsing session.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform w-50`}>
                        <CustomSelect defaultValue={settings.popupFrequency} onChange={(value) => dispatch(setSettings({ popupFrequency: value }))} data={PopUpfrequency} name={"PopupFrequency"} />

                    </div>
                </div>
            </div>
                : null}
            <div className='d-flex justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Exit PopUp
                    <div className='ms-2'>
                        <ToolTip text={"Leadform will automatically popup if the user moves a cursor to the edge of the window"} />
                    </div>
                </CollapsedAreaLabels>
                <div className={`itemsPerform `}>
                    <MaterialDesignSwitch defaultchecked={settings.exitPopup} id={"ExitPopUp"} checked={(checked) => dispatch(setSettings({ exitPopup: checked }))} />
                </div>

            </div>
        </>
    )
}



export default PopupBehavior;