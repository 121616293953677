import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { setisLoading } from "Store";
import styled from "styled-components";
import * as Yup from 'yup';
import { SignInManager } from "../../Helpers/Authentication/SignInManager";

export const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    return (
        <div>
            <Formik
                initialValues={{
                    username: '',
                    password: ''
                }}
                validationSchema={SigninSchema}
                onSubmit={async (values) => {
                    const manager = new SignInManager();
                    try {
                        dispatch(setisLoading(true));
                        const response = await manager.Login(values.username, values.password);
                        if (response.data && response.data.access_token) {
                            manager.SetToken(response.data);
                            dispatch(setisLoading(true));
                            navigate(searchParams.get('redirect_url') || '/', { replace: true });
                        }
                    } catch (e) {
                        dispatch(setisLoading(false));
                        toast('Invalid username or password.', { type: 'error' });
                    }
                }}>
                {({ isSubmitting, isValid }) => (
                    <Form>
                        <Field style={FieldStyles} type="text" name="username" autoComplete='username' placeholder="Email" />
                        <ErrorMessage name="username" component="div" className="text-danger" />
                        <Field style={FieldStyles} type="password" name="password" autoComplete="current-password" placeholder="Password" />
                        <ErrorMessage name="password" component="div" className="text-danger" />
                        <LoginBtn type="submit" disabled={isSubmitting || !isValid}>
                            Login
                        </LoginBtn>
                    </Form>
                )}
            </Formik>
        </div >
    )
}
const SigninSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
});
const FieldStyles = {
    color: 'rgb(112, 112, 112)',
    padding: '8px',
    border: 'none',
    outline: 'none',
    borderRadius: '3px',
    fontSize: '16px',
    display: 'block',
    background: 'rgb(244, 244, 244)',
    width: '100%',
    marginTop: '15px'
}

const LoginBtn = styled.button`
margin-top: 23px;
margin-bottom: 10px;
background: ${props => !props.disabled ? 'rgb(255, 80, 86)' : 'grey'};
color: rgb(255, 255, 255);
font-weight: 700;
box-shadow: ${props => !props.disabled && 'rgb(0 0 0 / 30%) 0px 2px 18px 0px'};
border: ${props => !props.disabled && '2px solid rgb(255, 80, 86)'};
border-radius: 3px;
font-size: 18px;
height: auto;
padding: 10px;
height: auto;
cursor: ${props => !props.disabled && 'pointer'};
outline: none;
text-decoration: none;
display: block;
width: 100%;
font-weight:500
`;

// const ForgetPasswordLink = styled.a`
// color: rgb(51, 142, 239);
//     color: rgb(32, 88, 119);
//     text-decoration: underline;
//     font-size: 16px;
//     font-weight: 400;
//     cursor: pointer;
// `;