import { useSelector } from "react-redux"

export const Loader = () => {
    const isLoading = useSelector((state: AppStore) => state.editor.isLoading)
    return (
        isLoading ? <div className='LoaderWrapper'>
            <span className="loader"></span>
        </div> : null

    )
}