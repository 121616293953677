import { useEffect, useState, Dispatch } from "react";

export const useDebounce = <S>(initialValue: S, delay: number)
    : [S, Dispatch<React.SetStateAction<S>>] => {
    const [actualValue, setActualValue] = useState(initialValue);
    const [debounceValue, setDebounceValue] = useState(initialValue);
    useEffect(() => {
        const debounceId = setTimeout(() => setDebounceValue(actualValue), delay);
        return () => clearTimeout(debounceId);
    }, [actualValue, delay]);
    return [debounceValue, setActualValue];
};
