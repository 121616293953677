import {
    Button,
    Card,
    CardContent,
    //FormControl,
    FormControlLabel,
    IconButton,
    //InputLabel,
   // Select,
   // Tabs,
    TextField
} from '@mui/material';
//import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
//import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
//import ReactHookFormSelect from 'Components/ReactHookFormSelect';
import { AxiosLite, CallAxios } from 'Helpers';
import * as React from 'react';
import { Badge } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';

export const EventCalendarList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState<ESTemplates[]>([]);
    const [show, setShow] = React.useState(false);

    const { register, formState: { errors }, setValue, handleSubmit, reset, control } = useForm<ESTemplates>({
        defaultValues: {
            id: undefined,
            name: '',
            content: '',
            published: false,
            templateType: 'SMS'
        },
        shouldFocusError: true,
    });

    const handleClose = () => {
        reset();
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const loadData = () => {
        AxiosLite(
            '/api/ESTemplates/List|GET',
            undefined,
            navigate,
            dispatch
        ).then(res => {
            setRows(res?.data.list || []);
        });
    };

    React.useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <Card style={{ boxShadow: '0px 1px 15px 1px rgb(69 65 78 / 8%)' }}>
                <Button
                    className='float-end'
                    onClick={handleShow}
                    variant='contained'
                    color='primary'>Add Calendar Event</Button>
                <CardContent>
                    <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
                        <Table sx={{ boxShadow: 'none' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Created On</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Modified On</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows.map((row) =>
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.createdDateUtc}</TableCell>
                                            <TableCell>
                                                <Badge bg={row.published ? 'success' : 'danger'} children={row.published ? 'Active' : 'In-Active'} />
                                            </TableCell>
                                            <TableCell>{row.lastModifiedDateUtc}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={e => {
                                                    reset(row);
                                                    handleShow();
                                                }}>
                                                    <MdEdit />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Modal
                show={show}
                centered
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Calendar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(values => {
                        CallAxios('/api/ESTemplates/Save|POST',
                            {
                                ...values,
                                published: Boolean(values.published)
                            },
                            undefined,
                            undefined,
                            navigate,
                            dispatch)
                            .then(res => {
                                toast(res?.data.message, {
                                    type: res?.data.success ? 'success' : 'error'
                                });
                                if (res?.data.success) {
                                    reset();
                                    loadData();
                                    handleClose();
                                }
                            })
                    })}>
                        <TextField
                            className='w-100 mb-2'
                            label='Name'
                            {...register('name', {
                                required: { message: 'Required', value: true }
                            })}
                            error={!!errors['name']?.message} />

                        <TextField
                            className='w-100 mb-2'
                            label='Content'
                            multiline
                            rows={4}
                            {...register('content', {
                                required: { message: 'Required', value: true }
                            })}
                            error={!!errors['content']?.message} />

                        <Controller
                            control={control}
                            name='published'
                            rules={{}}
                            render={({ field }) =>
                                <FormControlLabel control={<Checkbox {...field} />} label="Is Active" />
                            } />

                        <Button
                            variant="contained"
                            color='success'
                            className='float-end'
                            type='submit'>
                            Save
                        </Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={handleClose} color='error'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};