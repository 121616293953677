import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CommonArea, SideBarHeaderWrapper, CollapseTrigger, TopBottomPadding } from 'Components/Common';
import ToolTip from 'Components/Tooltip';
import { BiChevronRight } from "react-icons/bi"
import { DesignOptions, DesignOptionsChatStyle } from "commonJSON";
import TemplateStyle from "./Sidebars/DesignTab/TemplateStyle"
import Header from "./Sidebars/DesignTab/Header"
import StepHeadingAndSubHeading from "./Sidebars/DesignTab/StepHeadingAndSubHeading"
import BackButton from "./Sidebars/DesignTab/BackButton"
import CallUsBar from "./Sidebars/DesignTab/CallUsBar"
import LeadFormDimensions from "./Sidebars/DesignTab/LeadFormDimensions"
import LoadingSpinner from "./Sidebars/DesignTab/LoadingSpinner"
import OfferHeadline from "./Sidebars/DesignTab/OfferHeadline"
import OnsiteMessages from "./Sidebars/DesignTab/OnSiteMessages"
import OtherFontStyling from "./Sidebars/DesignTab/OtherFontStyling"
import ProgressBar from "./Sidebars/DesignTab/ProgressBar"
import ResponseStyling from "./Sidebars/DesignTab/ResponseStyling"
import ThankYouStep from "./Sidebars/DesignTab/ThankYouStep"
import SubmitButton from './Sidebars/DesignTab/SubmitButton';
import { useSelector } from 'react-redux';
import { CallAxios } from 'Helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const DesignSidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { formId } = useParams();
    const [SidebarList, setSidebarList] = useState(DesignOptions);
    const templateStyle = useSelector((state: AppStore) => state.editor.designOptions.templateStyle.templateStyle);
    const currentDesign = useSelector((state: AppStore) => state.editor.designOptions);

    useEffect(() => {
        templateStyle.value === "EnhancedForm" ?
            setSidebarList(DesignOptions)
            : setSidebarList(DesignOptionsChatStyle)
    }, [templateStyle.value]);

    const showCollapsedDiv = (index: number) => {
        const newData = [...SidebarList];
        newData[index].isOpen = !newData[index].isOpen;
        setSidebarList(newData)
    }

    const CollapseView = (label: string) => {
        switch (label) {
            case "Template Style":
                return <TemplateStyle />
            case "Header":
                return <Header />
            case "Step Heading and Sub-Heading":
                return <StepHeadingAndSubHeading />
            case "Response Styling":
                return <ResponseStyling />
            case "Loading spinner":
                return <LoadingSpinner />
            case `"Call us" bar`:
                return <CallUsBar />
            case `Back button`:
                return <BackButton />
            case `Onsite Message`:
                return <OnsiteMessages />
            case `Lead form dimensions`:
                return <LeadFormDimensions />
            case `Offer Headline`:
                return <OfferHeadline />
            case `Submit Button`:
                return <SubmitButton />
            case `"Thank You" Step`:
                return <ThankYouStep />
            case `Progress Bar`:
                return <ProgressBar />
            case `Other Font Styling`:
                return <OtherFontStyling />

        }
    }
    const CollapseViewChatStyle = (label: string) => {
        switch (label) {
            case "Template Style":
                return <TemplateStyle />
            case "Step Heading and Sub-Heading":
                return <StepHeadingAndSubHeading />
            case "Response Styling":
                return <ResponseStyling />
            case `"Call us" bar`:
                return <CallUsBar />
            case `Back button`:
                return <BackButton />
            case `Onsite Message`:
                return <OnsiteMessages />
            case `Offer Headline`:
                return <OfferHeadline />
            case `Submit Button`:
                return <SubmitButton />
            case `"Thank You" Step`:
                return <ThankYouStep />
            case `Progress Bar`:
                return <ProgressBar />
            case `Other Font Styling`:
                return <OtherFontStyling />

        }
    }
    const SaveDesignOptions = () => {
        CallAxios('/api/Forms/UpdateTemplateStyle|PATCH',
            undefined,
            {
                id: formId,
                style: templateStyle.value
            },
            undefined,
            navigate, dispatch);
        CallAxios('/api/Forms/SaveStepData|PATCH', {
            Data: JSON.stringify(currentDesign),
            FormId: formId,
            Step: 'Design'
        }, undefined, undefined, navigate, dispatch).then(res => {
            toast(res?.data?.message, { type: res?.data.success ? 'success' : 'warning' });
        });
    }
    return (
        <>
            <SideBarHeaderWrapper>
                <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='mb-0 fw-bold'>Design options</h6>
                    <button className='btn btn-primary btn-sm' onClick={SaveDesignOptions}> Save changes</button>
                </div>
            </SideBarHeaderWrapper>



            <div className='CollapseAbleWrapper'>

                {SidebarList.length > 0 && SidebarList.map((item, index) =>
                    <div key={"collapseiD_" + index}>
                        <CollapseTrigger cursor='default' key={"collapseiD_" + index} onClick={(e) => showCollapsedDiv(index)}>
                            <TopBottomPadding>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <CollapseTriggerHeading className='d-flex '>
                                        {item.label}
                                        <div className='ms-2'>
                                            {item.tooltip.hasTooltip === true ?
                                                <ToolTip text={item.tooltip.title} />
                                                : null}
                                        </div>
                                    </CollapseTriggerHeading>
                                    <CommonArea className={`${item.isOpen === true ? "rotateit" : ""}`}>
                                        <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                                    </CommonArea>
                                </div>
                            </TopBottomPadding>
                        </CollapseTrigger>

                        {item.isOpen === true ? <div>
                            <CollapsedArea id={"example-collapse-text_" + index}>
                                {templateStyle.value === "EnhancedForm" ? CollapseView(item.label) : CollapseViewChatStyle(item.label)}
                            </CollapsedArea>
                        </div>
                            : null}
                    </div>
                )}
            </div>

        </>

    );
}

export default DesignSidebar;

const CollapseTriggerHeading = styled.div`
font-size: 17px;

`
const CollapsedArea = styled.div`
padding: 0px 35px;
    position: relative;
    font-size: 16px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
    background: rgb(247, 247, 247);
`
