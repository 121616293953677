import React, { useState, useEffect } from 'react';
import FullCalendar, { EventContentArg, EventInput } from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { AxiosLite, CallAxios } from 'Helpers';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

export const Schedule = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { formId, leadId } = useParams();

    const mainForm = useForm<LeadEvents>({
        defaultValues: {
            formLeadId: leadId,
            categoryId: '',
        },
        shouldUnregister: true,
        mode: 'all'
    });

    const [addNewEvent, setAddNewEvent] = useState(false);
    const [selectedEvent, setselectedEvent] = useState<LeadEvents>();
    const [event, setEvents] = useState<EventInput[]>([]);
    const [localEvents, setLocalEvents] = useState<LeadEvents[]>([]);

    const loadData = () => {
        AxiosLite('/api/CRM/GetEvents|GET', undefined, navigate, dispatch).then(res => {
            setLocalEvents(res?.data.list || []);
            setEvents((res?.data.list || []).map(v => ({
                id: v.id,
                title: v.name,
                backgroundColor: v.color,
                start: v.createdDateUtc
            })));
        });
    }
    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {addNewEvent && <AddNewEvent selected={selectedEvent!} show={addNewEvent} handleClose={e => { setAddNewEvent(e); loadData() }} mainForm={mainForm} />}
            <div className="text-end mt-3 mb-5">
                <button className="btn btn-success m-btn--square  BtnAddEdit btnAddEvent" onClick={() => {
                    mainForm.reset({});
                    setAddNewEvent(!addNewEvent);
                }}>Add New Event</button>
            </div>
            <div>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    initialView='dayGridMonth'
                    editable={false}
                    selectable={false}
                    droppable={false}
                    dragScroll={false}
                    dayMaxEvents={true}
                    weekends={state.weekendsVisible}
                    events={event}
                    eventContent={renderEventContent}
                    eventClick={e => {
                        const ev = localEvents.find(s => s.id === e.event.id);
                        if (ev) {
                            mainForm.reset(ev);
                            setselectedEvent(ev);
                            setAddNewEvent(true);
                        }
                    }}
                />
            </div>
        </>
    );
}

function renderEventContent(eventInfo: EventContentArg) {
    return (
        <div style={{ backgroundColor: eventInfo.backgroundColor }} className='pointer-event'>
            <b>{eventInfo.timeText}</b>
            <i>{eventInfo.event.title}</i>
        </div>
    )
}

const AddNewEvent = (props: {
    show: boolean,
    handleClose: (v: boolean) => void,
    selected: LeadEvents,
    mainForm: UseFormReturn<LeadEvents, any>
}) => {
    const { show, handleClose, mainForm, selected } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, handleSubmit, reset, control, getValues, formState: { errors } } = mainForm;

    const [categories, setCategories] = useState<EventCategories[]>([]);
    const [value, setValue] = React.useState<moment.Moment | null>(moment(getValues('createdDateUtc') || new Date()));

    const handleChange = (newValue: moment.Moment | null) => {
        setValue(newValue);
    };

    useEffect(() => {
        AxiosLite('/api/EventCategories/List|GET', undefined, navigate, dispatch).then(s => {
            setCategories(s?.data.list || []);
        });
    }, []);

    return (
        <Modal show={show}
            onHide={() => handleClose(false)}
            centered
            keyboard={false}
            backdrop={'static'}>
            <Modal.Header>
                <Modal.Title>Add New Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(v => {
                    CallAxios('/api/CRM/AddEvent|POST', { ...v, id: selected.id, published: String(v.published) == 'true' ? true : false, createdDateUtc: value?.toISOString() || new Date().toISOString() }, undefined, undefined, navigate, dispatch).then(res => {
                        reset();
                        handleClose(false);
                    });
                })}>
                    <div className="kt-form">
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label">Name <span className="text-danger">*</span></label>
                            <div className="col-md-8">
                                <div className="input-group">
                                    <TextField type="text"
                                        error={!!errors['name']?.message}
                                        className="form-control"
                                        label="Name"
                                        {...register('name', { required: { message: 'Required', value: true } })} />
                                </div>
                                <span className="ValName text-danger d-none">Required</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label">App. Email <span className="text-danger">*</span></label>
                            <div className="col-md-8">
                                <div className="input-group">
                                    <TextField type="email"
                                        className="form-control"
                                        label="Appointment Email"
                                        error={!!errors['email']?.message}
                                        {...register('email', { required: { message: 'Required', value: true } })} />
                                </div>
                                <span className="ValEmail text-danger d-none">Required</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label">Category<span className="text-danger">*</span></label>
                            <div className="col-md-8">
                                <Controller
                                    control={control}
                                    name='categoryId'
                                    rules={{ required: { message: 'Required', value: true } }}
                                    render={({ field: { onChange, value, ref, name } }) =>
                                        <FormControl fullWidth>
                                            <InputLabel>Category</InputLabel>
                                            <Select
                                                name={name}
                                                error={!!errors['categoryId']?.message}
                                                ref={ref}
                                                onChange={e => {
                                                    onChange(e);
                                                }}
                                                value={value}
                                                label="Category">
                                                <MenuItem value="">Please Select</MenuItem>
                                                {
                                                    categories.map((v, i) =>
                                                        <MenuItem key={'cat_' + i} value={v.id}>{v.name}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    } />
                                <span className="ValCategory text-danger d-none">Required</span>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-4 col-form-label">Date <span className="text-danger">*</span></label>
                            <div className="col-md-8">
                                <DateTimePicker
                                    InputProps={{ ...register('createdDateUtc') }}
                                    value={value}
                                    onChange={handleChange}
                                    label="Date"
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <span className="ValHour text-danger d-none">Required</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label">Hour <span className="text-danger">*</span></label>
                            <div className="col-md-8">
                                <TextField type="number"
                                    className="form-control"
                                    label="Hour"
                                    error={!!errors['hour']?.message}
                                    {...register('hour', { required: { message: 'Required', value: true } })} />
                                <span className="ValHour text-danger d-none">Required</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label">Status <span className="text-danger">*</span></label>
                            <div className="col-md-8">
                                <Controller
                                    control={control}
                                    name='published'
                                    rules={{ required: { message: 'Required', value: true } }}
                                    render={({ field }) =>
                                        <FormControl fullWidth>
                                            <InputLabel>Status</InputLabel>
                                            <Select {...field} value={String(field.value)} label='Status'>
                                                <MenuItem value={''}>Please Select</MenuItem>
                                                <MenuItem value={'true'}>Active</MenuItem>
                                                <MenuItem value={'false'}>In Active</MenuItem>
                                            </Select>
                                        </FormControl>
                                        // <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label="Status" />
                                    } />
                                <span className="ValStatus text-danger d-none">Required</span>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-sm btn-success' type='submit'>
                        Save Changes
                    </button>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-sm btn-secondary' onClick={() => {
                    reset();
                    handleClose(false);
                }}>
                    Close
                </button>
            </Modal.Footer>
        </Modal >
    )
}

const state = {
    weekendsVisible: true,
    currentEvents: []
}