import { SignInManager } from 'Helpers/Authentication/SignInManager';
import { RiContactsBookLine, RiUserFill } from 'react-icons/ri';
import { SiReadthedocs } from 'react-icons/si';
import { Link, useLocation } from "react-router-dom";
import styled from 'styled-components';

const Menu = () => {
    const Checkactive = useLocation().pathname;
    const role = new SignInManager().UserRole;
    return (
        <>
            <Menubox>
                {
                    (role == 'Admin' || role == 'Client') &&
                    <>   <Menuitem to="/subusers" className={`menu-item ${Checkactive === "/subusers" || Checkactive === "/account" || Checkactive.includes('adduser') ? "active" : ""}`}>
                        <Menuitemicon className='menu-item-icon'>
                            <RiUserFill color='eee' />
                        </Menuitemicon>
                        Sub Users
                    </Menuitem><Menuitem to="/" className={`menu-item ${getFormsActive(Checkactive)}`}>
                            <Menuitemicon className='menu-item-icon'>
                                <SiReadthedocs color='eee' />
                            </Menuitemicon>
                            Forms
                        </Menuitem>
                    </>

                }

                <Menuitem to="/leads" className={`menu-item ${getLeadsActive(Checkactive)}`}>
                    <Menuitemicon className='menu-item-icon'>
                        <RiContactsBookLine color='eee' />
                    </Menuitemicon>
                    Leads
                </Menuitem>
             
                    <>  <Menuitem to="/estemplates" className={`menu-item ${getTemplatesActive(Checkactive)}`}>
                        <Menuitemicon className='menu-item-icon'>
                            <RiContactsBookLine color='eee' />
                        </Menuitemicon>
                        Email & Sms Templates
                    </Menuitem>
                        <Menuitem to="/categories" className={`menu-item ${getCategoriesActive(Checkactive)}`}>
                            <Menuitemicon className='menu-item-icon'>
                                <RiContactsBookLine color='eee' />
                            </Menuitemicon>
                            Event Categories
                        </Menuitem>
                    </>
                

            </Menubox>
        </>
    );
}

export default Menu;

const getFormsActive = (path: string) => {
    switch (path.toLowerCase()) {
        case "/":
        case "/templates":
            return "active";
        default:
            if (path.toLowerCase().startsWith('/edit'))
                return "active";
            return "";
    }
}
const getLeadsActive = (path: string) => {
    path = path.toLowerCase();
    if (path === '/leads' || path.startsWith('/contactdetails')) return 'active';
}
const getTemplatesActive = (path: string) => {
    switch (path.toLowerCase()) {
        case "/estemplates":
            return "active";
        default:
            return "";
    }
}
const getCategoriesActive = (path: string) => {
    switch (path.toLowerCase()) {
        case "/categories":
            return "active";
        default:
            return "";
    }
}

const Menubox = styled.div`
margin-top: 20px;
`;
const Menuitem = styled(Link)`
display: block;
    text-decoration: none;
    color: #eee;
    margin: 4px 8px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align:left;
    &:hover{
        color:#fff !important;
    }
`;
const Menuitemicon = styled.span`
display: inline-block;
    margin-right: 8px;
`;