import { AxiosLite, CallAxios } from 'Helpers';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaCalendarAlt, FaPen, FaRegCommentDots, FaRegEnvelope } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LogActivity from './ContactDetails/LogActivity';
import RecentActivity from './ContactDetails/RecentActivity';
import { Schedule } from './ContactDetails/Schedule';
import SendEmail from './ContactDetails/SendEmail';
import SendSms from './ContactDetails/SendSms';

const ContactDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { leadId, formId } = useParams();

    const [tabsChange, setTabsChange] = useState<TabTypes>('LogActivity');
    const [data, setData] = useState<ContactDetail>({});

    const loadData = () => {
        AxiosLite('/api/Leads/LeadDetail|GET', { formId, leadId }, navigate, dispatch).then(res => {
            if (res?.data.success) {
                setData(res.data.data);
            } else {
                toast(res?.data.message, { type: 'error' });
            }
        });
    }

    useEffect(() => {
        loadData();
    }, [leadId, formId]);

    return (
        <div className="container-fluid">
            <Row>
                <Col md={12}>
                    <div className='border-bottom mb-1'>
                        <label className="text-muted fw-bolder">{data.leadForm || 'No information found'}</label>
                    </div>
                </Col>
                <Col md={3}>
                    <section className='section-1'>
                        <Card className='mt-4'>
                            <Card.Body>
                                <label className="text-muted fw-bolder">{data.userName || 'No information found'}</label>
                            </Card.Body>
                        </Card>
                    </section>
                    <section className='section-1'>
                        <Card className='mt-4'>
                            <Card.Body>
                                <h5 className="fw-bold">Contact</h5>
                                <div className="d-flex justify-content-between align-items-center my-2">
                                    <div>
                                        <label className="fw-bold text-muted">Email</label>
                                    </div>
                                    <div>
                                        <a href="jabasvript:;" className="text-muted fw-bolder text-decoration-none">{data.email}</a>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center my-2">
                                    <div>
                                        <label className="fw-bold text-muted">Phone No</label>
                                    </div>
                                    <div>
                                        <label className="text-muted fw-bolder">{data.phone || '-'}</label>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <h5 className="fw-bold">Information</h5>
                                    <div className="d-flex justify-content-between align-items-center my-2">
                                        <div>
                                            <label className="fw-bold text-muted">Staus</label>
                                        </div>
                                        <div>
                                            <label className="text-muted fw-bolder">{data.userIP || '-'}</label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center my-2">
                                        <div>
                                            <label className="fw-bold text-muted">Channels</label>
                                        </div>
                                        <div>
                                            <label className="text-muted fw-bolder">{data?.userAgentInformation?.browser} on {data?.userAgentInformation?.platform}</label>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center my-2">
                                        <div>
                                            <label className="fw-bold text-muted">Created at</label>
                                        </div>
                                        <div>
                                            <label className="text-muted fw-bolder">{data.createdAt || '-'}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <h5 className="fw-bold">Additional Information</h5>
                                    <div className="d-flex justify-content-between align-items-center my-2">
                                        <div>
                                            <label className="fw-bold text-muted">Type</label>
                                        </div>
                                        <div>
                                            <label className="text-muted fw-bolder">{data.type || '-'}</label>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </section>
                    <section className='section-1'>
                        <Card className='mt-4'>
                            <Card.Body>
                                <h5 className="fw-bold">Form</h5>

                                <div className="company-primary-info">
                                    <div className="avatar avatar-l me-2">
                                        {data.avatar}
                                    </div>
                                    <div className="details">
                                        <a className="name text-decoration-none text-dark">
                                            {data.leadForm}
                                        </a>
                                        <div className="address"></div>
                                        <div className="site">
                                            <a href="" target="_blank">

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </section>
                </Col>
                <Col md={9}>
                    <Card className='mt-4'>
                        <Card.Body>
                            <Tabs
                                defaultActiveKey={tabsChange}
                                className="mb-3"
                                onSelect={(select) => setTabsChange(select as TabTypes)}>
                                <Tab eventKey="LogActivity" title={<div className="text-uppercase darColor"><FaPen className='me-2' /> log activity</div>}>
                                    <LogActivity data={data} LogTypes={data.logTypes || []} onNewAdded={() => loadData()} />
                                    <RecentActivity data={data.leadLogs || []} LogTypes={data.logTypes || []} />
                                </Tab>
                                <Tab eventKey="SendSms" title={<div className="text-uppercase darColor"><FaRegCommentDots className='me-2' /> send sms</div>}>
                                    <SendSms templates={data.templates || []} phone={data.phone} />
                                </Tab>
                                <Tab eventKey="SendEmail" title={<div className="text-uppercase darColor"><FaRegEnvelope className='me-2' /> send email</div>} >
                                    <SendEmail templates={data.templates || []} email={data.email} />
                                </Tab>
                                <Tab eventKey="Schedule" title={<div className="text-uppercase darColor"><FaCalendarAlt className='me-2' /> schedule</div>} >
                                    {tabsChange === "Schedule" ? <Schedule /> : null}
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
    );
}

export default ContactDetails;

type TabTypes = 'Schedule' | 'SendEmail' | 'SendSms' | 'LogActivity';