import React, { useState, useEffect } from 'react';
import FormFloatingLabel from "Components/FloatingInput"


const CustomizeErrorText = (props: { dispatch: any; settings: any; setSettings: any; }) => {
    const { dispatch, settings, setSettings } = props;

    return (
        <>
            <div className='mb-3'>
                <FormFloatingLabel onInput={(value) => dispatch(setSettings({ questionPromptText: value }))} label={"Question Prompt Text"} defaultValue={settings.questionPromptText} />

            </div>
            <div className='mb-3'>
                <FormFloatingLabel label={`Form Field "Required" Error Text`} defaultValue={settings.formField_Required_ErrorText} onInput={(value) => dispatch(setSettings({ formField_Required_ErrorText: value }))} />

            </div>
            <div className='mb-3'>
                <FormFloatingLabel label={`Form Field "Wrong Email" Error Text`} defaultValue={settings.formField_WrongEmail_ErrorText} onInput={(value) => dispatch(setSettings({ formField_WrongEmail_ErrorText: value }))} />

            </div>
            <div className='mb-3'>
                <FormFloatingLabel label={`Form Field "Wrong Zip Code" Error Text`} defaultValue={settings.formField_WrongZipCode_ErrorText} onInput={(value) => dispatch(setSettings({ formField_WrongZipCode_ErrorText: value }))} />

            </div>
            <div className='mb-3'>
                <FormFloatingLabel label={`Form Field "Wrong Canadian Postal Code" Error Text`} defaultValue={settings.formField_WrongCanadianPostalCode_ErrorText} onInput={(value) => dispatch(setSettings({ formField_WrongCanadianPostalCode_ErrorText: value }))} />

            </div>
            <div className='mb-3'>
                <FormFloatingLabel label={`Form Field "Wrong UK Postal Code" Error Text`} defaultValue={settings.formField_WrongUKPostalCode_ErrorText} onInput={(value) => dispatch(setSettings({ formField_WrongUKPostalCode_ErrorText: value }))} />

            </div>
            <div className='mb-3'>
                <FormFloatingLabel label={`Form Field "Wrong Phone" Error Text`} defaultValue={settings.formField_WrongPhone_ErrorText} onInput={(value) => dispatch(setSettings({ formField_WrongPhone_ErrorText: value }))} />

            </div>
            <div className='mb-3'>
                <FormFloatingLabel label={`Form Field "Wront Full Name" Error Text`} defaultValue={settings.formField_WrongFullName_ErrorText} onInput={(value) => dispatch(setSettings({ formField_WrongFullName_ErrorText: value }))} />

            </div>
        </>
    )
}

export default CustomizeErrorText