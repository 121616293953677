import { ICONS, ResponseLi, ResponseVariableName } from "Components/Common";
import Tooltip from 'Components/Tooltip';
import { AxiosLite, FocusOnView } from "Helpers";
import { confirmAlert } from "react-confirm-alert";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { removeFieldFromList } from "Store/slices/EditFormSlice";
import { v4 as uuid } from "uuid";

export const FormFieldsList = (props: {
    list: FormSteps[],
    setAddField: React.Dispatch<React.SetStateAction<boolean>>,
    formik: FormikType<FormStepResponses>;
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { formId, id } = useParams();
    const { list, setAddField, formik } = props;
    const currentStepList = list.find(x => x.id === String(id));

    const removeResponse = (currentID: string) => {
        confirmAlert({
            closeOnEscape: true,
            message: `Do you really want to delete the response?\r\nThis action can't be undone.`,
            closeOnClickOutside: false,
            title: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes, Delete',
                    className: 'bg-danger',
                    onClick: async () => {
                        const stepId = id;
                        AxiosLite('/api/Response/DeleteResponse|DELETE',
                            {
                                formId: formId,
                                stepId: stepId,
                                id: currentID
                            },
                            navigate,
                            dispatch);
                        dispatch(removeFieldFromList({ stepId: String(stepId), currentID }))
                    }
                },
                {
                    label: 'No, Cancel',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })

    }

    return (
        <ul className='p-0 px-3'>
            {
                currentStepList?.responses?.map((x, i) =>
                    <ResponseLi key={'form_fields_li_' + i}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-start align-items-center'>
                                <FaBars />
                                <div className='ms-3 text-break'>
                                    <div>Field Name:{x.responseOrFieldName}</div>
                                    {/* {
                                        (x.fieldType === "Text" || x.fieldType === "LongText")
                                        && <textarea className='form-control'></textarea>
                                    }

                                    {
                                        (x.fieldType === "Slider")
                                        && <div>Field Type:{x.fieldType}</div>
                                    }
                                    {
                                        x.fieldType === "Select"
                                        || x.fieldType === "MultiSelect"
                                        && <select className='form-select form-select-sm'>
                                            <option value={x.fieldLabel}>{x.fieldLabel}</option>
                                            {
                                                (x.createdOptions || []).map((x, i) =>
                                                    <option key={'o_' + i} value={x.value}>{x.label} || {x.value}</option>)
                                            }
                                        </select>
                                    } */}

                                    <ResponseVariableName className='d-flex'>Variable name:{`{${x.variableName}}`}
                                        <div className='ms-1 me-2'>
                                            <Tooltip text={`Put it as "{${x.variableName}}" in any input field to replace the variable name with the actual value of the response from the step.`} />
                                        </div>
                                    </ResponseVariableName>
                                </div>
                            </div>

                            <div className="ms-1">
                                <ICONS className='me-1'
                                    onClick={() => {
                                        setAddField(true);
                                        formik.setValues({
                                            ...x,
                                            slider: typeof x.sliderData == 'string' ? JSON.parse(x.sliderData) : x.sliderData,
                                            createdOptions: (x.createdOptions || []).length == 0 ? [{ label: '', value: '', id: '' }] : x.createdOptions
                                        });
                                        FocusOnView('editor_main');
                                    }}>
                                    <MdEdit />
                                </ICONS>
                                <ICONS onClick={() => x.id && removeResponse(x.id)}>
                                    <IoClose />
                                </ICONS>
                            </div>
                        </div>
                    </ResponseLi>
                )}
        </ul>
    )
}