import { useState } from 'react';
import { CollapsedAreaLabels } from "Components/Common";
import FormFloatingLabel from "Components/FloatingInput";
import CustomSelect from "Components/CustomSelect";
import ToolTip from 'Components/Tooltip';

const GeneralTab = (props: { dispatch: any; settings: FormEditorOptionsSettings; setSettings: any; }) => {
    const { dispatch, settings, setSettings } = props;
    const [option, setOption] = useState([{ value: '', label: '' }]);
    return (
        <>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Profile Email
                </CollapsedAreaLabels>
                <div className={`itemsPerform w-50`}>
                    <CustomSelect data={option} onChange={(value) => dispatch(setSettings({ profileEmail: value }))} defaultValue={settings.profileEmail} name={"general"} />
                </div>

            </div>
            <div className='mb-3'>
                <FormFloatingLabel label={"Form Name"} defaultValue={settings.FormName} onInput={(value) => dispatch(setSettings({ FormName: value }))} />
            </div>
            <CollapsedAreaLabels className='d-flex align-items-center'>
                URL parameters as variables
                <div className='ms-2'>
                    <ToolTip text={`Put any URL parameter as "{url__}" in any input field to replace the variable name with the actual value of the URL parameter. For example, this URL "https://test.com/?one_param=1&another_one=test" contains two URL parameters, and their values can be used in any part of your LeadBoost form using the variables "{url__one_param}" and "{url__another_one}" correspondingly.`} />
                </div>
            </CollapsedAreaLabels>
        </>
    )
}

export default GeneralTab;