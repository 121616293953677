import { HTMLInputTypeAttribute } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const FormFloatingLabel = (props: {
  label?: string,
  defaultValue?: string | number | string[],
  onInput?: (e: string) => void,
  className?: string,
  type?: HTMLInputTypeAttribute,
  autoFocus?: boolean,
  id?: string,
  max?: number,
  min?: number,
  step?: number,
  size?: number,
  maxLength?: number,
  regex?: RegExp,
  pattern?: string
}) => {
  return (
    <FloatingLabel
      controlId="floatingInput"
      label={props.label}
      className={"smallShadow " + (props.className || '')}>
      <input
        pattern={props.pattern}
        id={props.id}
        min={props.min}
        max={props.max}
        autoFocus={props.autoFocus}
        type={props.type || 'text'}
        size={props.size}
        step={props.step}
        maxLength={props.maxLength}
        value={props.defaultValue ? props.defaultValue : ''}
        className="form-control"
        onInput={(e) => {
          if (props.onInput && e.target instanceof HTMLInputElement) {
            if (props.regex && e.target.value && !props.regex.test(e.target.value)) {
            } else {
              props.onInput(e.target.value || '')
            }
          }
        }} />
    </FloatingLabel>
  );
}

export default FormFloatingLabel;