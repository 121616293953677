import { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";

function RecentActivity(props: {
    data: LeadLogs[],
    LogTypes: LogTypes[]
}) {
    const { data, LogTypes } = props;

    const [filteredList, setFilteredList] = useState<LeadLogs[]>(data);
    useEffect(() => {
        setFilteredList(data);
    }, [data]);
    return (
        <>
            <section className="mt-4">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h5 className="text-muted fw-bold">Recent Activity</h5>
                    </div>
                    <div>
                        <div className="d-flex">
                            <select className="form-select" onChange={e => {
                                if (e.target.value) {
                                    setFilteredList(data.filter(c => c.logType === e.target.value));
                                } else {
                                    setFilteredList(data);
                                }
                            }}>
                                <option value="">Select Activity</option>
                                {
                                    LogTypes.map((v, i) =>
                                        <option value={v} key={'ddl2_' + i}>{v}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </section>
            {
                filteredList.length > 0 &&
                <section>
                    <div className="timeline timeline-3 mt-3">
                        <div className="timeline-items logActivity_List">
                            {
                                filteredList?.map((v, i) =>
                                    <div className="timeline-item" key={'logs_' + i}>
                                        <div className="timeline-media">
                                            <FaPencilAlt className="fa-2x fa-2x fa fa fa-pencil msgbg  text-dark-75" />
                                        </div>
                                        <div className="timeline-content">

                                            <div className="card cardchildheight">
                                                <div className="card-body ">
                                                    <div className="cardtimiline">
                                                        <div>
                                                            <p><b>{v.createdBy}</b> made a {v.logType}</p>
                                                        </div>
                                                        <div>
                                                            <label className="text-muted btn BtnST">{v.createdAt}</label>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <p className="para_des">
                                                            {v.description} <a className="leaddes d-none" > <FaPencilAlt /></a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

export default RecentActivity;