import { Button, Card, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { AxiosLite } from 'Helpers';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { BsArrowRight } from 'react-icons/bs';
import { RiEyeLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setisLoading } from 'Store';

const Leads = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { formId } = useParams();

    const [data, setData] = useState<FormLeadSubmitModel[]>([]);
    const [forms, setForms] = useState<LabelValuePair<string>[]>([]);
    const [form, setForm] = useState<LabelValuePair<string> | null>(null);

    const [selectedLead, setSelectedLead] = useState<({ title: string, id: string })>({
        id: '',
        title: ''
    });

    const [showLead, setShowLead] = useState(false);

    useEffect(() => {
        AxiosLite('/api/General/GetFormsList|GET',
            undefined,
            navigate,
            dispatch
        ).then(res => {
            const list: LabelValuePair<string>[] = [];
            (res?.data.data || []).forEach(x => {
                list.push({
                    value: x.key,
                    label: x.value
                })
            })
            setForms(list);
        });
    }, []);

    useEffect(() => {
        if (formId && forms) {
            setForm(forms.find(x => x.value === formId) || null);
        }
    }, [forms, formId]);

    useEffect(() => {
        AxiosLite('/api/Leads/GetList|GET',
            { formId: form?.value || formId },
            navigate,
            dispatch
        ).then(res => {
            setData(res?.data.list || []);
        });
    }, [form, formId]);

    return (
        <Card className='shadow' style={{ boxShadow: '0px 1px 15px 1px rgb(69 65 78 / 8%)' }}>
            <Row className='p-3 pt-4'>
                <Col md={6}>
                    <Typography>
                        {data.length} of {data.length} Leads
                    </Typography>
                </Col>
                <Col md={6} style={{ textAlign: 'right' }}>
                    <Autocomplete
                        onReset={e => console.log(e)}
                        disablePortal
                        value={!form && formId && forms ? forms.find(x => x.value === formId) || null : form || null}
                        onChange={(_, newValue) => {
                            setForm(newValue);
                        }}
                        options={forms}
                        sx={{ width: 300, marginLeft: 'auto' }}
                        renderOption={(props, option) =>
                            <li {...props} key={option.value}>
                                {option.label?.trim()}
                            </li>
                        }
                        renderInput={(params) => <TextField {...params} label="Select Form" />}
                    />
                </Col>
            </Row>
            <CardContent>
                <Row>
                    <Col className='pt-1' md={12} lg={12} xl={12} xxl={12}>
                        <TableContainer>
                            <Table size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Lead Form</TableCell>
                                        <TableCell>Submitted Date</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Zip Code</TableCell>
                                        <TableCell>IP</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, i) => (
                                        <TableRow key={i + 1}>
                                            <TableCell>{row.leadForm}</TableCell>
                                            <TableCell>{row.date}</TableCell>
                                            <TableCell>{row.userName}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.phone}</TableCell>
                                            <TableCell>{row.zipCode}</TableCell>
                                            <TableCell>{row.userIP}</TableCell>
                                            <TableCell>
                                                <Tooltip title='View leads'>
                                                    <IconButton onClick={_ => {
                                                        setSelectedLead({ id: row.leadId || '', title: row.leadForm || '' });
                                                        setShowLead(true);
                                                    }}>
                                                        <RiEyeLine />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='View detail'>
                                                    <IconButton onClick={_ => navigate('/contactDetails/' + row.leadId + '/' + row.formId)}>
                                                        <BsArrowRight />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {
                                        data.length == 0 && <TableRow>
                                            <TableCell colSpan={8} className='text-danger text-center'>No leads found</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                </Row>
            </CardContent>
            <ViewLead leadId={selectedLead.id} onClose={() => setShowLead(false)} show={showLead} title={selectedLead.title} />
        </Card>
    );
}

export default Leads;

export const ViewLead = ({
    leadId,
    onClose,
    show,
    title
}: {
    leadId: string,
    show: boolean,
    onClose: () => void,
    title: string
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [detail, setDetail] = useState('');

    useEffect(() => {
        if (leadId && show) {
            dispatch(setisLoading(true));
            AxiosLite('/api/Leads/GetFilledDetail|GET', { leadId }, navigate, dispatch)
                .then(res => {
                    console.log(res?.data);
                    setDetail(res?.data?.data || '');
                    dispatch(setisLoading(false));
                });
        }
    }, [leadId]);

    return (
        <Modal
            show={show}
            centered
            onHide={onClose}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{title}'s Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <code dangerouslySetInnerHTML={{ __html: detail || '' }}></code>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="contained" onClick={onClose} color='error'>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}