import { AxiosLite, CallAxios } from 'Helpers';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
    addSubmittedResponse,
    removeSubmittedResponse,
    setDesignOptions,
    setSettings,
    setStepfromJSON,
    setStepOptions
} from "Store/slices/PreviewPageSlice";
import CenterFormBlock from '../Components/CenterBlock/CenterFormCreationBlock';
import { toast } from "react-toastify";
export const PreviewForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const steps = useSelector((state: AppStore) => state.preview.steps);
    const stepOptions = useSelector((state: AppStore) => state.preview.stepOptions);
    const stepsSubmitted = useSelector((state: AppStore) => state.preview.stepsSubmitted);

    const { formId } = useParams();

    const [leadId, setLeadId] = useState<string>();
    const [ready, setReady] = useState(false);

    const formMain = useForm<any>({
        mode: 'all',
        shouldFocusError: true,
    });

    const { setValue, reset } = formMain;
    const [stepsLoaded, setStepLoaded] = useState(0);


    useEffect(() => {
        const loadData = async () => {
            AxiosLite('/api/Forms/GetPreviewPartialForm|GET',
                {
                    id: formId
                },
                navigate,
                dispatch,
                false,
                false
            ).then(res => {
                setReady(true);
                if (res?.data?.data?.designData && Object.keys(res?.data?.data?.designData || {}).length > 0) {
                    const designOptions = res?.data?.data?.designData;
                    dispatch(setDesignOptions(designOptions));
                    dispatch(setSettings(res.data.data.settingsData));
                    const formSteps = [...(res?.data?.data.formSteps || [])];
                    formSteps.forEach(x => {
                        x.responses?.forEach(r => r.slider = typeof r.sliderData === 'string' ? JSON.parse(r.sliderData) : r.sliderData);
                    });
                    dispatch(setStepfromJSON(formSteps));
                    const entryStep = res.data.data.formSteps?.find(x => x.isEntryStep);
                    if (entryStep)
                        dispatch(setStepOptions(entryStep));
                    setStepLoaded(1);
                }
            }).catch(err => {
                navigate('/error');
            })
        }
        loadData();
    }, []);

    useEffect(() => {
        const loadData = async () => {
            if (stepsLoaded === 1 && steps.length == 1) {
                //console.log("condition met")
                AxiosLite('/api/Forms/GetPreviewFormSteps|GET',
                    {
                        id: formId
                    },
                    navigate,
                    dispatch,
                    false,
                    false
                ).then(res => {
                    setStepLoaded(2);
                    const formSteps = [...(res?.data?.data || [])];
                    formSteps.forEach(x => {
                        x.responses?.forEach((r: any) => r.slider = typeof r.sliderData === 'string' ? JSON.parse(r.sliderData) : r.sliderData);
                    });
                    dispatch(setStepfromJSON(formSteps));


                }).catch(err => {
                    navigate('/error');
                })
            }
        }
        loadData();
    }, [stepsLoaded]);





    const changeStep = (obj: FormStepResponses) => {

        if (stepOptions) {
            const nextStep = () => {
                const next = steps.find(x => x.id === obj?.targetStepId);
                if (next) {
                    dispatch(setStepOptions(next));
                    dispatch(addSubmittedResponse({
                        id: String(stepOptions?.id),
                        type: 'response',
                        values: []
                    }));
                }
            };
            if (!searchParams.has('mode') || searchParams.get('mode') !== 'view') {
                const values: FormLeadSubmitModel = {
                    leadId: leadId,
                    formId: String(formId),
                    formLeadSubmitDetails: [{
                        isFinalStep: stepOptions.isFinalStep || false,
                        answerDataType: 'Text',
                        stepId: String(stepOptions.id),
                        stepOrder: (stepOptions.stepOrder || 0),
                        questionResponseId: obj.id,
                        questionText: stepOptions.questionText || '',
                        answerText: obj.responseOrFieldName
                    }]
                }
                CallAxios('/api/Forms/SubmitResponse|POST',
                    values,
                    {
                        "api-version": '1.1'
                    },
                    undefined,
                    navigate,
                    dispatch
                ).then(res => {
                    if (!res?.data.success) {
                        //Show error message //Duplicate email
                        toast(res?.data.message, { type: 'error' });
                    }
                    else {
                        // if (res?.data.message != "") {
                        //     toast(res?.data.message, { type: 'success' });
                        // }
                        setLeadId(String(res?.data.data));
                        nextStep();
                    }

                });
            } else {
                nextStep();
            }
        }
    };

    const parseAnswerByFieldType = (type: ResponseFieldType, answer: any) => {
        if (type == 'MultiSelect') {
            return answer
                .filter((s: { checked: any; }) => s.checked)
                .map((s: { label: any; }) => s.label).join(',');
        }
        return answer;
    }

    const onSubmit: SubmitHandler<any> = data => {


        if (stepOptions) {
            const nextStep = () => {
                const next = steps.find(x => x.id === stepOptions?.targetStepId);
                if (next) {
                    dispatch(addSubmittedResponse({
                        id: String(stepOptions?.id),
                        type: 'step',
                        values: data
                    }));
                    dispatch(setStepOptions(next));
                    reset();
                }
            }
            if (!searchParams.has('mode') || searchParams.get('mode') !== 'view') {
                const responses: FormleadSubmitDetail[] = [];
                Object.keys(data).forEach(key => {
                    const resp = stepOptions.responses?.find(x => x.id === key);
                    if (resp) {
                        responses.push({
                            isFinalStep: stepOptions.isFinalStep || false,
                            answerDataType: resp.validationType || 'Text',
                            stepId: String(stepOptions.id),
                            stepOrder: (stepOptions.stepOrder || 0),
                            questionText: resp.fieldLabel,
                            answerText: parseAnswerByFieldType(resp.fieldType, data[key]),
                            formResponseId: key,
                        });
                    }
                });
                const values: FormLeadSubmitModel = {
                    leadId: leadId,
                    formId: String(formId),
                    formLeadSubmitDetails: responses
                }
                CallAxios('/api/Forms/SubmitResponse|POST',
                    values,
                    {
                        "api-version": '1.1'
                    },
                    undefined,
                    navigate,
                    dispatch
                ).then(res => {
                    if (!res?.data.success) {
                        //Show error message //Duplicate email
                        toast(res?.data.message, { type: 'error' });
                    }
                    else {
                        // if (res?.data.message != "") {
                        //     toast(res?.data.message, { type: 'success' });
                        // }
                        setLeadId(String(res?.data.data));
                        nextStep();
                    }

                });
            } else {
                nextStep();
            }
        }

    };

    const onBackButton = (e: HTMLButtonElement) => {


        if (stepsSubmitted) {
            const last = stepsSubmitted[stepsSubmitted.length - 1];
            const next = steps.find(x => x.id === String(last.id));
            if (next) {
                dispatch(setStepOptions(next));
                dispatch(removeSubmittedResponse(last));
                Object.keys(last.values).forEach((x: any) => {
                    setValue(x, last.values[x]);
                })
            }
        }
    };
    const actions: FormPreviewActions = {
        changeStep
    };

    return (
        <div className='w-75 mx-auto'>
            {
                ready == true ?
                    <div className='LoadPreview formPreview'>
                        <CenterFormBlock formMain={formMain} onBackClick={onBackButton} slice={"preview"} action={actions} onSubmit={onSubmit} />
                    </div> :
                    <div></div>
            }

        </div>
    );
}
