import { Button } from '@mui/material';
import { CollapsedAreaLabels, CollapseTrigger, CommonArea, TopBottomPadding } from 'Components/Common';
import FormFloatingLabel from 'Components/FloatingInput';
import MaterialDesignSwitch from 'Components/MaterialSwitch';
import { FieldArray, FormikProvider } from "formik";
import { useState } from 'react';
import { BiChevronRight } from "react-icons/bi";
import styled from 'styled-components';

const LeadNotifications = (props: {
    formik: FormikType<FormLeadDelivery>;
}) => {
    const { formik } = props;
    const [showDiv, setShowDiv] = useState(false);
    const [emailNotification, setEmailNotification] = useState(formik.values.emailAddress.length > 0);
    const [smsNotification, setSmsNotification] = useState(formik.values.mobilePhoneNumber.length > 0);

    return (
        <>
            <FormikProvider value={formik}>
                <CollapseTrigger cursor='default' onClick={() => setShowDiv(!showDiv)}>
                    <TopBottomPadding>
                        <div className='d-flex justify-content-between align-items-center'>
                            <CollapseTriggerHeading className='d-flex '>
                                Lead notifications
                            </CollapseTriggerHeading>

                            <CommonArea className={`${showDiv === true ? 'rotateit' : ''}`}>
                                <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                            </CommonArea>

                        </div>
                    </TopBottomPadding>
                </CollapseTrigger>
                {showDiv === true ? <CollapsedArea className='bg-white' id={"example-collapse-text_ss"}>
                    <TopBottomPadding >

                        <div className='d-flex justify-content-between align-items-center'>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                Email Notifications
                            </CollapsedAreaLabels>
                            <div className={`itemsPerform `}>
                                <MaterialDesignSwitch defaultchecked={emailNotification} id={"emailNotification"} checked={(checked) => setEmailNotification(checked)} />

                            </div>

                        </div>
                        {
                            emailNotification && <div className='mt-2 border-top'>
                                <TopBottomPadding className='pb-0 mb-2'>
                                    {
                                        formik.values.emailAddress.length === 0 &&
                                        <Button type='button' variant='contained' color='primary' onClick={e => {
                                            formik.setFieldValue("emailAddress", [{ value: "" }]);
                                        }}>+</Button>
                                    }
                                    <FieldArray
                                        name="emailAddress"
                                        render={arrayHelpers => (
                                            formik.values.emailAddress.map((item: any, index: number) =>
                                                <div className='row noMArginOnLast' key={index}>
                                                    <div className='col-md-10'>
                                                        <FormFloatingLabel onInput={(value) => formik.setFieldValue(`emailAddress.${index}`, { value })} label={"Email Address"} defaultValue={formik.values.emailAddress[index].value} type='email' />
                                                    </div>
                                                    <div className='col-md-2 px-0 align-self-center'>
                                                        <div className='d-flex align-items-center'>
                                                            {formik.values.emailAddress.length === index + 1 && <BlueBtn onClick={() => arrayHelpers.insert(0, "")}>+ URL</BlueBtn>}
                                                            {formik.values.emailAddress.length === index + 1 && index !== 0 && <RemoveBtn className='removeBtn ms-1' onClick={() => arrayHelpers.remove(index)}>-</RemoveBtn>}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    />
                                </TopBottomPadding>
                            </div>
                        }

                    </TopBottomPadding>
                    <TopBottomPadding>
                        <div className='d-flex justify-content-between align-items-center '>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                SMS Notifications
                            </CollapsedAreaLabels>
                            <div className={`itemsPerform `}>
                                <MaterialDesignSwitch defaultchecked={smsNotification} id={"smsNotification"} checked={(checked) => setSmsNotification(checked)} />

                            </div>

                        </div>
                        {smsNotification && <div className='mt-2 border-top'>
                            <TopBottomPadding className='pb-0'>
                                {
                                    formik.values.mobilePhoneNumber.length === 0 &&
                                    <Button type='button' variant='contained' color='primary' onClick={e => {
                                        formik.setFieldValue("mobilePhoneNumber", [{ value: "" }]);
                                    }}>+</Button>
                                }
                                <FieldArray
                                    name="mobilePhoneNumber"
                                    render={arrayHelpers => (
                                        formik.values.mobilePhoneNumber.map((item: any, index: number) =>
                                            <div className='row  noMArginOnLast' key={index}>
                                                <div className='col-md-10'>
                                                    <FormFloatingLabel onInput={(value) => formik.setFieldValue(`mobilePhoneNumber.${index}`, { value: value })} label={"Mobile Phone Number"} defaultValue={formik.values.mobilePhoneNumber[index].value} />
                                                </div>
                                                <div className='col-md-2 px-0 align-self-center'>
                                                    <div className='d-flex align-items-center'>
                                                        {formik.values.mobilePhoneNumber.length === index + 1 && <BlueBtn onClick={() => arrayHelpers.insert(0, "")}>+ URL</BlueBtn>}
                                                        {formik.values.mobilePhoneNumber.length === index + 1 && index !== 0 && <RemoveBtn className='removeBtn ms-1' onClick={() => arrayHelpers.remove(index)}>-</RemoveBtn>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                />
                            </TopBottomPadding>

                        </div>
                        }
                    </TopBottomPadding>

                </CollapsedArea>
                    : null}
            </FormikProvider>
        </>
    );
}

export default LeadNotifications;

const CollapseTriggerHeading = styled.div`
font-size: 17px;

`
const CollapsedArea = styled.div`
padding: 0px 35px;
    position: relative;
    font-size: 16px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
    background: rgb(247, 247, 247);
`

const BlueBtn = styled.span`
border: none;
border-radius: 3px;
text-align: center;
color: rgb(255, 255, 255);
background: rgb(65, 112, 251);
cursor: pointer;
height: 27px;
font-weight: 500;
font-size: 15px;
line-height: 27px;
width: 51px;

`
const RemoveBtn = styled.span`
border: none;
border-radius: 3px;
text-align: center;
color: rgb(255, 255, 255);
background: rgb(251, 104, 93);
cursor: pointer;
height: 27px;
font-weight: 500;
font-size: 15px;
line-height: 27px;
width: 27px;

`