import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import InputPrefix from "Components/PrefixInput";

const LoadingSpinner = () => {
    return (
        <>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Loading Spinner Color

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker defaultValue={"#fff"} />

                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Loading Spinner Size

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Loading Spinner Thickness

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>

        </>
    );
}

export default LoadingSpinner;