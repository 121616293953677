import { Navbar } from 'Components/Navbar';
import EditFormMain from '../Builder/EditForm';

const EditFormLayout = () => {
  return (
    <>
      <Navbar />
      <EditFormMain />
    </>
  );
}

export default EditFormLayout;