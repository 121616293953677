import BackgroundImage from 'Components/BackgroundImage';
import ColorPicker from 'Components/ColorPicker';
import { BlueBtn, CancelBtn, CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import CustomSelect from 'Components/CustomSelect';
import FormFloatingLabel from 'Components/FloatingInput';
import InputPrefix from 'Components/PrefixInput';
import { useFormik } from 'formik';
import { CallAxios, FocusOnView } from 'Helpers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RefreshIconInReduxState } from 'Store';
import styled from 'styled-components';

const Title = styled.div`
color: rgb(102, 102, 102);
font-size: 15px;
font-weight: 500;
`

const AddEditResponseIcon = (props: { setAddicon: (n: boolean) => void, responseID: string, iconData: IconsData }) => {
    const { id, formId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const steps = useSelector((state: AppStore) => state.editor.steps);

    const ICONPOSITION = [
        { value: "top", label: 'Top' },
        { value: "bottom", label: 'Bottom' },
        { value: "left", label: 'Left' },
        { value: "right", label: 'Right' }
    ];
    const CONTENTALIGNMENT = [
        { value: "left", label: 'Left' },
        { value: "center", label: 'Center' },
        { value: "right", label: 'Right' },
        { value: "justify", label: 'Justify' }
    ];
    const formik = useFormik<IconsData>({
        initialValues: {
            imgsrc: "",
            iconButtontext: "",
            iconPosition: { value: "top", label: 'Top' },
            contentAlignment: { value: "left", label: 'Left' },
            contentAlignmentOnMobile: { value: "left", label: 'Left' },
            iconHeight: "",
            iconHeightOnMobile: "",
            iconRightPadding: "",
            iconLeftPadding: "",
            iconButtonColor: "",
            iconButtonColorOnHover: "",
            iconButtonTextColor: "",
            iconButtonTextColorOnHover: ""
        },
        onSubmit: values => {
            const formData = new FormData();
            formData.append('data', JSON.stringify(values));
            CallAxios('/api/Response/SaveIconData|PATCH',
                formData,
                {
                    id: props.responseID,
                    stepId: id,
                    formId: formId,
                    'api-version': '1.0'
                },
                undefined,
                navigate,
                dispatch
            ).then(res => {
                toast(res?.data?.message, { type: res?.data.success ? 'success' : 'warning' });
                FocusOnView('responseWrapper');
                dispatch(RefreshIconInReduxState({ stepID: String(id), responseID: props.responseID, data: values }))
                formik.resetForm();
            });
            props.setAddicon(false)
        }
    });

    useEffect(() => {
        const data = props.iconData;
        if (data) {
            formik.setValues(data)
        }
    }, [props.iconData]);

    return (
        <div className="px-3" id='editor_main'>
            <Title> Icon</Title>
            <TopBottomPadding>

                <BackgroundImage recommendedSize={" "} preview={formik?.values?.imgsrc} getimg={(img) => formik.setFieldValue("imgsrc", img)} />
            </TopBottomPadding>
            <TopBottomPadding>

                <FormFloatingLabel onInput={(value) => formik.setFieldValue("iconButtontext", value)} label={"Icon Button Text"} defaultValue={formik?.values?.iconButtontext} />

            </TopBottomPadding>

            {formik?.values?.iconButtontext !== "" && <div>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Icon Button Color
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                formik.setFieldValue("iconButtonColor", color)
                            }} defaultValue={formik?.values?.iconButtonColor} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Icon Button Color On Hover

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                formik?.setFieldValue("iconButtonColorOnHover", color)
                            }} defaultValue={formik?.values?.iconButtonColorOnHover} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Icon Button Text Color
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                formik.setFieldValue("iconButtonTextColor", color)
                            }} defaultValue={formik?.values?.iconButtonTextColor} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Icon Button Text Color On Hover
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                formik.setFieldValue("iconButtonTextColorOnHover", color)
                            }} defaultValue={formik?.values?.iconButtonTextColorOnHover} />


                        </div>

                    </div>

                </TopBottomPadding>

            </div>}



            {formik?.values?.iconButtontext === "" && <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Icon Position

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform w-50 `}>
                        <CustomSelect defaultValue={formik?.values?.iconPosition} onChange={(selected) => formik.setFieldValue("iconPosition", selected)} data={ICONPOSITION} name={"TemplateStyle"} />


                    </div>

                </div>

            </TopBottomPadding>}
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Content Alignment


                    </CollapsedAreaLabels>
                    <div className={`itemsPerform w-50 `}>
                        <CustomSelect defaultValue={formik?.values?.contentAlignment} onChange={(selected) => formik.setFieldValue("contentAlignment", selected)} data={CONTENTALIGNMENT} name={"TemplateStyle"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Content Alignment On Mobile

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform w-50 `}>
                        <CustomSelect defaultValue={formik?.values?.contentAlignmentOnMobile} onChange={(selected) => formik.setFieldValue("contentAlignmentOnMobile", selected)} data={CONTENTALIGNMENT} name={"TemplateStyle"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Icon Height


                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={formik?.values?.iconHeight} onInput={(value) => formik.setFieldValue("iconHeight", value)} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Icon Height On Mobile


                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={formik?.values?.iconHeightOnMobile} onInput={(value) => formik.setFieldValue("iconHeightOnMobile", value)} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            {formik?.values?.iconButtontext === "" && <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Icon Right Padding


                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={formik?.values?.iconRightPadding} onInput={(value) => formik.setFieldValue("iconRightPadding", value)} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>}
            {formik?.values?.iconButtontext === "" && <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Icon Left Padding


                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={formik?.values?.iconLeftPadding} onInput={(value) => formik.setFieldValue("iconLeftPadding", value)} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>}
            <div className='d-flex justify-content-between align-items-center px-5'>
                <BlueBtn onClick={() => {
                    formik.handleSubmit();
                }}
                    className="me-3">Save response</BlueBtn>
                <CancelBtn onClick={() => {
                    formik.resetForm();
                    props.setAddicon(false);
                    FocusOnView('responseWrapper');
                }}>Cancel</CancelBtn>
            </div>
        </div>
    );
}

export default AddEditResponseIcon;