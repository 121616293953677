import React, { useState, useEffect } from 'react';
import FormFloatingLabel from "Components/FloatingInput"
import MaterialDesignSwitch from "Components/MaterialSwitch"
import ToolTip from 'Components/Tooltip';
import { ResponsesHeader, ResponsesHeaderSpan, TopBottomPadding, CollapsedAreaLabels } from 'Components/Common';
import { useSelector, useDispatch } from 'react-redux';
import { setStepOptions, setStepOptionsInner } from 'Store';
import { Checkbox, FormControlLabel } from '@mui/material';

const TextBlock = (props: { formik: FormikType<FormSteps>; }) => {
    const { formik } = props;
    const dispatch = useDispatch();
    const stepOptions = useSelector((state: AppStore) => state.editor.stepOptions);

    return (
        <div className='p-3'>
            <div className="mb-3">
                <FormFloatingLabel
                    label={"Step name*"}
                    onInput={(value) => {
                        formik.setFieldValue("name", value);
                        dispatch(setStepOptions({ name: value }))
                    }}
                    defaultValue={stepOptions?.name} />
                {
                    formik.errors.name && formik.touched.name && (
                        <small className="text-danger">{formik.errors.name}</small>
                    )
                }
            </div>
            <div className="mb-3">
                <FormFloatingLabel
                    label={"Text block text*"}
                    onInput={(value) => {
                        formik.setFieldValue("textBlockText", value);
                        dispatch(setStepOptionsInner({ textBlockText: value }))
                    }}
                    defaultValue={stepOptions?.textBlockText} />
                {
                    formik.errors.textBlockText && formik.touched.textBlockText && (
                        <small className="text-danger">{formik.errors.textBlockText}</small>
                    )
                }
            </div>
            <div className="mb-3">
                <FormControlLabel control={<Checkbox onChange={e => dispatch(setStepOptionsInner({ isFinalStep: e.target.checked }))} checked={stepOptions?.isFinalStep || false} />} label="Mark as final step?" />
                <FormFloatingLabel
                    label={"Progress"}
                    type='text'
                    regex={/^(0|[1-9][0-9]?|100)$/}
                    onInput={(value) => {
                        formik.setFieldValue("progress", value);
                        dispatch(setStepOptionsInner({ progress: Number(value) }))
                    }}
                    defaultValue={stepOptions?.progress || 0} />
            </div>
            <TopBottomPadding>
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Send Lead?

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform`}>
                        <MaterialDesignSwitch
                            defaultchecked={stepOptions?.sendLead}
                            id={"SendLead"}
                            checked={(checked) => dispatch(setStepOptionsInner({ sendLead: checked }))} />
                    </div>
                </div>
            </TopBottomPadding>
            <ResponsesHeader className='mb-5'>
                <ResponsesHeaderSpan>ADVANCED OPTIONS</ResponsesHeaderSpan>
            </ResponsesHeader>

            <div className="mb-3">
                <FormFloatingLabel label={"Text block Additional Text"}
                    onInput={(value) => {
                        dispatch(setStepOptionsInner({ textBlockAdditionalText: value }))
                    }}
                    defaultValue={stepOptions?.textBlockAdditionalText} />
            </div>
            {CustomCode()}
        </div>
    );
}

export default TextBlock;


const CustomCode = () => {
    const [customCode, setCustomCode] = useState(false);
    return (
        <>
            <div className='py-3'>
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Custom Code

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform`}>
                        <MaterialDesignSwitch defaultchecked={customCode} id={"customCode"} checked={(checked) => setCustomCode(checked)} />
                    </div>
                </div>
            </div>
            {customCode === true ? <div className='ShowCustomTeaserWrapper mb-3'>
                <p className='mb-0'>Custom HTML</p>
                <div className='TeaserHtml mb-3'>
                    <div className='d-flex mb-2'>
                        <span>Conditions example:</span>
                        <div className='ms-2'>
                            <ToolTip text={`{[test_question__response1]?}<div>This text is visible if the 'test_question__response1' variable is present in the user's answers.</div>{/?}{[test_question__response1, test_question__response2]?}<div>This text is visible if both the 'test_question__response1' and 'test_question__response2' variables are present in the user's answers.</div>{/?}{any[test_question__response1, test_question__response2]?}<div>This text is visible if at least one of the 'test_question__response1' or 'test_question__response2' variables is present in the user's answers.</div>{/?}<div>This text is always visible.</div>`} />
                        </div>
                    </div>
                    <textarea className='form-control' rows={3}></textarea>
                </div>
                <div className='TeaserCSS'>
                    <div className='d-flex mb-2'>
                        <p className='mb-0'>Custom CSS</p>
                    </div>
                    <textarea className='form-control' rows={3}></textarea>
                </div>
            </div> : null}
        </>
    )
}