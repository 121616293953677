
import { GreenTickIcon, TextBlockText, TextBlockWrapper } from 'Pages/Forms/Builder/Components';
import { useSelector } from 'react-redux';

const TextBlockEndStep = (props: { currentSlice: StoreSlices, thankyouStyle: any }) => {
    const { thankyouStyle, currentSlice } = props;
    const stepOptions = useSelector((state: AppStore) => state[currentSlice].stepOptions);

    return (
        <TextBlockWrapper>
            <GreenTickIcon />
            <div>
                <TextBlockText
                    fc={thankyouStyle?.thankyouStepFontColor}
                    fw={thankyouStyle?.thankyouStepFontWeight}
                    fs={thankyouStyle?.thankyouStepFontSize}>
                    {
                        stepOptions?.textBlockText || `Thank you for the information`
                    }
                </TextBlockText>
                <TextBlockText
                    fc={thankyouStyle?.thankyouStepFontColor}
                    fw={thankyouStyle?.thankyouStepFontWeight}
                    fs={thankyouStyle?.thankyouStepFontSize}>
                    {
                        stepOptions?.textBlockAdditionalText || ``
                    }
                </TextBlockText>
            </div>
            {/* {
                    !thankyouStyle?.thankyouStepDarkCloseButton ?
                        <CloseBtn onClick={e => navigate('/')}>Close</CloseBtn> :
                        <CloseBtnDark onClick={e => navigate('/')}>Close</CloseBtnDark>
                } */}
        </TextBlockWrapper>
    )
}

export default TextBlockEndStep