import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, CardContent, FormControl, TextField, Typography } from "@mui/material";
import { CallAxios } from "Helpers";
import { SignInManager } from "Helpers/Authentication/SignInManager";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from 'yup';

export const ChangePassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = () => {
        new SignInManager().RemoveToken();
        navigate('/login');
    }

    const { register,
        handleSubmit,
        control,
        formState: { errors } } = useForm<ChangePassword>({
            mode: 'all',
            resolver: yupResolver(formSchema),
            defaultValues: {

            }
        });

    return (
        <div>
            <form onSubmit={handleSubmit(v => CallAxios('/api/Clients/ChangePassword|POST',
                v,
                undefined,
                undefined,
                navigate,
                dispatch).then(res => {
                    if (res?.data.success) {
                        toast(res.data.message, { type: 'success' });
                        logout();
                    } else {
                        toast(res?.data?.message, { type: 'error' });
                    }
                }).catch(err => {
                    if (err.status == 400) {
                        toast('All fields are required.', { type: 'warning' });
                    }
                })
            )}>
                <Card className="p-3">
                    <Typography component="div" variant="h5" className="ml-1">Change Password</Typography>
                    <CardContent>
                        <FormControl fullWidth>
                            <TextField type='password' label='Current password' {...register('currentPassword')} error={!!errors.currentPassword?.message} />
                            <ErrorMessage name="currentPassword" errors={errors} as={<span className="text-danger"></span>} />
                        </FormControl>

                        <FormControl fullWidth className="mt-2">
                            <TextField type='password' label='New password' {...register('newPassword')} error={!!errors.newPassword?.message} />
                            <ErrorMessage name="newPassword" errors={errors} as={<span className="text-danger"></span>} />
                        </FormControl>

                        <FormControl fullWidth className="mt-2">
                            <TextField type='password' label='Confirm password' {...register('confirmPassword')} error={!!errors.confirmPassword?.message} />
                            <ErrorMessage name="confirmPassword" errors={errors} as={<span className="text-danger"></span>} />
                        </FormControl>

                        <Button variant="contained" color='primary' className="mt-2 float-end" type='submit'>Change Password</Button>
                    </CardContent>
                </Card>
            </form>
        </div>
    )
}

const formSchema = Yup.object().shape({
    currentPassword: Yup.string()
        .required("Password is required")
        .min(4, "Password length should be at least 4 characters")
        .max(12, "Password cannot exceed more than 12 characters"),
    newPassword: Yup.string()
        .required("Password is required")
        .min(4, "Password length should be at least 4 characters")
        .max(12, "Password cannot exceed more than 12 characters"),
    confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .min(4, "Password length should be at least 4 characters")
        .max(12, "Password cannot exceed more than 12 characters")
        .oneOf([Yup.ref("newPassword")], "Passwords do not match")
});