import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { defaultstepOptions } from 'commonJSON';
import { StepTypeSelect } from 'Components';
import { SideBarHeaderWrapper } from 'Components/Common';
import ToolTip from 'Components/Tooltip';
import { useFormik } from 'formik';
import { AxiosLite, CallAxios, ExecuteParsers } from 'Helpers';
import { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { IoCopyOutline } from "react-icons/io5";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { removeStep, saveStep, setAsEntryStep, setEntryStepAsCurrent, setStepOptions } from "Store/slices/EditFormSlice";
import styled from 'styled-components';
import * as Yup from "yup";
import FormBlock from "./FormBlock";
import QuestionBlock from "./Questionblock";
import TextBlock from "./TextBlock";

const Steps = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stepOptions = useSelector((state: AppStore) => state.editor.stepOptions);
  const steps = useSelector((state: AppStore) => state.editor.steps);

  const formik = useFormik<FormSteps>({
    initialValues: {
      isEntryStep: false,
      name: "",
      questionText: "",
      formTitle: "",
      submitButtonLabel: "",
      textBlockText: "",
    },
    validationSchema: validationStep[stepOptions?.stepType === "Question" ? 0 : (stepOptions?.stepType === "Form" ? 1 : 2)],
    onSubmit: (values, { resetForm }) => {
      SaveStep();
      // resetForm();
    },
  })

  useEffect(() => {
    if (stepOptions) {
      formik.setValues({
        isEntryStep: stepOptions?.isEntryStep,
        progress: stepOptions.progress || 0,
        formTitle: stepOptions?.formTitle || '',
        questionText: stepOptions?.questionText || '',
        name: stepOptions.name || '',
        submitButtonLabel: stepOptions?.submitButtonLabel || '',
        textBlockText: stepOptions?.textBlockText || ''
      })
    }
  }, [stepOptions]);

  const SaveStep = () => {
    CallAxios('/api/Steps/SaveStep|POST',
      {
        ...stepOptions,
        id: stepOptions?.id || undefined,
        formId: formId,
        responses: []
        // stepType: stepOptions?.stepType,
        // name: stepOptions?.name,
        // questionText: stepOptions?.questionText,
        // subTitle: stepOptions?.subTitle,
        // textBlockText: stepOptions?.textBlockText,
        // sendLead: stepOptions?.sendLead,
        // submitButtonLabel: stepOptions?.submitButtonLabel,
        // targetStepId: stepOptions?.targetStepId,
        // stepTeaserText: stepOptions?.stepTeaserText,
        // includeSMS: stepOptions?.includeSMS,
        // formTitle: stepOptions?.formTitle,
        // textBlockAdditionalText: stepOptions?.textBlockAdditionalText
      },
      undefined,
      undefined,
      navigate,
      dispatch
    ).then(res => {
      if (stepOptions && res?.data.data) {
        formik.setFieldValue('id', res.data.data.id);
        dispatch(saveStep({
          ...stepOptions,
          formId: String(formId),
          id: res.data.data.id,
          stepOrder: res.data.data.stepOrder
        }));
      }

      toast(res?.data?.message, { type: res?.data.success ? 'success' : 'warning' });
      navigate(`/editForm/${formId}/${res?.data.data.id}`);
    });
  }

  const setEntryStep = async () => {
    if (stepOptions?.id) {
      await AxiosLite('/api/Steps/SetAsEntryStep|GET', {
        formId: formId,
        stepId: stepOptions?.id
      }, navigate, dispatch);
      dispatch(setAsEntryStep({ stepId: stepOptions?.id }));
      toast('Marked as entry step.')
    }
    else {
      toast('Something went wrong.')
    }
  }

  const DeleteStep = () => {
    confirmAlert({
      closeOnEscape: true,
      message: `Do you really want to delete the step?\r\nThis action can't be undone.`,
      closeOnClickOutside: false,
      title: 'Are you sure?',
      buttons: [
        {
          label: 'Yes, Delete',
          className: 'bg-danger',
          onClick: async () => {
            try {
              const resp = await AxiosLite('/api/Steps/DeleteStep|DELETE',
                {
                  id: stepOptions?.id,
                  formId: formId
                })
              if (resp?.data.success) {
                dispatch(removeStep({ stepId: String(stepOptions?.id) }));
                dispatch(setEntryStepAsCurrent());
                const step = steps.find(x => x.isEntryStep) || (steps.length > 0 ? steps[0] : defaultstepOptions);
                navigate(`/editForm/${formId}/${step.id}`);
                toast('Step is deleted.');
              }
            } catch (error) {
              console.log(error);
              toast('Failed to delete step.', { type: 'error' });
            }
          }
        },
        {
          label: 'No, Cancel',
          onClick: () => {
            return;
          }
        }
      ]
    })
  };

  return (
    <>
      <SideBarHeaderWrapper style={{ padding: "10px 20px" }}>
        <div className='d-flex justify-content-between align-items-center'>
          <StepNameForQuestion><span>Create:</span> {stepOptions?.name}
            {
              stepOptions?.name && stepOptions?.stepType === "Question" &&
              <VariableName>
                <span className='fw-normal'>Variable name: </span>
                {`{${stepOptions.name.replaceAll(" ", "_")}}`}
                <div className=' ms-2 d-inline-block'>
                  <ToolTip text={`Put it as "{${stepOptions.name.replaceAll(" ", "_")}}" in any input field to replace the variable name with the actual value (the user's answer) from the step.`} /></div>
              </VariableName>
            }
          </StepNameForQuestion>
          <div>
            {
              stepOptions?.id && <ICONS>
                <Tooltip title='Duplicate step'>
                  <IconButton onClick={async e => {
                    const resp = await AxiosLite('/api/Steps/CopyStep|POST',
                      {
                        id: stepOptions?.id,
                        formId: formId
                      },
                      navigate,
                      dispatch);
                    if (resp?.data.data) {
                      resp.data.data.responses?.forEach(r => {
                        r = ExecuteParsers(r);
                      })
                      dispatch(saveStep({ ...resp?.data.data }));
                    }
                  }}>
                    <IoCopyOutline />
                  </IconButton>
                </Tooltip>
              </ICONS>
            }
            <button
              className='btn btn-primary btn-sm mx-3'
              onClick={() => formik.handleSubmit()}>Save step</button>

            {
              stepOptions?.id && <ICONS>
                <Tooltip title='Delete forever'>
                  <IconButton onClick={DeleteStep}>
                    <MdDeleteForever className='text-danger' />
                  </IconButton>
                </Tooltip>
              </ICONS>
            }
          </div>
        </div>
      </SideBarHeaderWrapper>
      <div className='CollapseAbleWrapper p-2 bg-white'>
        <div className='px-3'>
          {
            ((stepOptions?.stepType === "Question" || stepOptions?.stepType === "Form") && !stepOptions?.isEntryStep && stepOptions.id) &&
            <div className='w-50 my-2'>
              <Button variant='contained' color='secondary' onClick={setEntryStep}>Set as entry step</Button>
            </div>
          }

          {!stepOptions?.id && <StepTypeSelect
            onChange={(selected) => { dispatch(setStepOptions({ stepType: selected })) }}
            defaultValue={stepOptions?.stepType}
            name={"selectype"} />}
          {stepOptions?.id && <div className='text-center'>{stepOptions?.stepType}</div>}
        </div>
        {stepOptions?.stepType === "Question" ? <QuestionBlock formik={formik} /> : stepOptions?.stepType === "Form" ? <FormBlock formik={formik} /> : <TextBlock formik={formik} />}

      </div>
    </>
  );
}

export default Steps;

const StepNameForQuestion = styled.div`
font-weight: 700;
color: rgb(153, 153, 153);
font-size:16px;
line-height:16px;
`
const VariableName = styled.div`
font-weight: 700;
font-size: 13px;
margin-top: 2px;
`
const ICONS = styled.span`
font-size: 22px;
position: relative;
top: -2px;
color: rgb(170, 170, 170);
cursor:pointer;
&:hover{
    color: rgb(51, 51, 51);

}
`
const questionSchema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  questionText: Yup.string().required("This field is required.")
});
const formSchema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  formTitle: Yup.string().required("This field is required."),
  submitButtonLabel: Yup.string().required("This field is required.")
});
const textblockSchema = Yup.object().shape({
  name: Yup.string().required("This field is required."),
  textBlockText: Yup.string().required("This field is required.")
});
const validationStep = [
  questionSchema,
  formSchema,
  textblockSchema
]