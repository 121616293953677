import { CollapsedAreaLabels } from "Components/Common";
import BackgroundImage from "Components/BackgroundImage";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import ToolTip from 'Components/Tooltip';

const Avatartab = (props: { dispatch: any; settings: any; setSettings: any; }) => {
    const { dispatch, settings, setSettings } = props;

    return (
        <>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Hide Avatar- here
                    <div className='ms-2'>
                        <ToolTip text={`Disabling this setting will hide the profile image/avatar from appearing across all steps of your form.`} />
                    </div>
                </CollapsedAreaLabels>
                <div className={`itemsPerform `}>
                    <MaterialDesignSwitch defaultchecked={settings?.hideAvatarhere} id={"HideAvatarhere"} checked={(checked) => dispatch(setSettings({ hideAvatarhere: checked }))} />
                </div>
            </div>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Hide Avatar After Step 1
                    <div className='ms-2'>
                        <ToolTip text={`Disabling this setting will hide the profile image/avatar on every step after the first step. So, it will appear on the first step then automatically be removed across all other steps.`} />
                    </div>
                </CollapsedAreaLabels>
                <div className={`itemsPerform `}>
                    <MaterialDesignSwitch defaultchecked={settings?.hideAvatarAfterStepOne} id={"HideAvatarAfterStepone"} checked={(checked) => dispatch(setSettings({ hideAvatarAfterStepOne: checked }))} />
                </div>
            </div>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Hide Avatar (Embedded)

                </CollapsedAreaLabels>
                <div className={`itemsPerform `}>
                    <MaterialDesignSwitch defaultchecked={settings?.hideAvatar_Embedded} id={"HideAvatarAfterStepone"} checked={(checked) => dispatch(setSettings({ hideAvatar_Embedded: checked }))} />
                </div>
            </div>
            <div className='border-top'>
                <CollapsedAreaLabels className='d-flex mt-2 mb-3 align-items-center'>
                    Avatar Image
                </CollapsedAreaLabels>
                <BackgroundImage preview={settings?.AvatarImage} getimg={(img) => dispatch(setSettings({ AvatarImage: img }))} recommendedSize={`Minimum recommended size: 70X70px`} />
            </div>
        </>
    )

}


export default Avatartab;