import React, { useState, useEffect } from 'react';
import { CommonArea } from "./Common";
import styled from 'styled-components';

const InputPrefix = (props:
    {
        onInput?: (text: string) => void;
        defaultValue?: string;
        width?: string | number | undefined;
        prefix?: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
    }) => {
    const [value, setValue] = useState("");

    const setInputValue = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.target instanceof HTMLInputElement) {
            const val = e.target.value;
            setValue(val);
            if (props.onInput)
                props.onInput(val)
        }
    }
    useEffect(() => {
        if (props.defaultValue) {
            setValue(props.defaultValue)
        }
    }, [props.defaultValue]);

    return (
        <CommonArea>
            <MainDiv>
                <div className='d-flex align-items-center justify-content-between'>
                    <InputwithoutStyle width={props.width} value={value} onInput={(e) => setInputValue(e)} />
                    <Font14 className='ps-2'>
                        {props.prefix}
                    </Font14>
                </div>
            </MainDiv>
        </CommonArea>
    );
}

export default InputPrefix;

const MainDiv = styled.div`
background: rgb(255, 255, 255);
    padding: 5px;
    border-radius: 4px;
    color: rgb(102, 102, 102);
    border: 1px solid rgba(153, 153, 153, 0.6);
    `
const InputwithoutStyle = styled.input`
outline: none;
padding: 0px;
width: ${props => !!props.width ? props.width + 'px' : '25px'};
font-family: Lato, sans-serif;
color: rgb(102, 102, 102);
font-size: 14px;
display: block;
border: none;
background: none;`

const Font14 = styled.div`
font-size:14px;
`