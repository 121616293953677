import { useState } from 'react';
import { CollapsedAreaLabels, CommonArea, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import InputPrefix from "Components/PrefixInput";
import CustomSelect from "Components/CustomSelect";
import ToolTip from 'Components/Tooltip';

import { BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { setDesignOptions } from "Store/slices/EditFormSlice";

const ResponseStyling = () => {
    const dispatch = useDispatch();
    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);

    const [ResponseGridWidth, setResponseGridWidth] = useState([
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
    ]);

    const [ResponseTextAlign, setResponseTextAlign] = useState([
        { value: 'left', label: 'Left' },
        { value: 'center', label: 'Center' },
        { value: 'right', label: 'Right' }
    ]);

    const [ResponseFontWeight, setResponseFontWeight] = useState([
        { value: 400, label: '400' },
        { value: 500, label: '500' },
        { value: 600, label: '600' },
        { value: 700, label: '700' },
        { value: 900, label: '900' },
    ]);
    const [MultiselectStyling, setMultiselectStyling] = useState(false);
    return (
        <>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Grid Width
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  w-50`}>
                        <CustomSelect defaultValue={designOptions.responseStyling.responseGridWidth} onChange={(selected) => dispatch(setDesignOptions({ key: "responseStyling", data: { responseGridWidth: selected } }))} data={ResponseGridWidth} name={"ResponseGridWidth"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Responses Box Max Width
                        <div className='ms-2'>
                            <ToolTip text={`Use this setting to adjust the font size of your form's question text`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.responseStyling.responseBoxMaxWidth} onInput={(value) => dispatch(setDesignOptions({ key: "responseStyling", data: { responseBoxMaxWidth: value } }))} prefix={"%"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Responses Box Max Width On Mobile

                        <div className='ms-2'>
                            <ToolTip text={`Use this setting to adjust the font size of your form's question text`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.responseStyling.responseBoxMaxWidthOnMobile} onInput={(value) => dispatch(setDesignOptions({ key: "responseStyling", data: { responseBoxMaxWidthOnMobile: value } }))} prefix={"%"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Font Size
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the font size of your response button text.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.responseStyling.responseFontSize} onInput={(value) => dispatch(setDesignOptions({ key: "responseStyling", data: { responseFontSize: value } }))} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Font Size Mobile

                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the font size of your response button text on mobile only.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Text Align
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  w-50`}>
                        <CustomSelect defaultValue={designOptions.responseStyling.responseTextAlign} onChange={(selected) => dispatch(setDesignOptions({ key: "responseStyling", data: { responseTextAlign: selected } }))} data={ResponseTextAlign} name={"ResponseTextAlign"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Font Weight
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the font thickness of your response button text.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  w-50`}>
                        <CustomSelect defaultValue={designOptions.responseStyling.responseFontWeight} onChange={(selected) => dispatch(setDesignOptions({ key: "responseStyling", data: { responseFontWeight: selected } }))} data={ResponseFontWeight} name={"ResponseFontWeight"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Vertical Padding

                        <div className='ms-2'>
                            <ToolTip text={`This adds or reduces space (padding) between response buttons.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.responseStyling.responseMarginBottom} onInput={(value) => dispatch(setDesignOptions({ key: "responseStyling", data: { responseMarginBottom: value } }))} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Vertical Padding (Inside)

                        <div className='ms-2'>
                            <ToolTip text={`Use this setting to make your response buttons larger. For example, setting the value to "3" will make the buttons appear smaller. While a value of "20" will make the buttons look larger.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.responseStyling.responseVerticalPadding} onInput={(value) => dispatch(setDesignOptions({ key: "responseStyling", data: { responseVerticalPadding: value } }))} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Color
                        <div className='ms-2'>
                            <ToolTip text={`Use this to adjust the color of your response buttons.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "responseStyling", data: { responseBgColor: color } }))
                        }} defaultValue={designOptions.responseStyling.responseBgColor} />

                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Color On Hover
                        <div className='ms-2'>
                            <ToolTip text={`This will apply a hover effect to your response buttons when a prospect goes to over hover the button.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "responseStyling", data: { responseHoverBgColor: color } }))
                        }} defaultValue={designOptions.responseStyling.responseHoverBgColor} />

                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Border Color
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the border color of your response buttons.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "responseStyling", data: { responseBorderColor: color } }))
                        }} defaultValue={designOptions.responseStyling.responseBorderColor} />

                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Text Color
                        <div className='ms-2'>
                            <ToolTip text={`This setting adjusts the color of the text inside of your response buttons.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {
                            dispatch(setDesignOptions({ key: "responseStyling", data: { responseFontColor: color } }))
                        }} defaultValue={designOptions.responseStyling?.responseFontColor} />

                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Response Text Color On Hover
                        <div className='ms-2'>
                            <ToolTip text={`This setting adjusts the hover color of the text inside of your response buttons.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "responseStyling", data: { responseHoverFontColor: color } }))
                        }} defaultValue={designOptions.responseStyling.responseHoverFontColor} />

                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div onClick={() => setMultiselectStyling(!MultiselectStyling)} className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Multi-Select Styling

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>

                        <CommonArea className={`${MultiselectStyling === true ? "rotateit" : ""}`}>
                            <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                        </CommonArea>
                    </div>

                </div>

            </TopBottomPadding>
            {MultiselectStyling === true ?
                 <>
                 <TopBottomPadding>


                     <div className='d-flex justify-content-between align-items-center'>
                         <CollapsedAreaLabels className='d-flex align-items-center'>
                             Multiselect Color

                         </CollapsedAreaLabels>
                         <div className={`itemsPerform  `}>
                             <ColorPicker getColor={(color) => {

                                 dispatch(setDesignOptions({ key: "responseStyling", data: { multiselectColor: color } }))
                             }} defaultValue={designOptions.responseStyling.multiselectColor} />

                         </div>

                     </div>

                 </TopBottomPadding>
                 <TopBottomPadding>


                     <div className='d-flex justify-content-between align-items-center'>
                         <CollapsedAreaLabels className='d-flex align-items-center'>
                         Multiselect Text Color

                         </CollapsedAreaLabels>
                         <div className={`itemsPerform  `}>
                             <ColorPicker getColor={(color) => {

                                 dispatch(setDesignOptions({ key: "responseStyling", data: { multiselectTextColor: color } }))
                             }} defaultValue={designOptions.responseStyling.multiselectTextColor} />

                         </div>

                     </div>

                 </TopBottomPadding>
                 <TopBottomPadding>


                     <div className='d-flex justify-content-between align-items-center'>
                         <CollapsedAreaLabels className='d-flex align-items-center'>
                         Multiselect Selected Color

                         </CollapsedAreaLabels>
                         <div className={`itemsPerform  `}>
                             <ColorPicker getColor={(color) => {

                                 dispatch(setDesignOptions({ key: "responseStyling", data: { multiselectSelectedColor: color } }))
                             }} defaultValue={designOptions.responseStyling.multiselectSelectedColor} />

                         </div>

                     </div>

                 </TopBottomPadding>
                 <TopBottomPadding>


                     <div className='d-flex justify-content-between align-items-center'>
                         <CollapsedAreaLabels className='d-flex align-items-center'>
                         Multiselect Selected Text Color

                         </CollapsedAreaLabels>
                         <div className={`itemsPerform  `}>
                             <ColorPicker getColor={(color) => {

                                 dispatch(setDesignOptions({ key: "responseStyling", data: { multiselectSeletedTextColor: color } }))
                             }} defaultValue={designOptions.responseStyling.multiselectSeletedTextColor} />

                         </div>

                     </div>

                 </TopBottomPadding>
             </>
                : null}

        </>
    );
}

export default ResponseStyling;