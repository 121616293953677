import { AnyAction } from '@reduxjs/toolkit';
import axios, { AxiosRequestHeaders } from 'axios';
import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { setisLoading } from 'Store';
import { SignInManager } from '../Authentication/SignInManager';
import { config } from '../Config';
import { BodyTypes } from './Body';
import { ParamTypes } from './Params';
import { DataReturnTypes } from './Response';

axios.defaults.baseURL = config.API_URL;

export const AxiosLite =
    async <TUrl extends API_URLs = API_URLs, TParams extends ParamTypes[TUrl] = ParamTypes[TUrl]>(url: TUrl,
        params?: TParams,
        navigate?: NavigateFunction,
        dispatch?: Dispatch<AnyAction>,
        shouldAttachToken = true,
        shouldLoad= true,
    ) => {
        return CallAxios(url, undefined, params, undefined, navigate, dispatch, shouldAttachToken, shouldLoad);
    };

export const CallAxios = async <TUrl extends API_URLs = API_URLs, TParams extends ParamTypes[TUrl] = ParamTypes[TUrl], TBody extends BodyTypes[TUrl] = BodyTypes[TUrl], TResponse extends DataReturnTypes[TUrl] = DataReturnTypes[TUrl]>(
    url: TUrl,
    body?: TBody,
    params?: TParams,
    headers?: AxiosRequestHeaders,
    navigate?: NavigateFunction,
    dispatch?: Dispatch<AnyAction>,
    shouldAttachToken = true,
    shouldLoad=true,
) => {
    if (url) console.log('URL: ', url);
    if (body) console.log('Body: ', body);
    if (params) console.log('Params: ', params);
    if (dispatch && shouldLoad==true) dispatch(setisLoading(true))
    try {
        const res = await axios.request<TResponse>({
            url: url.split('|')[0],
            method: url.split('|')[1],
            params,
            headers: shouldAttachToken ? { ...headers, Authorization: ('Bearer ' + new SignInManager().AccessToken) } : { ...headers },
            data: body
        });
        console.log('Response: ', res);
       if (dispatch && shouldLoad==true) dispatch(setisLoading(false))
        return res;
    } catch (err: any) {
        console.error(err, err.response);
       if (dispatch && shouldLoad==true) dispatch(setisLoading(false))
        if (err.response) {
            if (err.response.status === 401) {
                const rUri = window.location.pathname
                    .replace('login', '');
                if (navigate)
                    navigate('/login?redirect_url=' + rUri);
            }
            else if (err.response.status === 403)
                if (navigate)
                    navigate('/forbidden403');
        }
    }
};

type API_URLs = keyof DataReturnTypes;


