import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { UseFormReturn } from "react-hook-form";

export const FormDatepicker = (props: {
  portal?: boolean,
  data: FormStepResponses,
  formMain: UseFormReturn<any, any>,
  currentSlice: StoreSlices
}) => {
  const { portal, data, formMain: { register } } = props;
  const [date, setDate] = useState<moment.Moment | null>(moment());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [date]);

  return (
    <>
      {
        portal ? <DesktopDatePicker
          className='w-100 mb-2'
          label={data.fieldValue || "Date"}
          closeOnSelect
          inputFormat="MM/DD/YYYY"
          value={date}
          open={open}
          onChange={setDate}
          renderInput={(params) => <TextField {...params} onClick={e => setOpen(true)} {...register(String(data.id))} />}
        /> :
          <MobileDatePicker
            label={data.fieldValue || "Date"}
            className='w-100 mb-2'
            inputFormat="MM/DD/YYYY"
            value={date}
            renderInput={(params) => <TextField {...params} {...register(String(data.id))} />}
            onChange={setDate} />
      }
    </>
  )

}

