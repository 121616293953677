import { Box, CssBaseline, Toolbar } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Sidebar from "Layout/Sidebar/Sidebar";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { Outlet } from "react-router-dom";

const drawerWidth = 310;

export const Mainlayout = () => {
    const [menu, setMenu] = useState(true);
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={menu}>
                <Toolbar variant="regular">
                    <IconButton
                        onClick={e => setMenu(!menu)}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}>
                        {
                            menu ?
                                <RiMenuFoldLine />
                                : <RiMenuUnfoldLine />
                        }
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                        Lead Page Magnet
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer sx={{
                padding: 10,
                width: drawerWidth,
                flexShrink: 0,
                borderRight: 'none',
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
                variant="persistent"
                anchor="left"
                open={menu}>
                <Sidebar />
            </Drawer>
            <Main open={menu}>
                <Toolbar />
                <Container fluid >
                    <Outlet />
                </Container>
            </Main>
        </Box>
    );
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    background: `linear-gradient(90deg, #E80A89 0%, #F15B2A 100%)`,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));
interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}