import {
    Button,
    Card,
    CardContent, FormControlLabel,
    IconButton, TextField
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AxiosLite, CallAxios } from 'Helpers';
import * as React from 'react';
import { Badge } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { confirmAlert } from 'react-confirm-alert';
import { Controller, useForm } from 'react-hook-form';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const EventCategoriesList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState<EventCategories[]>([]);
    const [show, setShow] = React.useState(false);

    const { register, formState: { errors }, handleSubmit, reset, control } = useForm<EventCategories>({
        defaultValues: {
            id: undefined,
            name: '',
            color: '',
            published: false,
        },
        shouldFocusError: true,
    });

    const handleClose = () => {
        reset({});
        setShow(false);
    }

    const handleShow = () => {
        //reset({});
        setShow(true);
    }

    const loadData = () => {
        AxiosLite(
            '/api/EventCategories/List|GET',
            undefined,
            navigate,
            dispatch
        ).then(res => {
            reset({});
            setRows(res?.data.list || []);
        });
    };

    React.useEffect(() => {
        loadData();
    }, []);

    const Delete = (id?: string) => {
        confirmAlert({
            closeOnClickOutside: false,
            closeOnEscape: false,
            title: 'Are you sure?',
            message: 'This action can\'t be undone.',
            buttons: [
                {
                    label: 'Yes',
                    className: 'bg-danger',
                    onClick: () => {
                        AxiosLite('/api/EventCategories/Delete|DELETE',
                            {
                                id: id
                            },
                            navigate,
                            dispatch).then(res => {
                                loadData();
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    return (
        <div>
            <Card style={{ boxShadow: '0px 1px 15px 1px rgb(69 65 78 / 8%)' }}>
                <Button
                    className='float-end me-2 mt-2'
                    onClick={_ => {
                        reset({});
                        handleShow();
                    }}
                    variant='contained'
                    color='primary'>Add</Button>
                <CardContent>
                    <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
                        <Table sx={{ boxShadow: 'none' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Color</TableCell>
                                    <TableCell>Created On</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows.map((row) =>
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>
                                                <span style={{
                                                    display: 'block',
                                                    backgroundColor: row.color,
                                                    width: 25,
                                                    height: 25,
                                                    borderRadius: 25
                                                }}></span>
                                            </TableCell>
                                            <TableCell>{row.createdDateUtc}</TableCell>
                                            <TableCell>
                                                <Badge bg={row.published ? 'success' : 'danger'} children={row.published ? 'Active' : 'In-Active'} />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={e => {
                                                    reset(row);
                                                    handleShow();
                                                }}>
                                                    <MdEdit />
                                                </IconButton>
                                                <IconButton onClick={_ => Delete(row.id)}>
                                                    <MdDeleteForever className='text-danger' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Modal
                show={show}
                centered
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(values => {
                        CallAxios('/api/EventCategories/Save|POST',
                            {
                                ...values,
                            },
                            undefined,
                            undefined,
                            navigate,
                            dispatch)
                            .then(res => {
                                toast(res?.data.message, {
                                    type: res?.data.success ? 'success' : 'error'
                                });
                                if (res?.data.success) {
                                    reset({});
                                    loadData();
                                    handleClose();
                                }
                            })
                    })}>
                        <TextField
                            className='w-100 mb-2'
                            label='Name'
                            {...register('name', {
                                required: { message: 'Required', value: true }
                            })}
                            error={!!errors['name']?.message} />

                        <TextField
                            type={'color'}
                            className='w-100 mb-2'
                            label='Color'
                            {...register('color', {
                                required: { message: 'Required', value: true }
                            })}
                            error={!!errors['color']?.message} />

                        <Controller
                            control={control}
                            name='published'
                            rules={{}}
                            render={({ field }) =>
                                <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label="Is Active" />
                            } />

                        <Button
                            variant="contained"
                            color='success'
                            className='float-end'
                            type='submit'>
                            Save
                        </Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={handleClose} color='error'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};