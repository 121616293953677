import styled from 'styled-components';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

const Userdropdown = (props: {
    text?: string | number | boolean
}) => {
    return (
        <AccountPopupwrapper>
            <Grayicon>
                <BsFillQuestionCircleFill />
            </Grayicon>
            <Popupsection className='account-popup'>
                <Popupitem>
                    {props.text}
                </Popupitem>
            </Popupsection>
        </AccountPopupwrapper>
    );
}

export default Userdropdown;

const AccountPopupwrapper = styled.div`
position: relative;
cursor: pointer;
&:hover .account-popup {
    display: block !important;
  }
`

const Popupsection = styled.div`
background: rgb(255, 255, 255);
    cursor: default;
    position: absolute;
    z-index: 9999;
    margin-top:5px;
    padding: 10px 10px;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 25%) 1px 1px 5px 1px;
    width: 250px;
    text-align: left;
    display:none;
`

const Popupitem = styled.p`
font-size:12px;
margin-bottom:0px;
color: #9d9d9d;
text-decoration: none;
line-height: 13px;
font-weight: 600;
&:hover {
  }
`
const Grayicon = styled.span`
color:#BBB !important;
`