import styled from "styled-components"
import Slider from '@mui/material/Slider';
import { styled as muiStyled } from '@mui/system';


export const Label = styled.div`
                        font-size: 28px;
                        margin-bottom: 12px;
                        color: ${(props: { textColor?: string }) => props.textColor};
                        text-align: center;
                        `;

export const SliderLabels = styled.div`
                        font-size: 18px;
                        color: ${(props: { textColor?: string }) => props.textColor};
                        text-align: center;
                        `;

export const PrettoSlider = muiStyled(Slider)(({ }) => ({
    color: '#52af77',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    }
}));