import Select, { ActionMeta, SingleValue } from 'react-select';

const CustomSelect = <T extends {}>(props: {
  id?: string,
  name?: string,
  data?: T[],
  onChange?: (selected: SingleValue<T>) => void,
  defaultValue?: T,
  placeholder?: React.ReactNode
}) => {
  const handleChange = (newValue: SingleValue<T>, actionMeta: ActionMeta<T>) => {
    console.log('changed: ', newValue)
    if (props.onChange) {
      props.onChange(newValue)
    }
  }

  return (
    <Select
      placeholder={props.placeholder}
      className="basic-single"
      id={props.id}
      classNamePrefix="select"
      value={props.defaultValue ? props.defaultValue : (props.data || [])[0]}
      isSearchable={true}
      onChange={handleChange}
      name={props.name}
      options={props.data}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
  );
}

export default CustomSelect;