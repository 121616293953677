import { createTheme, ThemeProvider } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FieldValidators, RequiredRule } from "Helpers";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";

// const theme = createTheme({
//   components: {
//     MuiSelect: {
//       variants: [
//         {
//           props: { variant: 'outlined' },
//           style: {
//             textTransform: 'none',
//             border: `1px solid #c1c1c1`,
//           },
//         },
//       ],
//     },
//   },
// });

export const FormSelect = (props: {
  data: FormStepResponses;
  formMain: UseFormReturn<any, any>;
  currentSlice: StoreSlices;
  isMulti: boolean;
}) => {
  const {
    data,
    isMulti,
    formMain: {
      register,
      formState: { errors },
    },
    currentSlice,
  } = props;
  const settings = useSelector(
    (state: AppStore) => state[currentSlice].settings
  );
  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <div>
      <FormControl className="w-100 mb-2">
        <InputLabel>{data?.fieldLabel}</InputLabel>
        <Select
          label={data?.fieldLabel}
          sx={{ backgroundColor: "white" }}
          variant="filled"
          multiple={isMulti}
          defaultValue={isMulti ? [] : ""}
          error={!!errors[String(data.id)]}
          {...register(String(data.id), {
            shouldUnregister: true,
            validate: (v) =>
              FieldValidators(v, String(data.id),"",false,"",data.validationType || "Text", settings),
            required: RequiredRule(
              data.isRequired,
              settings.formField_Required_ErrorText
            ),
          })}
        >
          <MenuItem value={""}>Please Select</MenuItem>
          {data?.createdOptions?.map((x, i) => (
            <MenuItem value={x.value} key={x.id}>
              {x.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {errors[String(data.id)] && (
        <span className="showError text-danger small">
          {errors[String(data.id)]?.message?.toString()}
        </span>
      )}
    </div>
  );
};
