import { useState } from 'react';
import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import InputPrefix from "Components/PrefixInput";
import CustomSelect from "Components/CustomSelect";

import { useDispatch, useSelector } from 'react-redux';
import FormFloatingLabel from "Components/FloatingInput";
import { setDesignOptions } from "Store/slices/EditFormSlice";

const OfferHeadline = () => {
    const dispatch = useDispatch();
    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);

    const [offerHeadlineFontWeight, setOfferHeadlineFontWeight] = useState([
        { value: 400, label: '400' },
        { value: 500, label: '500' },
        { value: 600, label: '600' },
        { value: 700, label: '700' },
        { value: 900, label: '900' },
    ]);

    return (
        <>
            <TopBottomPadding>
                <FormFloatingLabel defaultValue={designOptions.offerHeadline.offerHeadlineText} onInput={(value) => { dispatch(setDesignOptions({ key: "offerHeadline", data: { offerHeadlineText: value } })) }} label={"Offer Headline Text"} />
            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Offer Headline Color

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker defaultValue={designOptions.offerHeadline.offerHeadlineFontColor} getColor={(color) => {
                            dispatch(setDesignOptions({ key: "offerHeadline", data: { offerHeadlineFontColor: color } }))
                        }} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Offer Headline Font Size
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.offerHeadline.offerHeadlineFontSize} onInput={(value) => dispatch(setDesignOptions({ key: "offerHeadline", data: { offerHeadlineFontSize: value } }))} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Offer Headline Font Size Mobile
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Offer Headline Font Weight

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  w-50`}>
                        <CustomSelect defaultValue={designOptions.offerHeadline.offerHeadlineFontWeight} onChange={(selected) => dispatch(setDesignOptions({ key: "offerHeadline", data: { offerHeadlineFontWeight: selected } }))} data={offerHeadlineFontWeight} name={"offerHeadlineFontWeight"} />



                    </div>

                </div>

            </TopBottomPadding>



        </>
    );
}

export default OfferHeadline;