import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import FormFloatingLabel from "Components/FloatingInput";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import InputPrefix from "Components/PrefixInput";
import CustomSelect from "Components/CustomSelect";
import ToolTip from 'Components/Tooltip';
import { setDesignOptions } from "Store/slices/EditFormSlice";

const CallUsBar = () => {
    const dispatch = useDispatch();
    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);

    const [CallUsbarTextWeight, setCallUsbarTextWeight] = useState([
        { value: 400, label: '400' },
        { value: 500, label: '500' },
        { value: 600, label: '600' },
        { value: 700, label: '700' },
        { value: 900, label: '900' },
    ]);

    return (
        <>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Top "Call Us" Bar
                        <div className='ms-2'>
                            <ToolTip text={`Enabling this feature will add an area at the top of the form to include your phone number. All phone leads are tracked and counted as conversions.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch defaultchecked={designOptions.callUsBar.topCallUsBar} id={"TopCallUsBar"} checked={(checked) => dispatch(setDesignOptions({ key: "callUsBar", data: { topCallUsBar: checked } }))} />

                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Bottom "Call Us" Bar
                        <div className='ms-2'>
                            <ToolTip text={`Enabling this feature will add an area at the bottom of the form to include your phone number. All phone leads are tracked and counted as conversions.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch defaultchecked={designOptions.callUsBar.bottomCallUsBar} id={"BottomCallUsBar"} checked={(checked) => dispatch(setDesignOptions({ key: "callUsBar", data: { bottomCallUsBar: checked } }))} />

                    </div>

                </div>

            </TopBottomPadding>
            {designOptions.callUsBar.topCallUsBar === true || designOptions.callUsBar.bottomCallUsBar === true ? <div>
                <TopBottomPadding>

                    <FormFloatingLabel onInput={(value) => { dispatch(setDesignOptions({ key: "callUsBar", data: { callUsBarText: value } })) }} label={`"Call Us" bar text`} defaultValue={designOptions.callUsBar.callUsBarText} />


                </TopBottomPadding>
                <TopBottomPadding>
                    <FormFloatingLabel onInput={(value) => { dispatch(setDesignOptions({ key: "callUsBar", data: { callUsBarPhone: value } })) }} label={`"Call Us" bar phone`} defaultValue={designOptions.callUsBar.callUsBarPhone} />



                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            "Call Us" Bar Color
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                dispatch(setDesignOptions({ key: "callUsBar", data: { callUsBarBgColor: color } }))
                            }} defaultValue={designOptions.callUsBar.callUsBarBgColor} />

                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            "Call Us" Bar Text Color
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                dispatch(setDesignOptions({ key: "callUsBar", data: { callUsBarFontColor: color } }))
                            }} defaultValue={designOptions.callUsBar.callUsBarFontColor} />

                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            "Call Us" Bar Text Size

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <InputPrefix defaultValue={designOptions.callUsBar.callUsBarFontSize} onInput={(value) => dispatch(setDesignOptions({ key: "callUsBar", data: { callUsBarFontSize: value } }))} prefix={"pixels"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            "Call Us" Bar Text Weight


                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  w-50`}>
                            <CustomSelect defaultValue={designOptions.callUsBar.callUsBarFontWeight} onChange={(selected) => dispatch(setDesignOptions({ key: "callUsBar", data: { callUsBarFontWeight: selected } }))} data={CallUsbarTextWeight} name={"CallUsbarTextWeight"} />


                        </div>

                    </div>

                </TopBottomPadding>
            </div>
                : null}
        </>
    );
}

export default CallUsBar;