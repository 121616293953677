import { CallAxios } from 'Helpers';
import { useForm } from 'react-hook-form';
import { FaCheck } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
function LogActivity(props: {
    data: ContactDetail
    LogTypes: LogTypes[],
    onNewAdded: () => void
}) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { leadId } = useParams();

    const { LogTypes, data, onNewAdded } = props;

    const { register, handleSubmit, reset } = useForm<LeadLogs>({
        defaultValues: {
            formLeadId: leadId,

        }
    });
    return (
        <form onSubmit={handleSubmit(v => {
            CallAxios('/api/CRM/SaveLogs|POST', v, undefined, undefined, navigate, dispatch)
                .then(s => {
                    toast(s?.data.message, { type: s?.data.success ? 'success' : 'error' });
                    reset();
                    onNewAdded();
                });
        })}>
            <div className="log_activity_tab">
                <div className="header pl-0 d-flex align-items-center mb-3">
                    <label className="prefix fw-bold me-3 text-muted">Log a</label>
                    <select className="form-select w-25 logtypeinput" {...register('logType', { required: true })}>
                        <option value={''}>Please Select</option>
                        {
                            LogTypes.map((v, i) =>
                                <option value={v} key={'ddl_' + i}>{v}</option>
                            )
                        }
                    </select>
                </div>
                <div className="description">
                    <textarea {...register('description', { required: true })} rows={7} className="form-control logdesinput" placeholder="Description"></textarea>
                    <span className="text-danger ValDescription d-none">Description is required.</span>
                </div>
                <div className="footer text-end mt-3">
                    <button className="btn btn-success" type='submit'>
                        <FaCheck className='me-2' />
                        Save Log
                    </button>
                </div>

            </div>
        </form>
    );
}

export default LogActivity;