
import ToolTip from 'Components/Tooltip';

import { CollapsedAreaLabels } from "Components/Common";
import FormFloatingLabel from "Components/FloatingInput";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import InputPrefix from "Components/PrefixInput";
import CustomSelect from "Components/CustomSelect";

const OnsiteMessages = (props: { dispatch: any; settings: FormEditorOptionsSettings; setSettings: any; }) => {
    const { dispatch, settings, setSettings } = props;
    const option = [
        { value: "bottom_right", label: "Bottom Right" },
        { value: "bottom_middle", label: "Bottom Middle" },
        { value: "bottom_left", label: "Bottom Left" },
    ];
    return (
        <>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Onsite Message
                    <div className='ms-2'>
                        <ToolTip text={`The "onsite message" can appear in the bottom right or bottom left of your page. When clicked, it opens your RocketForm. The onsite message increases engagement, which typically results in more leads.`} />
                    </div>
                </CollapsedAreaLabels>
                <div className={`itemsPerform `}>
                    <MaterialDesignSwitch defaultchecked={settings.onSiteMessage} id={"onSiteMessage"} checked={(checked) => dispatch(setSettings({ onSiteMessage: checked }))} />
                </div>
            </div>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Onsite Message On Mobile
                    <div className='ms-2'>
                        <ToolTip text={`This setting disables the onsite message on mobile so it appears on desktop devices only.`} />
                    </div>
                </CollapsedAreaLabels>
                <div className={`itemsPerform `}>
                    <MaterialDesignSwitch defaultchecked={settings.onSiteMessageOnMobile} id={"onSiteMessageMobile"} checked={(checked) => dispatch(setSettings({ onSiteMessageOnMobile: checked }))} />
                </div>
            </div>
            {settings.onSiteMessage === true || settings.onSiteMessageOnMobile === true ? <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Onsite Message Position
                </CollapsedAreaLabels>
                <div className={`itemsPerform w-50`}>
                    <CustomSelect data={option} onChange={(value) => dispatch(setSettings({ onSiteMessagePosition: value }))} defaultValue={settings.onSiteMessagePosition} name={"onSiteMessage"} />
                </div>
            </div>
                : null}
            {settings.onSiteMessage === true ? <div className='onSiteMessage'>
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Display Message
                        <div className='ms-2'>
                            <ToolTip text={`This is the "chat bubble text" that appears within the onsite message. Disable this feature if you'd like to hide the message text. We recommend leaving this enabled.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch defaultchecked={settings.displayMessage} id={"displayMessage"} checked={(checked) => dispatch(setSettings({ displayMessage: checked }))} />
                    </div>
                </div>
                <div className='d-flex mb-3 justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Appearance Delay
                        <div className='ms-2'>
                            <ToolTip text={`This will delay the onsite message from loading. The appearance delay works in seconds. For example, changing the value to "5" means that it will take five seconds for the onsite message to load.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <InputPrefix defaultValue={settings.appearanceDelay} onInput={(value) => dispatch(setSettings({ appearanceDelay: value }))} prefix={'second(s)'} />
                    </div>
                </div>
            </div>
                : null}
            {settings.onSiteMessageOnMobile === true ?
                <div className='onSiteMessageMobile'>
                    <div className='d-flex mb-3 justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Display Message On Mobile
                            <div className='ms-2'>
                                <ToolTip text={`Disabling this setting will hide the onsite message on mobile only.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform `}>
                            <MaterialDesignSwitch defaultchecked={settings.displayMessageOnMobile} id={"displayMessageMobile"} checked={(checked) => dispatch(setSettings({ displayMessageOnMobile: checked }))} />
                        </div>
                    </div>
                    <div className='d-flex mb-3 justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Appearance Delay On Mobile
                            <div className='ms-2'>
                                <ToolTip text={`This will delay the onsite message from loading on mobile only. The appearance delay works in seconds. For example, changing the value to "5" means that it will take five seconds for the onsite message to load.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform `}>
                            <InputPrefix defaultValue={settings.appearanceDelayOnMobile} onInput={(value) => dispatch(setSettings({ appearanceDelayOnMobile: value }))} prefix={'second(s)'} />
                        </div>
                    </div>
                </div>
                : null}
            {(settings.displayMessageOnMobile === true || settings.displayMessage === true) && (settings.onSiteMessage === true || settings.onSiteMessageOnMobile === true) ? <div className=''>
                <FormFloatingLabel label={"Display Message"} defaultValue={settings.displayMessageText} onInput={(value) => dispatch(setSettings({ displayMessageText: value }))} />
            </div>
                : null}
        </>
    )
}

export default OnsiteMessages;