import 'bootstrap/dist/css/bootstrap.min.css';
import { Forbidden401 } from 'Components/Forbidden401';
import { Forbidden403 } from 'Components/Forbidden403';
import PageNotFound from 'Components/PageNotFound';
import { SignInManager } from 'Helpers/Authentication/SignInManager';
import { ESTemplatesList, ESTemplatesMain } from 'Pages/ESTemplates';
import { EventCalendarList } from 'Pages/EventCalendar';
import { EventCategoriesList } from 'Pages/EventCategories';
import ContactDetails from 'Pages/Leads/ContactDetails';
import { Nylas } from 'Pages/Nylas';
import { useEffect } from 'react';
import {
  Navigate,
  Outlet, Route, Routes, useLocation
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Loader } from '../Layout/Loader';
import { LoginLayout } from '../Layout/LoginLayout';
import { Mainlayout } from '../Layout/Mainlayout';
import { ChangePassword, Login, Register } from '../Pages/Accounts';
import { PreviewForm } from '../Pages/Forms/Builder/PreviewForm';
import EditFormLayout from '../Pages/Forms/Components/EditFormLayout';
import Forms from '../Pages/Forms/List';
import Templates from '../Pages/Forms/List/Templates';
import Leads from '../Pages/Leads';
import AddUser from '../Pages/SubUsers/AddUser';
import Subusers from '../Pages/SubUsers/Index';
import './App.css';


export const App = () => {
  const titlePrefix = ' | Lead Page Forms Builder';

  const titleMap = [
    { path: '/', title: 'Forms List' },
    { path: '/login', title: 'Login' },
    { path: '/subusers', title: 'Users' },
    { path: '/adduser', title: 'Add or Modify User' },
    { path: '/editform', title: 'Main form viewer' },
    { path: '/contactDetails', title: 'Lead Detail' },
  ];

  const curLoc = useLocation();
  useEffect(() => {
    const curTitle = titleMap.find(item => item.path.startsWith(curLoc.pathname.toLowerCase()))
    if (curTitle && curTitle.title) {
      document.title = curTitle.title + titlePrefix
    } else {
      document.title = 'Default' + titlePrefix
    }
  }, [curLoc, titleMap])

  return (
    <div className="App">
      <Loader />
      <ToastContainer limit={2} newestOnTop theme='light' />
      <Routes>
        <Route path="/" element={<Mainlayout />}>
          <Route index element={<ProtectedRoute role={['Admin', 'Client','Agent']}><Forms /></ProtectedRoute>} />
          <Route path='/leads' element={<ProtectedRoute><Outlet /></ProtectedRoute>}>
            <Route index element={<Leads />} />
            <Route path="/leads/:formId" element={<Leads />} />
          </Route>
          <Route path="/contactDetails/:leadId/:formId" element={<ProtectedRoute><ContactDetails /></ProtectedRoute>} />
          <Route path="/subusers" element={<ProtectedRoute role={['Admin', 'Client']}><Subusers /></ProtectedRoute>} />
          <Route path="/templates" element={<ProtectedRoute role={['Admin']}><Templates /></ProtectedRoute>} />
          <Route path="/estemplates" element={<ProtectedRoute><ESTemplatesList /></ProtectedRoute>} />
          <Route path="/categories" element={<ProtectedRoute><EventCategoriesList /></ProtectedRoute>} />
          <Route path="/calendar" element={<ProtectedRoute><EventCalendarList /></ProtectedRoute>} />
          <Route path="/adduser" element={<ProtectedRoute role={['Admin', 'Client']}><AddUser justMyProfile={false} /></ProtectedRoute>} />
          <Route path="/adduser/:userId" element={<ProtectedRoute role={['Admin', 'Client']}><AddUser justMyProfile={false} /></ProtectedRoute>} />
          <Route path="/account" element={<ProtectedRoute><AddUser justMyProfile /></ProtectedRoute>} />
          <Route path='/changepassword' element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
        </Route>

        <Route path='/:formId/viewer' element={<PreviewForm />} />

        <Route path="/editForm/:formId" element={<Outlet />}>
          <Route index element={<ProtectedRoute role={['Admin', 'Client']}><EditFormLayout /></ProtectedRoute>} />
          <Route path=":id" element={<ProtectedRoute role={['Admin', 'Client']}><EditFormLayout /></ProtectedRoute>} />
          <Route path="preview" element={<ProtectedRoute role={['Admin', 'Client']}><PreviewForm /></ProtectedRoute>} />
        </Route>

        <Route path='/login' element={<LoginLayout><Login /></LoginLayout>} />
        <Route path='/register' element={<Register />} />

        <Route path="/forbidden401" element={<Forbidden401 />} />
        <Route path="/forbidden403" element={<Forbidden403 />} />
        <Route path="/nylas" element={<Nylas />} />
        <Route path="*" element={<PageNotFound />} />

      </Routes>


    </div>
  );
}

export const ProtectedRoute = (props: {
  role?: Role[],
  children: JSX.Element
}) => {
  const { children } = props;
  const location = useLocation();

  const isAuthenticated = new SignInManager().IsAuthenticated;
  const role = new SignInManager().UserRole;

  if (!isAuthenticated || (props.role && role && !props.role.includes(role))) {
    const rUri = location.pathname;
    return <Navigate to={'/login?redirect_url=' + rUri} replace />;
  }

  return children;
};
