import axios from "axios";
import { NavigateFunction } from "react-router-dom";
import { config } from "../Config";

const tokenKey = 'token';
const generatedKey = 'generated';
const expiryKey = 'expiry';

export class SignInManager {
    options = {
        client_id: config.CLIENT_ID,
        client_secret: config.CLIENT_SECRET,
        grant_type: config.GRANT_TYPE,
        response_type: config.RESPONSE_TYPE,
        username: '',
        password: ''
    };
    async Login(userName?: string, password?: string) {
        this.options.username = userName || '';
        this.options.password = password || '';
        const response = await axios.post<TokenResponse>(config.TOKEN_URL, new URLSearchParams(this.options));
        console.log(response);
        return response;
    }
    get IsAuthenticated(): boolean {
        const t = this.GetToken();
        const e = Number(localStorage.getItem(expiryKey));
        const g = localStorage.getItem(generatedKey);
        if (t && e && g) {
            const expiry = e;
            const generated = new Date(g);
            generated.setSeconds(generated.getSeconds() + expiry);
            return generated > new Date();
        } else {
            return false;
        }
    }
    get UserRole() {
        return this.ParseJwt.role;
    }
    SetToken(token: TokenResponse) {
        
        localStorage.setItem(tokenKey, JSON.stringify(token));
        localStorage.setItem(expiryKey, token.expires_in.toString());
        localStorage.setItem(generatedKey, new Date().toISOString());
    }
    GetToken() {
        return JSON.parse(localStorage.getItem(tokenKey) || '{}') as TokenResponse | null
    }

    public get AccessToken(): string {
        const token = this.GetToken();
        if (token) {
            return token.access_token;
        }
        else {
            return '.';
        }
    }

    RemoveToken() {
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(expiryKey);
        localStorage.removeItem(generatedKey);
        localStorage.clear();
    }
    Logout(navigate?: NavigateFunction) {
        this.RemoveToken();
        if (navigate)
            navigate('/login')
    }
    get ParseJwt(): JwtType {
        const token = this.AccessToken || '.';
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload || '{}') || {};
    }

}