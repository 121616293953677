import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import InputPrefix from "Components/PrefixInput";

const OnsiteMessages = () => {


    return (
        <>

            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Onsite Message Max Width
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"%"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Onsite Message Max Width On Mobile

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"%"} />


                    </div>

                </div>

            </TopBottomPadding>

        </>
    );
}

export default OnsiteMessages;