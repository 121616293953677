import { CollapseTrigger, CommonArea, TopBottomPadding } from 'Components/Common';
import { useState } from 'react';
import styled from 'styled-components';

import LoadingButton from '@mui/lab/LoadingButton';
import { BiChevronRight } from "react-icons/bi";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const EmbedLeadForm = () => {
    const [showDiv, setShowDiv] = useState(false);
    const [copying, setCopying] = useState(false);

    const { formId } = useParams();
    const code = `<div id="lead-page-root-element" data-ref="${formId}" style="height: 100%;width: 100%;"></div>`;
    return (
        <>
            <CollapseTrigger cursor='default' onClick={() => setShowDiv(!showDiv)}>
                <TopBottomPadding>
                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapseTriggerHeading className='d-flex '>
                            Embed LeadForm
                        </CollapseTriggerHeading>

                        <CommonArea className={`${showDiv === true ? 'rotateit' : ''}`}>
                            <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                        </CommonArea>

                    </div>
                </TopBottomPadding>
            </CollapseTrigger>
            {showDiv === true ? <CollapsedArea id={"example-collapse-text_ss"}>
                <TopBottomPadding>
                    <p className='small'>{`Before you embed your LeadForm on your page, please make sure that you have set your Form Mode to be either 'embedded' or 'all'. This can be done by going to Settings > Form Mode Go To Settings`}</p>
                    <p className='small'>{`Below here is the html element you should put in order to embed a single LeadForm in your page's body:`}</p>
                    <ScriptDiv className='mb-2'>{code}</ScriptDiv>
                    <ScriptDiv className='mb-3'>
                        <LoadingButton
                            variant='contained'
                            color='primary'
                            loading={copying}
                            onClick={async e => {
                                setCopying(true);
                                await navigator.clipboard.writeText(code);
                                setCopying(false);
                                toast('Copied');
                            }}>Copy Code</LoadingButton>
                    </ScriptDiv>
                </TopBottomPadding>
            </CollapsedArea>
                : null}
        </>
    );
}

export default EmbedLeadForm;
const CollapseTriggerHeading = styled.div`
font-size: 17px;

`
const CollapsedArea = styled.div`
padding: 0px 35px;
    position: relative;
    font-size: 16px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
    background: rgb(247, 247, 247);
`

const ScriptDiv = styled.div`
padding: 8px 15px;
    font-size: 14px;
    overflow: hidden;
    white-space: pre-wrap;
    position: relative;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-weight:600
`