import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

import FormControl from '@mui/material/FormControl';
import { UseFormReturn } from 'react-hook-form';
import styled from "styled-components";

export const MultipleCheckboxButtons = styled.div`
display: block;
width: 100% !important;
margin-top: 15px;
letter-spacing: 0.4px;
font-weight: ${props => props.fw?.value};
font-size:${props => !!props.fs ? props.fs + 'px' : '20px'} ;
line-height: 1.3em;
color: ${props => !!props.fc ? props.fc : '#000'};
cursor: pointer;
border-width: 3px;
border-style: solid;
border-color: ${props => !!props.bgColor ? props.bgColor : '#000'};
-webkit-border-image: initial;
border-image: initial;
outline: none;
margin-left: auto;
margin-right: auto;
border-radius: ${props => !!props.br ? props.br + 'px' : "3px"} ;
background-color:  ${props => !!props.bgColor ? props.bgColor : '#6ebf65'} ;
box-shadow: none !important;
height: auto !important;
max-width: ${props => !!props.width ? props.width + "%" : '100%'} !important;
text-align:center;
&.active {
  background-color:${props => !!props.activebgColor ? props.activebgColor : '#6ebf65'} ;
  color:${props => !!props.activetextColor ? props.activetextColor : '#fff'} ;
  border-color: ${props => !!props.activebgColor ? props.activebgColor : '#000'}
}
&:hover{
    background-color : ${(props: {
  bgColor: any,
  fs: any,
  bgColorOnHover: any,
  bc: any,
  fc: any,
  fcOnHover: any,
  fw: any,
  br: any,
  activebgColor:any,
  activetextColor:any,
  width: any
}) => !!props.activebgColor ? props.activebgColor : '#6ebf65'};
color:${props => !!props.activetextColor ? props.activetextColor : '#fff'};
border-color: ${props => !!props.activebgColor ? props.activebgColor : '#000'}
}
& .checkboxCustom{
  padding-left:0 !important;
}
& .checkboxCustom input{
    opacity:0;
    
}
& .checkboxCustom label {
padding: 14px 10px !important;
width:100%;
cursor:pointer;
}
`

export const MultiFormCheckboxes = (props: {

  data: FormStepResponses,
  formMain: UseFormReturn<any, any>,
  currentSlice: StoreSlices,
  isMulti: boolean,
  checkboxStyle:any,
  multiSelectstyle:any
}) => {
  const [checkboxState, setCheckboxState] = useState<multiSelectCheckboxes[]>();

  const { data, formMain: { formState: { errors } }, checkboxStyle , multiSelectstyle } = props;

  useEffect(() => {
    if (checkboxState)
      props.formMain.setValue(String(data.id), checkboxState);
  }, [checkboxState]);

  useEffect(() => {
    if (data.createdOptions) {
      let newData = data.createdOptions?.map(x => {
        return { ...x, checked: false }
      });
      setCheckboxState(newData)
    }

  }, [data.createdOptions]);

  const selectedCheckBox = (checked: boolean, i: number) => {
    if (checkboxState) {
      let prevState = [...checkboxState];
      prevState[i].checked = checked;
      setCheckboxState(prevState)
    }

  }

  return (
    <div>
      <p className='mb-0 mt-3'>{data?.fieldLabel}</p>
      <FormControl className='w-100 mb-2'>
        <div className='row'>
          {
            checkboxState?.map((x, i) =>
              <MultipleCheckboxes i={i} checked={x.checked} multiSelectstyle={multiSelectstyle} checkboxStyle={checkboxStyle} value={x.value} key={x.id} label={x.label} selectedCheckBox={selectedCheckBox} />
            )
          }
        </div>
      </FormControl>
      {errors[String(data.id)] && <span className='showError text-danger small'>{errors[String(data.id)]?.message?.toString()}</span>}
    </div>
  );
}


function MultipleCheckboxes(props: {
  value: string,
  key: string,
  label: string,
  selectedCheckBox: any,
  checked: boolean,
  i: number,
  checkboxStyle:any,
  multiSelectstyle:any
}) {
  return (
    <div className='col-6'>
      <MultipleCheckboxButtons
        width={props.checkboxStyle?.submitButtonWidth}
        br={props.checkboxStyle?.submitButtonBorderRadius}
        fw={props.checkboxStyle?.submitButtonFontWeight}
        fcOnHover={props.checkboxStyle?.submitButtonTextColorOnHover}
        fc={props.multiSelectstyle?.multiselectTextColor}
        bc={props.checkboxStyle?.submitButtonBorderColor}
        bgColorOnHover={props.checkboxStyle?.submitButtonColorOnHover}
        fs={props.checkboxStyle?.submitButtonFontSize}
        bgColor={props.multiSelectstyle?.multiselectColor}
        activebgColor={props.multiSelectstyle?.multiselectSelectedColor}
        activetextColor={props.multiSelectstyle?.multiselectSeletedTextColor}
        className={props.checked ? 'active' : ''}>

        <Form.Check
          className='checkboxCustom text-start'
          type={'checkbox'}
          id={`default-${props.value}`}
          label={`${props.label}`}
          value={props.value}
          onChange={(e) => props.selectedCheckBox(e.target.checked, props.i)}
          checked={props.checked}
        />

      </MultipleCheckboxButtons>
    </div>
  );
}


