import { CollapsedAreaLabels } from "Components/Common";
import FormFloatingLabel from 'Components/FloatingInput';
import { FieldArray, FormikProvider } from "formik";
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const SliderTypeOptions = (props: {
    formik: FormikType<FormStepResponses>
}) => {
    const formik = props.formik;

    return (
        <div className='px-3'>
            <FormikProvider value={formik} >
                <CollapsedAreaLabels className='d-flex align-items-center pb-2 mb-2 border-bottom'>
                    Slider Options
                </CollapsedAreaLabels>
                <div>
                    <FieldArray
                        name="slider.sliderOptions"
                        render={arrayHelpers => (
                            formik.values.slider && formik.values.slider.sliderOptions.map((item, index) =>
                                <div className="ListItem">
                                    <div className=' nostyle mx-0'>

                                        <div className='d-flex align-items-center mb-2'>
                                            <div>
                                                <FormFloatingLabel onInput={(value) => formik.setFieldValue(`slider.sliderOptions.${index}.label`, value)} label={"Option"} defaultValue={formik.values.slider?.sliderOptions[index].label} />

                                            </div>
                                            <div className='ms-2'>
                                                <FormFloatingLabel className="ms-1" onInput={(value) => formik.setFieldValue(`slider.sliderOptions.${index}.value`, value)} label={"Webhook Field Value"} defaultValue={formik.values.slider?.sliderOptions[index].value} />
                                            </div>
                                            <div className='buttonsGroupsHere align-items-center ms-1'>
                                                {formik.values.slider?.sliderOptions?.length === index + 1 && <AddBtn className='AddBtn mb-1' onClick={() => arrayHelpers.push({ id: uuidv4(), label: '', value: "" })}>+</AddBtn>}
                                                <RemoveBtn className='removeBtn' onClick={() => arrayHelpers.remove(index)}>-</RemoveBtn>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            )
                        )}
                    />
                </div>
            </FormikProvider >
        </div>
    );
}

export default SliderTypeOptions;

const AddBtn = styled.span`
border: none;
    border-radius: 3px;
    text-align: center;
    color: rgb(255, 255, 255);
    background: rgb(65, 112, 251);
    cursor: pointer;
    height: 27px;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    width: 27px;
    display: block;

`
const RemoveBtn = styled.span`
border: none;
border-radius: 3px;
text-align: center;
color: rgb(255, 255, 255);
background: rgb(251, 104, 93);
cursor: pointer;
height: 27px;
font-weight: 500;
font-size: 15px;
line-height: 27px;
width: 27px;
display: block;
`