import { AuthHeading, AuthSubHeading, AuthWrapper, Navbar } from "Components"

export const LoginLayout = (props: { children: JSX.Element }) => {
    return (
        <>
            <Navbar onlyLogo />
            <AuthWrapper>
                <AuthHeading>LeadForms Dashboard</AuthHeading>
                <AuthSubHeading noText={true}></AuthSubHeading>
                <div className='d-flex justify-content-center align-items-center'>
                    <div className='CenterWidth'>

                        {props.children}
                    </div>
                </div>
            </AuthWrapper>
        </>
    )
}
