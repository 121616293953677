import { useState, useEffect } from 'react';
import { UseFormReturn } from "react-hook-form";
import { Label, PrettoSlider } from "./Shared";

export const OptionListSlider = (props: {
    data: FormStepResponses,
    currentSlice: StoreSlices,
    formMain: UseFormReturn<any, any>
}) => {
    const { data, formMain } = props;
    const { register } = formMain;
    const { name, ref, disabled } = register(String(data.id));
    const options = (data.slider?.sliderOptions || []).map((x, i) => ({ label: x.label, value: i }))
    const [value, setValue] = useState(options.find((mark) => mark.value === 0)?.label || '');

    const valueLabelFormat = (value: number) => {
        return options.find((mark) => mark.value === value)?.label || '';
    }

    useEffect(() => {
        if (value)
            formMain.setValue(String(data.id), value);
    }, [value]);

    return (
        <div className='mb-3'>
            {
                data.slider?.Show_field_label &&
                <Label textColor={data.slider?.labelColor}>{data.fieldLabel}</Label>
            }
            <Label style={{ fontSize: 18 }} textColor={data.slider?.sliderValueColor}>
                {value}
            </Label>
            <PrettoSlider
                sx={{
                    color: data.slider?.sliderbarColor,
                    '& .MuiSlider-valueLabel': {
                        backgroundColor: data.slider?.sliderValueColor
                    }
                }}
                onChange={(_: Event, newValue: number | number[]) => {
                    if (typeof newValue == 'number') {
                        setValue(options.find((mark) => mark.value === newValue)?.label || '');
                    }
                }}
                name={name}
                disabled={disabled}
                size="medium"
                track={false}
                valueLabelFormat={valueLabelFormat}
                getAriaValueText={(v, i) => `${v}`}
                step={null}
                max={(options.length == 0 ? 1 : options.length) - 1}
                valueLabelDisplay="auto"
                marks={options}
            />
        </div>
    )
}