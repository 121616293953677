import { configureStore } from '@reduxjs/toolkit';
import EditFormSlice from "./slices/EditFormSlice";
import PreviewPage from "./slices/PreviewPageSlice";
import  EmailFormSlice  from './slices/EmailFormSlice';

export const AppStore = configureStore<AppStore>({
  reducer: {
    editor: EditFormSlice,
    preview: PreviewPage,
    emailForm:EmailFormSlice
  },
})