import { defaultDesignOptions } from 'commonJSON';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { saveStepfromJSON, setActivenavbar, setActiveStep, setAddEditResponseWindow, setDesignOptionsFromJSON, setLeadDelivery, setSettings } from 'Store';
import { CallAxios, ExecuteParsers } from 'Helpers';
import CenterFormBlock from "../Components/CenterBlock/CenterFormCreationBlock";
import DesignSidebar from './DesignSidebar';
import LeadDeliveryTab from './Sidebars/LeadDeliveryTab/LeadDelivery';
import PublishTab from "./Publish";
import Settings from "./Settings";
import StepsView from "./Sidebars/StepList/ListOfSteps";
import Steps from "./Sidebars/StepTab/StepSidebar";

const EditForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const activeTab = useSelector((state: AppStore) => state.editor.activeNavBar);
    const steps = useSelector((state: AppStore) => state.editor.steps);

    const { formId, id } = useParams();

    const formMain = useForm<any>({
        mode: 'all',
        shouldFocusError: true,
        shouldUnregister: true
    });

    const SetActiveStepOnCenter = (s?: FormSteps[]) => {
        const step = (s || steps).find(x => x.id === id);
        if (step) {
            dispatch(setActiveStep({ ...step, id: step.id }));
            dispatch(setActivenavbar('Steps'));
            dispatch(setAddEditResponseWindow(false));
        }
    };

    useEffect(() => {
        const LoadData = async () => {
            const res = await CallAxios('/api/Forms/GetEditForm|GET',
                undefined,
                {
                    id: formId
                },
                undefined,
                navigate,
                dispatch);
            if (res?.data?.data?.designData && Object.keys(res?.data?.data?.designData || {}).length > 0) {
                dispatch(setDesignOptionsFromJSON(res?.data?.data?.designData));
                dispatch(setLeadDelivery(res.data.data.leadDeliveryData));
                dispatch(setSettings({ ...res?.data?.data.settingsData }));

            } else {
                dispatch(setDesignOptionsFromJSON(defaultDesignOptions))
            }
            const formSteps = [...(res?.data?.data.formSteps || [])];
            formSteps.forEach(x => {
                x.responses = (x.responses || []).map((r) => ExecuteParsers(r));
            });
            dispatch(saveStepfromJSON(formSteps));
            const entryStep = res?.data.data.formSteps?.find(x => x.isEntryStep);
            if (entryStep && !id) {
                navigate('/editForm/' + formId + '/' + entryStep.id);
            } else if (id) {
                SetActiveStepOnCenter(formSteps);
            }
        };
        if (formId)
            LoadData();
    }, [formId]);

    useEffect(() => {
        if (id)
            SetActiveStepOnCenter();
    }, [id]);
    const changeStep = (step: FormStepResponses) => {
        console.log(step)
    }

    const actions: FormPreviewActions = {
        changeStep
    }
    return (
        <Row className='mx-0' style={{ background: "#d9d9d9" }}>
            <Col md={4} className="ps-0">
                <div className=''>
                    {activeTab === "Design" && <DesignSidebar />}
                    {activeTab === "Steps" && <Steps />}
                    {activeTab === "Settings" && <Settings />}
                    {activeTab === "LeadDelivery" && <LeadDeliveryTab />}
                    {activeTab === "Publish" && <PublishTab />}
                </div>
            </Col>
            <Col md={5}>
                <CenterFormBlock formMain={formMain} action={actions} slice={'editor'}
                    onBackClick={e => { }}
                    onSubmit={e => {

                    }} />
            </Col>
            <Col md={3}>
                <StepsView />
            </Col>
        </Row>
    );
}

export default EditForm;