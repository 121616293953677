import FormFloatingLabel from "Components/FloatingInput"
import { TopBottomPadding, CollapsedAreaLabels } from 'Components/Common';
import ToolTip from 'Components/Tooltip';
import MaterialDesignSwitch from "Components/MaterialSwitch"
import TargetStep from 'Components/TargetStepSelect';
import { TitleText, SubtitleText, AddResponseTExt, ResponseVariableName } from "Components/Common";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bind } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"

// const [responseOpenChange$, setResponseOpen] = createSignal<boolean>();
// const [useResponseOpen, responseOpen$] = bind<boolean>(responseOpenChange$, false);

const AddEditResponses = (props: { formik: FormikType<FormStepResponses>; }) => {
    const { formik } = props;
    const { id } = useParams();
    const stepsList = useSelector((state: AppStore) => state.editor.steps);
    const name = stepsList?.find(x => x.id === String(id))?.name?.replaceAll(" ", "_");

    return (
        <div className='px-3 ' id="editor_main">
            <AddResponseTExt>
                <TitleText>
                    Add Response
                </TitleText>
                <SubtitleText>
                    (Save the response to reflect the changes in the real-time demo)

                </SubtitleText>
            </AddResponseTExt>
            <div className="mb-3 text-start">
                <FormFloatingLabel
                    autoFocus
                    id='autofocusable'
                    onInput={(value) => formik.setFieldValue('responseOrFieldName', value)}
                    defaultValue={formik.values.responseOrFieldName}
                    label={"Response Text*"} />
                {formik.errors.responseOrFieldName && formik.touched.responseOrFieldName && (
                    <small className="text-danger">{formik.errors.responseOrFieldName}</small>
                )}
            </div>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Is Phone Button?
                        <div className='ms-2'>
                            <ToolTip text={`This setting turns the response into a "click to call" button. When clicked, the form will call you. All phone calls will be tracked as leads in the system.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch defaultchecked={formik.values.isPhoneButton} id={"isPhoneBtn"} checked={(checked) => { formik.setFieldValue('isPhoneButton', checked) }} />

                    </div>

                </div>
                {formik.values.isPhoneButton && <div>
                    <FormFloatingLabel label={"Phone Number"} onInput={(value) => { formik.setFieldValue('phoneNumber', value) }} defaultValue={formik.values.phoneButton} />
                </div>}
            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Is Link Button?
                        <div className='ms-2'>
                            <ToolTip text={`This setting turns the response into a button that links out to any page outside of the form. When clicked, the form will direct the prospect to another page on your site.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch defaultchecked={formik.values.isLinkButton} id={"isLinkBtn"} checked={(checked) => { formik.setFieldValue('isLinkButton', checked) }} />

                    </div>

                </div>
                {formik.values.isLinkButton && <div >
                    <FormFloatingLabel label={"URL"} onInput={(value) => { formik.setFieldValue('linkButton', value) }} defaultValue={formik.values.linkButton} />
                </div>}
            </TopBottomPadding>

            {!formik.values.isPhoneButton && !formik.values.isLinkButton && 
            <div className="mb-3 text-start">
                <FormFloatingLabel label={"Response Variable Name"} onInput={(value) => { formik.setFieldValue('variableName', value) }} defaultValue={formik.values.variableName} />
                {formik.errors.variableName && formik.touched.variableName && (
                    <small className="text-danger d-block">{formik.errors.variableName}</small>
                )}
                <ResponseVariableName className='ResponseVariableName'>Variable Name: {`{${name}_${!!formik.values.variableName ? formik.values.variableName : ""}}`}</ResponseVariableName>

                <div className="my-3">
                    <TargetStep formik={formik} />
                </div>
            </div>
            }

            
        </div>
    );
}
export default AddEditResponses;