import { config } from 'Helpers/Config';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BackButtonMain } from '..';
import { FormFields } from "../../EditForm/Sidebars/StepTab/FormFields";
import { ResponseChatStyle, ResponsesList } from "../../EditForm/Sidebars/StepTab/ResponsesList";
import TextBlockEndStep from "../../EditForm/Sidebars/StepTab/TextBlockEndStep";
import {
    AddBgImage,
    BackBtn,
    CallUsBar,
    ChartStyleForminner,
    ChatStyleFormWrapper,
    ClicktoChoose,
    CurrentProgress,
    FormFieldsWrapper,
    LeadformHeaderWrapper,
    LeadformsAvatar,
    LeadformsformbodyWrapper,
    LeadformsStepInstruction,
    LeadformsStepTitle,
    LeadFormStepSubHeading,
    Leadforms_title,
    OfferHeadline,
    ProgressBar,
    ProgressbarLabel
} from "./CenterFormStyling";

const CenterFormBlock = (props: {
    action?: FormPreviewActions
    slice: StoreSlices
    onSubmit: SubmitHandler<any>
    onBackClick: (e: HTMLButtonElement) => void,
    formMain: UseFormReturn<any, any>
}) => {
    const { action, slice, onSubmit, onBackClick, formMain } = props;
    const currentSlice = slice;

    const designOptions = useSelector((state: AppStore) => state[currentSlice].designOptions);
    const stepOptions = useSelector((state: AppStore) => state[currentSlice].stepOptions);
    const settings = useSelector((state: AppStore) => state[currentSlice].settings);

    return (
        <>
            <div className='addBodyPadding mainBody sticky-sidebar' id="leadforms-embd-form">
                {designOptions?.templateStyle?.templateStyle?.value === "EnhancedForm" ?
                    <EnhancedForm formMain={formMain} onBackClick={onBackClick} onSubmit={onSubmit} settingTab={settings} stepOptions={stepOptions} settings={designOptions} currentSlice={currentSlice} action={action} />
                    :
                    <ChatStyleForm formMain={formMain} onBackClick={onBackClick} onSubmit={onSubmit} settingTab={settings} stepOptions={stepOptions} settings={designOptions} currentSlice={currentSlice} action={action} />
                }
            </div>

        </>
    );
}

export default CenterFormBlock;

const ChatStyleForm = (props: {
    settings?: FormsDesignOptions;
    stepOptions?: FormSteps;
    action?: FormPreviewActions;
    settingTab?: FormEditorOptionsSettings;
    currentSlice: StoreSlices
    onSubmit: SubmitHandler<any>,
    onBackClick: (e: HTMLButtonElement) => void,
    formMain: UseFormReturn<any, any>
}) => {

    const { formId } = useParams();

    const { action, currentSlice, settingTab, onSubmit, stepOptions, onBackClick, formMain } = props;

    const designOptions = props.settings;

    const backButtonClickable = useSelector((state: AppStore) => state[currentSlice].backButtonClickable);

    return (
        <>
            {designOptions?.offerHeadline?.offerHeadlineText !== "" &&
                <OfferHeadline
                    fc={designOptions?.offerHeadline?.offerHeadlineFontColor}
                    fs={designOptions?.offerHeadline?.offerHeadlineFontSize}
                    fw={designOptions?.offerHeadline?.offerHeadlineFontWeight}
                >{designOptions?.offerHeadline?.offerHeadlineText}</OfferHeadline>}
            <ChatStyleFormWrapper>
                {
                    designOptions?.callUsBar?.topCallUsBar &&
                    <CallUsBar fw={designOptions?.callUsBar?.callUsBarFontWeight}
                        fs={designOptions?.callUsBar?.callUsBarFontSize}
                        fc={designOptions?.callUsBar?.callUsBarFontColor}
                        bgColor={designOptions?.callUsBar?.callUsBarBgColor}
                        position="top">{designOptions?.callUsBar?.callUsBarText}</CallUsBar>
                }
                {
                    !designOptions?.header.hideHeader &&
                    <LeadformHeaderWrapper className='leadforms-form-header-wrapper'>
                        {
                            !settingTab?.hideAvatar_Embedded &&
                            <LeadformsAvatar shadow={false}>
                                <img src={settingTab?.AvatarImage !== '' ? config.ICON_IMAGES_URL + formId + '/' + settingTab?.AvatarImage : ""} className='w-100' />
                            </LeadformsAvatar>
                        }
                    </LeadformHeaderWrapper>
                }
                <ChartStyleForminner>

                    {designOptions?.backButton?.backBtn && designOptions?.backButton?.backBtnVerticalPosition?.value === "top" &&
                        <div style={{ textAlign: (designOptions?.backButton?.backBtnHorizontalPosition?.value || 'center') as any }}>
                            <BackBtn
                                bgColor={designOptions?.backButton?.backBtnBgColor}
                                bgColorOnHover={designOptions?.backButton?.backBtnHoverBgColor}
                                borderRadius={designOptions?.backButton?.backBtnBorderRadius}
                                py={designOptions?.backButton?.backBtnVerticalPadding}
                                widthOrPadding={designOptions?.backButton?.backBtnWidthAuto}
                                backBtnWidth={designOptions?.backButton?.backBtnWidth}
                                backBtnHorizontalPadding={designOptions?.backButton?.backBtnHorizontalPadding}
                                vPosition={designOptions?.backButton?.backBtnVerticalPosition}
                                fc={designOptions?.backButton?.backBtnFontColor}
                                fs={designOptions?.backButton?.backBtnFontSize}
                                fw={designOptions?.backButton?.backBtnFontWeight}
                                onClick={e => {
                                    if (backButtonClickable) {
                                        if (e.target instanceof HTMLButtonElement)
                                            onBackClick(e.target);
                                    }
                                }}>
                                {"← "} {designOptions?.backButton?.backBtnText}
                            </BackBtn>
                        </div>
                    }

                    {stepOptions?.stepType === 'Question' && <LeadformsStepTitle type="ChatStyle" fw={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontWeight} fs={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontSize} fc={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontColor}>
                        {stepOptions.questionText !== "" ? stepOptions.questionText : `TextEdit "Question text" in the editor`}
                    </LeadformsStepTitle>
                    }

                    {stepOptions?.stepType === 'Form' && <LeadformsStepTitle type="ChatStyle" fw={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontWeight} fs={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontSize} fc={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontColor}>
                        {stepOptions.formTitle !== "" ? stepOptions.formTitle : `Edit "Form title" in the editor`}
                    </LeadformsStepTitle>}


                    {stepOptions?.stepType === 'Question' && <ClicktoChoose>Click to Choose</ClicktoChoose>}

                    {stepOptions?.stepType === "Question" ?
                        <ResponseChatStyle action={action} currentSlice={currentSlice} />
                        :
                        stepOptions?.stepType === "Form" ?
                            <FormFieldsWrapper className="form-field">
                                <FormFields formMain={formMain} onSubmit={onSubmit} currentSlice={currentSlice} multiSelectstyle={designOptions?.responseStyling} backButtonStyle={designOptions?.backButton} submitButtonStyle={designOptions?.submitButton} />
                            </FormFieldsWrapper>
                            :
                            stepOptions?.stepType === "TextBlock" &&
                            <TextBlockEndStep currentSlice={currentSlice} thankyouStyle={designOptions?.thankyouStep} />}


                    {
                        designOptions?.progressBar?.progressBar &&
                        <ProgressBar
                            borderColor={designOptions?.progressBar?.progressBarColor}
                            bgColor={designOptions?.progressBar?.progressBarBgColor}>
                            <CurrentProgress bgColor={designOptions?.progressBar?.progressBarColor} />
                            <ProgressbarLabel fc={designOptions?.progressBar?.progressBarFontColor}>50%</ProgressbarLabel>
                        </ProgressBar>
                    }


                    {designOptions?.backButton?.backBtn && designOptions?.backButton?.backBtnVerticalPosition?.value === "bottom" &&
                        <div style={{ textAlign: (designOptions?.backButton?.backBtnHorizontalPosition?.value || 'center') as any }}>
                            <BackBtn
                                bgColor={designOptions?.backButton?.backBtnBgColor}
                                bgColorOnHover={designOptions?.backButton?.backBtnHoverBgColor}
                                borderRadius={designOptions?.backButton?.backBtnBorderRadius}
                                py={designOptions?.backButton?.backBtnVerticalPadding}
                                widthOrPadding={designOptions?.backButton?.backBtnWidthAuto}
                                backBtnWidth={designOptions?.backButton?.backBtnWidth}
                                backBtnHorizontalPadding={designOptions?.backButton?.backBtnHorizontalPadding}
                                fc={designOptions?.backButton?.backBtnFontColor}
                                fs={designOptions?.backButton?.backBtnFontSize}
                                fw={designOptions?.backButton?.backBtnFontWeight}
                                onClick={e => {
                                    if (backButtonClickable) {
                                        if (e.target instanceof HTMLButtonElement)
                                            onBackClick(e.target);
                                    }
                                }}>
                                {"← "} {designOptions?.backButton?.backBtnText}
                            </BackBtn>
                        </div>
                    }
                </ChartStyleForminner>

                {designOptions?.callUsBar?.bottomCallUsBar && <CallUsBar fw={designOptions?.callUsBar?.callUsBarFontWeight} fs={designOptions?.callUsBar?.callUsBarFontSize} fc={designOptions?.callUsBar?.callUsBarFontColor} bgColor={designOptions?.callUsBar?.callUsBarBgColor} position="bottom">{designOptions?.callUsBar?.callUsBarText}</CallUsBar>}

            </ChatStyleFormWrapper>
        </>
    )
}

const EnhancedForm = (props: {
    settings?: FormsDesignOptions
    stepOptions?: FormSteps
    action?: FormPreviewActions
    settingTab?: FormEditorOptionsSettings
    currentSlice: StoreSlices
    onSubmit: SubmitHandler<any>,
    onBackClick: (e: HTMLButtonElement) => void,
    formMain: UseFormReturn<any, any>
}) => {
    const { formId } = useParams();

    const { action, stepOptions, currentSlice, settingTab, onSubmit, onBackClick, formMain } = props;
    const designOptions = props.settings;
    const backButtonClickable = useSelector((state: AppStore) => state[currentSlice].backButtonClickable);

    return (
        <div className='leadforms-form-wrapper'>
            {
                !designOptions?.header.hideHeader &&
                <AddBgImage bgimg={designOptions?.header.headlineBgimg !== '' ? config.ICON_IMAGES_URL + formId + '/' + designOptions?.header.headlineBgimg : ""} >
                    <LeadformHeaderWrapper bgColor={designOptions?.header.headlineBgColor} className='leadforms-form-header-wrapper'>
                        {
                            !settingTab?.hideAvatar_Embedded &&
                            <LeadformsAvatar shadow>
                                {designOptions?.header.hideHeader && <img src={settingTab?.AvatarImage !== '' ? config.ICON_IMAGES_URL + formId + '/' + settingTab?.AvatarImage : ""} className='w-100' />}
                            </LeadformsAvatar>
                        }
                        <Leadforms_title fw={designOptions?.header.headlineFontWeight}
                            fs={designOptions?.header.headlineFontSize}
                            fc={designOptions?.header.headlineFontColor}>{designOptions?.header.headlineText}</Leadforms_title>
                    </LeadformHeaderWrapper>
                </AddBgImage>
            }

            <div className='leadforms-form-body-wrapper'>
                <AddBgImage bgimg={designOptions?.templateStyle.templateBgimg !== '' ? config.ICON_IMAGES_URL + formId + '/' + designOptions?.templateStyle.templateBgimg : ""} >
                    <LeadformsformbodyWrapper bgColor={designOptions?.templateStyle.templateBgColor} className="formBody-responsive">
                        {
                            designOptions?.callUsBar?.topCallUsBar &&
                            <CallUsBar fw={designOptions?.callUsBar?.callUsBarFontWeight}
                                fs={designOptions?.callUsBar?.callUsBarFontSize}
                                fc={designOptions?.callUsBar?.callUsBarFontColor}
                                bgColor={designOptions?.callUsBar?.callUsBarBgColor}
                                position="top">{designOptions?.callUsBar?.callUsBarText}</CallUsBar>
                        }

                        {
                            designOptions?.backButton?.backBtnVerticalPosition?.value === "top" &&
                            <BackButtonMain entryStep={stepOptions?.isEntryStep || false} designOptions={designOptions} backButtonClickable={backButtonClickable} onBackClick={onBackClick} />
                        }

                        {
                            stepOptions?.stepType === 'Question' &&
                            <LeadformsStepTitle type="EnhancedForm"
                                fw={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontWeight}
                                fs={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontSize}
                                fc={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontColor}>
                                {stepOptions.questionText ? stepOptions.questionText : `TextEdit "Question text" in the editor`}
                            </LeadformsStepTitle>
                        }

                        {
                            stepOptions?.stepType === 'Form' &&
                            <LeadformsStepTitle type="EnhancedForm"
                                fw={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontWeight}
                                fs={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontSize}
                                fc={designOptions?.stepHeadingAndSubHeading?.stepHeadingFontColor}>
                                {stepOptions?.formTitle ? stepOptions.formTitle : `Edit "Form title" in the editor`}
                            </LeadformsStepTitle>
                        }

                        {
                            stepOptions?.stepType === 'Question' &&
                            stepOptions.subTitle &&

                            <LeadFormStepSubHeading fw={designOptions?.stepHeadingAndSubHeading?.stepSubHeadingFontWeight}
                                fs={designOptions?.stepHeadingAndSubHeading?.stepSubHeadingFontSize}
                                fc={designOptions?.stepHeadingAndSubHeading?.stepSubHeadingFontColor}>
                                {stepOptions?.subTitle}
                            </LeadFormStepSubHeading>

                        }
                        {
                            stepOptions?.stepType === 'Form' && stepOptions?.subTitle &&

                            <LeadFormStepSubHeading fw={designOptions?.stepHeadingAndSubHeading?.stepSubHeadingFontWeight}
                                fs={designOptions?.stepHeadingAndSubHeading?.stepSubHeadingFontSize}
                                fc={designOptions?.stepHeadingAndSubHeading?.stepSubHeadingFontColor}>
                                {stepOptions?.subTitle}
                            </LeadFormStepSubHeading>

                        }
                        <LeadformsStepInstruction></LeadformsStepInstruction>
                        {
                            stepOptions?.stepType === "Question" ?
                                <ResponsesList action={action} currentSlice={props.currentSlice} /> :
                                stepOptions?.stepType === "Form" ?
                                    <FormFieldsWrapper className="form-field fix-slider-font">
                                        <FormFields formMain={formMain} onSubmit={onSubmit} currentSlice={currentSlice} multiSelectstyle={designOptions?.responseStyling} backButtonStyle={designOptions?.backButton} submitButtonStyle={designOptions?.submitButton} />
                                    </FormFieldsWrapper> :
                                    stepOptions?.stepType === "TextBlock" &&
                                    <TextBlockEndStep currentSlice={currentSlice} thankyouStyle={designOptions?.thankyouStep} />
                        }

                        {
                            designOptions?.progressBar?.progressBar &&
                            <ProgressBar
                                borderColor={designOptions?.progressBar?.progressBarColor}
                                bgColor={designOptions?.progressBar?.progressBarBgColor}>
                                <CurrentProgress style={{ width: (stepOptions?.progress || 0) + '%' }} bgColor={designOptions?.progressBar?.progressBarColor} />
                                <ProgressbarLabel fc={designOptions?.progressBar?.progressBarFontColor}>{(stepOptions?.progress || 0).toFixed(0)}%</ProgressbarLabel>
                            </ProgressBar>
                        }
                        {
                            designOptions?.backButton?.backBtnVerticalPosition?.value === "bottom" &&
                            <BackButtonMain entryStep={stepOptions?.isEntryStep || false} designOptions={designOptions} backButtonClickable={backButtonClickable} onBackClick={onBackClick} />
                        }

                        {
                            designOptions?.callUsBar?.bottomCallUsBar &&
                            <CallUsBar fw={designOptions?.callUsBar?.callUsBarFontWeight}
                                fs={designOptions?.callUsBar?.callUsBarFontSize}
                                fc={designOptions?.callUsBar?.callUsBarFontColor}
                                bgColor={designOptions?.callUsBar?.callUsBarBgColor}
                                position="bottom">{designOptions?.callUsBar?.callUsBarText}</CallUsBar>
                        }

                    </LeadformsformbodyWrapper>
                </AddBgImage>
            </div>
        </div>
    )
}
