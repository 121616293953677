import { FaChevronLeft } from "react-icons/fa";
import { BackBtn } from "./CenterBlock/CenterFormStyling";

export const BackButtonMain = (props: {
    designOptions?: FormsDesignOptions,
    backButtonClickable?: boolean,
    entryStep: boolean,
    onBackClick: (event: HTMLButtonElement) => void
}) => {
    const { designOptions, backButtonClickable, onBackClick, entryStep } = props;
    return (
        <>
            {
                designOptions?.backButton?.backBtn && <>
                    {
                        !entryStep &&
                        <div style={{ textAlign: (designOptions?.backButton?.backBtnHorizontalPosition?.value || 'center') as any }}>
                            <BackBtn
                                bgColor={designOptions?.backButton?.backBtnBgColor}
                                bgColorOnHover={designOptions?.backButton?.backBtnHoverBgColor}
                                borderRadius={designOptions?.backButton?.backBtnBorderRadius}
                                py={designOptions?.backButton?.backBtnVerticalPadding}
                                widthOrPadding={designOptions?.backButton?.backBtnWidthAuto}
                                backBtnWidth={designOptions?.backButton?.backBtnWidth}
                                backBtnHorizontalPadding={designOptions?.backButton?.backBtnHorizontalPadding}
                                fc={designOptions?.backButton?.backBtnFontColor}
                                fs={designOptions?.backButton?.backBtnFontSize}
                                fw={designOptions?.backButton?.backBtnFontWeight}
                                onClick={e => {
                                    if (backButtonClickable) {
                                        if (e.target instanceof HTMLButtonElement)
                                            onBackClick(e.target);
                                    }
                                }}>
                                <FaChevronLeft scale={.7} /> {designOptions?.backButton?.backBtnText}
                            </BackBtn>
                        </div>
                    }
                </>
            }
        </>
    )
}