import 'rc-slider/assets/index.css';
import { UseFormReturn } from 'react-hook-form';
import { NumericSlider, OptionListSlider } from './Sliders';

export const FormSlider = (props: {
    data: FormStepResponses,
    currentSlice: StoreSlices,
    formMain: UseFormReturn<any, any>
}) => {
    const { data, currentSlice, formMain } = props;
    return (
        <div>
            {
                data.slider?.sliderValueType == 'OptionsList' ?
                    <OptionListSlider data={data} currentSlice={currentSlice} formMain={formMain} />
                    : <NumericSlider data={data} currentSlice={currentSlice} formMain={formMain} />
            }
        </div>
    );
}