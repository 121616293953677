import { defaultDesignOptions, defaultSettingsOptions } from 'commonJSON';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Cardheader } from 'Components/Common';
import { config } from 'Helpers/Config';
import { AxiosLite, CallAxios } from 'Helpers';
import { SignInManager } from 'Helpers/Authentication/SignInManager';

const Templates = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [categories, setCategories] = useState<KeyPairValue<string>[]>([]);
    const [templates, setTemplates] = useState<TemplatesList[]>([]);
    const [category, setCategory] = useState('');
    const [clients, setClients] = useState<UserModel[]>([]);
    const [client, setClient] = useState(new SignInManager().ParseJwt.sub || '');
    React.useEffect(() => {
        CallAxios('/api/General/GetCategories|GET',
            undefined,
            undefined,
            undefined,
            navigate,
            dispatch)
            .then(res => {
                setCategories(res?.data.data || []);
            });
    }, []);
    React.useEffect(() => {
        CallAxios('/api/Template/Templates|GET', undefined, { categoryId: category }, undefined, navigate, dispatch)
            .then(res => {
                setTemplates(res?.data?.list || []);
            });
    }, [category]);
    React.useEffect(() => {
        AxiosLite('/api/Clients/ListUsers|GET', undefined, navigate, dispatch).then(s => {
            setClients(s?.data.list || []);
        })
    }, []);
    return (
        <>
            <div className='text-end mb-2'>
                <Button onClick={(e) => navigate(-1)} variant="primary">Back</Button>
            </div>
            <Card className='shadow'>
                <Cardheader>
                    <Card.Title>
                        Choose your template
                    </Card.Title>
                    {
                        new SignInManager().UserRole == 'Admin' &&
                        <div className='w-25'>
                            <FloatingLabel label="Client" className="lead-floating-label">
                                <Form.Select value={client} onChange={e => setClient(e.target.value)}>
                                    <option value={new SignInManager().ParseJwt.sub}>None</option>
                                    {
                                        clients.map(s =>
                                            <option key={'a_' + s.id} value={s.id}>{s.name}</option>
                                        )
                                    }
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                    }
                    <div className='w-25'>
                        <FloatingLabel controlId="floatingSelect" label="Showing" className="lead-floating-label">
                            <Form.Select value={category} onChange={e => setCategory(e.target.value)}>
                                <option value={''}>All Categories</option>
                                {
                                    categories.map(s =>
                                        <option key={'a_' + s.key} value={s.key}>{s.value}</option>
                                    )
                                }
                            </Form.Select>
                        </FloatingLabel>
                    </div>
                </Cardheader>
                <Card.Body>
                    <Row>
                        <FormListItem v={EmptyTemplate} isEmpty={true} client={client} />
                        {
                            templates.map((v, i) => <FormListItem client={client} v={v} isEmpty={false} key={'template_' + i} />)
                        }
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
}
const EmptyTemplate: TemplatesList = {
    id: '',
    name: 'Create From Scratch',
    description: 'Build your own form from scratch',
    categoryId: '',
    formId: '',
    image: '',
    isActive: true,
};
const FormListItem = (props: { v: TemplatesList, isEmpty: boolean, client: string }) => {
    const { v, client } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <Col md={4} className='mb-3'>
            <div className="Tempcard">
                <div className="imgBox">
                    <img src={config.API_URL + v.image}
                        onError={(e: any) => e.target.src = "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"} />
                </div>
                <div className="content">
                    <div className="contentBox">
                        <h3 className='text-dark'>{v.name}</h3>
                    </div>
                    <ul className="social">
                        <li className='me-2'>
                            <Button onClick={() => {
                                if (!props.isEmpty) {
                                    AxiosLite('/api/Forms/CopyForms|POST',
                                        {
                                            formId: v.formId,
                                            clientId: client
                                        },
                                        navigate,
                                        dispatch)
                                        .then(res => {
                                            navigate(`/editForm/${res?.data.data}`);
                                        });
                                }
                                else
                                    CallAxios('/api/Forms/CreateBlankForm|POST',
                                        {
                                            designData: JSON.stringify(defaultDesignOptions),
                                            settingsData: JSON.stringify(defaultSettingsOptions),
                                            formSteps: [],
                                            leadDeliveryData: {} as any,
                                            publishData: JSON.stringify({}),
                                            clientId: client
                                        },
                                        undefined,
                                        undefined,
                                        navigate,
                                        dispatch)
                                        .then(res => {
                                            navigate(`/editForm/${res?.data.data}`);
                                        });
                            }}
                                size='sm'
                                variant={props.isEmpty ? 'secondary' : 'primary'}>Choose</Button>
                        </li>
                        {
                            !props.isEmpty &&
                            <li>
                                <Link
                                    to={`/editForm/${v.formId}/preview?mode=view`}
                                    target={'_blank'}
                                    //    onClick={e => navigate(`/editForm/${v.formId}/preview?mode=view`)}
                                    className='btn btn-sm btn-secondary font-14'>Preview</Link>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </Col>);
}
export default Templates;