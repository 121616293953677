import FormFloatingLabel from "Components/FloatingInput";



const Recaptcha = (props: { dispatch: any; settings: any; setSettings: any; }) => {
    const { dispatch, settings, setSettings } = props;

    return (
        <>
            <div className='mb-3'>
                <FormFloatingLabel label={"reCAPTCHA Site Key"} defaultValue={settings.reCAPTCHASiteKey} onInput={(value) => dispatch(setSettings({ reCAPTCHASiteKey: value }))} />

            </div>
        </>
    )
}

export default Recaptcha