import { useState } from 'react';
import { BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setStepOptions, setStepOptionsInner } from 'Store';
import styled from 'styled-components';
import { CollapsedAreaLabels, CommonArea, ResponsesHeader, ResponsesHeaderSpan, TopBottomPadding } from 'Components/Common';
import { QuestionBlockCollapse, QuestionBlockSwithcs } from 'commonJSON';
import FormFloatingLabel from "Components/FloatingInput";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import InputPrefix from "Components/PrefixInput";
import CustomSelect from "Components/CustomSelect";
import ToolTip from 'Components/Tooltip';
import { ResponseMain } from './Responses';
import { Checkbox, FormControlLabel } from '@mui/material';

const QuestionBlock = (props: { formik: FormikType<FormSteps> }) => {
    const { formik } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const stepOptions = useSelector((state: AppStore) => state.editor.stepOptions);

    const [SwitchbtnList, setSwitchbtnList] = useState(QuestionBlockSwithcs);
    const [ThirdPartyIntegrationList, setThirdPartyIntegrationList] = useState(QuestionBlockCollapse);


    const showhiddenDivsOnSwitchChange = (index: number, checked: boolean) => {

        const newData = [...SwitchbtnList];
        newData[index].isSwitch = checked;
        setSwitchbtnList(newData);
    }

    const collapseDiv = (index: number) => {

        const newData = [...ThirdPartyIntegrationList];
        newData[index].isCollapsed = !newData[index].isCollapsed;
        setThirdPartyIntegrationList(newData);
    }

    return (
        <>
            <div className='p-3'>
                <div className="mb-3">
                    <FormFloatingLabel label={"Step name*"} onInput={(value) => { formik.setFieldValue("name", value); dispatch(setStepOptions({ name: value })) }} defaultValue={stepOptions?.name} />
                    {formik.errors.name && formik.touched.name && (
                        <small className="text-danger">{formik.errors.name}</small>
                    )}
                </div>
                <div className="mb-3">
                    <FormFloatingLabel label={"Question Text*"} onInput={(value) => { formik.setFieldValue("questionText", value); dispatch(setStepOptionsInner({ questionText: value })) }} defaultValue={stepOptions?.questionText} />
                    {formik.errors.questionText && formik.touched.questionText && (
                        <small className="text-danger">{formik.errors.questionText}</small>
                    )}
                </div>
                <div className="mb-3">
                    <FormFloatingLabel label={"Step Subtitle"} onInput={(value) => { dispatch(setStepOptionsInner({ subTitle: value })) }} defaultValue={stepOptions?.subTitle} />
                </div>
                <div className="mb-3">
                    <FormControlLabel control={<Checkbox onChange={e => dispatch(setStepOptionsInner({ isFinalStep: e.target.checked }))} checked={stepOptions?.isFinalStep || false} />} label="Mark as final step?" />
                    <FormFloatingLabel label={"Progress"}
                        type='text'
                        regex={/^(0|[1-9][0-9]?|100)$/}
                        onInput={(value) => dispatch(setStepOptionsInner({ progress: Number(value) }))}
                        defaultValue={stepOptions?.progress || 0} />
                </div>
            </div>
            <ResponsesHeader id='responseWrapper'>
                <ResponsesHeaderSpan>RESPONSES</ResponsesHeaderSpan>
            </ResponsesHeader>
            <div className='py-3 text-center'>
                {!id && <p className='text-muted mb-0'>- Save the step to start adding responses -</p>}
                {id && <ResponseMain />}
            </div>
            <ResponsesHeader>
                <ResponsesHeaderSpan>ADVANCED OPTIONS</ResponsesHeaderSpan>
            </ResponsesHeader>
            <div className='p-3'>
                <TopBottomPadding>
                    {SwitchbtnList.map((childitem, i) =>
                        <div key={childitem.label + i}>
                            <div className='d-flex mb-3 justify-content-between align-items-center'>
                                <CollapsedAreaLabels className='d-flex align-items-center'>
                                    {childitem.label}
                                    <div className='ms-2'>
                                        {childitem.tooltip.hasTooltip === true ?
                                            <ToolTip text={childitem.tooltip.title} />
                                            : null}
                                    </div>
                                </CollapsedAreaLabels>
                                <div className={`itemsPerform`}>
                                    <MaterialDesignSwitch defaultchecked={childitem.isSwitch} id={"switch_" + i} checked={(checked) => showhiddenDivsOnSwitchChange(i, checked)} />
                                </div>

                            </div>
                            {childitem.isSwitch === true ?
                                <>{childitem.label === "TCPA Notice" ? <TipText>Tip: Wrap the clickable text into two "*" characters, e.g. **Your clickable text** - for example: by clicking the next button you agree to our **TCPA consent**</TipText> : null}
                                    {childitem.children.map(x =>
                                        <div className='mb-3'>  <FormFloatingLabel label={x.label} defaultValue={x.defaultValue} /></div>)}
                                </> : null}

                        </div>
                    )}

                    {ThirdPartyIntegrationList.map((childitem, i) =>
                        <div key={childitem.label + i}>
                            <div onClick={() => collapseDiv(i)} className='d-flex mb-3 justify-content-between align-items-center'>
                                <CollapsedAreaLabels className='d-flex align-items-center'>
                                    {childitem.label}
                                    <div className='ms-2'>
                                        {childitem.tooltip.hasTooltip === true ?
                                            <ToolTip text={childitem.tooltip.title} />
                                            : null}
                                    </div>
                                </CollapsedAreaLabels>
                                <div className={`itemsPerform`}>
                                    <CommonArea className={`${childitem.isCollapsed === true ? "rotateit" : ""}`}>
                                        <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                                    </CommonArea>
                                </div>

                            </div>
                            {childitem.isCollapsed === true ?
                                <>
                                    {childitem.label === "Step Layout Override" ? <StepLayoutOverride /> : childitem.label === "Step Teaser Text" ? <StepTeaserText /> :
                                        childitem.children.map(x =>

                                            <div className='mb-3'>  <FormFloatingLabel label={x.label} defaultValue={x.defaultValue} /></div>)}
                                </> : null}

                        </div>
                    )}

                </TopBottomPadding>

            </div>
        </>
    );
}

export default QuestionBlock;



export const StepLayoutOverride = () => {
    const responsiveColumnWidth = [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 },
        { value: 11, label: 11 },
        { value: 12, label: 12 },

    ];
    const responsiveinputList = [
        {
            label: "Response Font Size",
            prefix: "pixels"
        },
        {
            label: "Response Font Size On Mobile",
            prefix: "pixels"
        },
        {
            label: "Responses Box Max Width",
            prefix: "%"
        },
        {
            label: "Responses Box Max Width On Mobile",
            prefix: "%"
        },
        {
            label: "Response Vertical Padding",
            prefix: "pixels"
        },
        {
            label: "Response Vertical Padding (Inside)",
            prefix: "pixels"
        },
    ];



    return (
        <>
            <div className='d-flex justify-content-between mb-3 align-items-center'>
                <CollapsedAreaLabels className='d-flex fw-light align-items-center'>
                    Responses Column Width
                    <div className='ms-2'>
                        <ToolTip text={"This setting adjusts the layout of the responses in your step. By default, all responses are in one column. With step layout override, you can adjust the column grid width so your responses are in 2 columns, 3 columns, etc..."} />
                    </div>
                </CollapsedAreaLabels>
                <div className={`itemsPerform w-50`}>
                    <CustomSelect data={responsiveColumnWidth} defaultValue={responsiveColumnWidth[0]} name="ColumnWidth" onChange={(selected) => { }} />
                </div>
            </div>
            {responsiveinputList.map(x =>
                <div className='d-flex justify-content-between mb-3 align-items-center'>
                    <CollapsedAreaLabels className='d-flex fw-light align-items-center'>
                        {x.label}
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform noPaddingonChildren`}>
                        <InputPrefix prefix={x.prefix} />
                    </div>
                </div>
            )}
        </>
    );
}

export const StepTeaserText = () => {
    const [isCustomTeaserCode, setIsCustomTeaserCode] = useState(false);
    return (
        <>
            {isCustomTeaserCode === false ?
                <div className='mb-3'>
                    <FormFloatingLabel label={"Step Teaser Text"} defaultValue={""} />
                </div>
                : null}
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Custom Teaser Code
                    <div className='ms-2'>
                        <ToolTip text={`With this setting you can update the HTML and CSS of the teaser text. This overrides the default teaser text styling.`} />
                    </div>
                </CollapsedAreaLabels>
                <div className={`itemsPerform`}>
                    <MaterialDesignSwitch defaultchecked={isCustomTeaserCode} id={"Custom_Teaser_Code_1"} checked={(checked) => setIsCustomTeaserCode(checked)} />
                </div>

            </div>
            {isCustomTeaserCode === true ? <div className='ShowCustomTeaserWrapper mb-3'>
                <p className='mb-0'>Custom Teaser HTML</p>
                <div className='TeaserHtml mb-3'>
                    <div className='d-flex mb-2'>
                        <span>Conditions example:</span>
                        <div className='ms-2'>
                            <ToolTip text={`{[test_question__response1]?}<div>This text is visible if the 'test_question__response1' variable is present in the user's answers.</div>{/?}{[test_question__response1, test_question__response2]?}<div>This text is visible if both the 'test_question__response1' and 'test_question__response2' variables are present in the user's answers.</div>{/?}{any[test_question__response1, test_question__response2]?}<div>This text is visible if at least one of the 'test_question__response1' or 'test_question__response2' variables is present in the user's answers.</div>{/?}<div>This text is always visible.</div>`} />
                        </div>
                    </div>
                    <textarea className='form-control' rows={3}></textarea>
                </div>
                <div className='TeaserCSS'>
                    <div className='d-flex mb-2'>
                        <p className='mb-0'>Custom Teaser CSS</p>
                    </div>
                    <textarea className='form-control' rows={3}></textarea>
                </div>
            </div> : null}
        </>
    )

}

const TipText = styled.div`
color: rgb(102, 102, 102);
    margin-bottom: 5px;
    font-size: 14px;`;