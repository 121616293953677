import { CallAxios } from 'Helpers';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaPaperPlane } from "react-icons/fa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function SendEmail(props: {
    templates: ESTemplates[],
    email?: string
}) {
    const { templates, email } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, control, watch, reset } = useForm<EmailModel>({
        defaultValues: {
            email: email
        }
    });
    useEffect(() => {
        setValue('email', email || '');
    }, [email]);
    const editorContent = watch("body");
    return (
        <form onSubmit={handleSubmit(v => {
            CallAxios('/api/CRM/SendEmail|POST', v, undefined, undefined, navigate, dispatch)
                .then(s => {
                    toast(s?.data.message, { type: s?.data.success ? 'success' : 'error' });
                    reset();
                });
        })}>
            <div className="send_email_tab">

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="email" className="form-control" placeholder="Email" {...register('email', { required: true })} />

                            <span className="ValSubject text-danger d-none">Required</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Subject" {...register('subject', { required: true })} />

                            <span className="ValSubject text-danger d-none">Required</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <select className="form-control" onChange={e => {
                                setValue('body', e.target.value);
                                if (e.target)
                                    setValue('subject', e.target?.selectedOptions[0].dataset.s || '');
                            }}>
                                <option value="">Please Select Template</option>
                                {
                                    templates.filter(s => s.templateType === 'Email' && s.published).map((v, i) =>
                                        <option key={'tem2_' + i} data-s={v.subject} value={v.content}>{v.name}</option>
                                    )
                                }
                            </select>
                            <span className="ValEmailTemp text-danger d-none">Required</span>
                        </div>
                    </div>
                </div>

                <div className="m-portlet__body py-3">
                    <div className="row mx-0">
                        <div className='col-md-12 px-0'>
                            <ReactQuill theme="snow"
                                value={editorContent} onChange={e => setValue('body', e)} />
                        </div>
                    </div>
                    <div className="text-end mt-3">
                        <button className="btn btn-success BtnSendEmail"> <FaPaperPlane className="me-2" />SEND</button>
                    </div>
                </div>

            </div>
        </form>
    );
}

export default SendEmail;