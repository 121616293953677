import styled from 'styled-components';

export const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


export const Cardheader = styled.div`
display:flex;
align-items:center;
justify-content:space-between !important;
padding:10px 1rem;
border-bottom:1px solid #eee;
`
export const Firstheading = styled.h1`
text-align:center;
`

export const Toastercontainer = styled.div`
background: linear-gradient(90deg, #E80A89 0%, #F15B2A 100%);
    color: rgb(255, 255, 255);
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    box-shadow: rgb(0 0 0 / 40%) 0px 2px 5px;
    width: 100%;
    padding: 20px 0px;
    box-sizing: content-box;
    position: relative;
    margin-left: 0px;
    top: 0px;
    z-index: 20;
`
export const Toasterbox = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
    justify-content: center;
`
export const Toastercontent = styled.div`
display: inline-block;
text-align:center;
font-size: 18px;
`
export const Flexbetween = styled.div`
display:flex !important;
justify-content:space-between !important;
`
export const Flexbtwcenter = styled.div`
display:flex !important;
justify-content:space-between !important;
align-items: center;
`
export const CommonArea = styled.div`
box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
`
export const SideBarHeaderWrapper = styled.div`
background-color : #fff;
padding: 15px 20px;
    border-bottom: 1px solid rgb(238, 238, 238);
    font-size: 19px;
    color: rgb(51, 51, 51);
`
export const CollapseTrigger = styled.div`
background: rgb(255, 255, 255);
    position: relative;
    font-size: 16px;
    ${(props: { cursor: 'default' | 'pointer' }) => props.cursor === "default" ? `cursor: default;` : 'cursor: pointer;'}
   
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
    padding: 0px 43px;
`
export const TopBottomPadding = styled.div`
padding: 10px 0px;

`

export const ResponsesHeader = styled.div`
text-transform: uppercase;
text-align: center;
&:before{
    content: "";
    display: block;
    height: 1px;
    background: rgb(238, 238, 238);
    width: 100%;
    position: relative;
    top: 12px;
    z-index: 1;
}
`
export const ResponsesHeaderSpan = styled.span`
position: relative;
z-index: 2;
display: inline-block;
background: rgb(255, 255, 255);
padding-left: 10px;
padding-right: 10px;
`

export const CollapsedAreaLabels = styled.div`
color: rgb(102, 102, 102);
font-size: 15px;
font-weight: 500;
`


export const BlueBtn = styled.button`
display: block;
    border: 0px;
    text-align: center;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 40%) 0px 2px 3px;
    text-transform: none;
    cursor: pointer;
    font-size: 12px;
    word-spacing: 0.1em;
    transition: all 0.1s ease-in-out 0s;
    background-color: rgb(65, 112, 251);
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 14px;
    outline: none !important;
    &:hover{
        background-color: rgb(242, 242, 242);
        color: rgb(65, 112, 251);
    }
`

export const CancelBtn = styled.button`
display: block;
border: 0px;
text-align: center;
border-radius: 5px;
box-shadow: rgb(0 0 0 / 40%) 0px 2px 3px;
text-transform: none;
cursor: pointer;
font-size: 12px;
word-spacing: 0.1em;
transition: all 0.1s ease-in-out 0s;
background-color: rgb(236, 236, 236);
color: rgb(102, 102, 102);
width: 100%;
padding: 14px;
outline: none !important;
&:hover{
    background-color: rgb(153, 153, 153);
    color: rgb(255, 255, 255);
}
`

export const AddResponseTExt = styled.div`
margin: 15px 20px;
`
export const TitleText = styled.div`
color: rgb(102, 102, 102);
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    `
export const SubtitleText = styled.div`
color: rgb(153, 153, 153);
    font-size: 12px;
    text-align: left;
`
export const ResponseVariableName = styled.span`
font-size: 13px;
margin-top: 2px;
`
export const ResponseLi = styled.li`
list-style-type:none;
border: 2px solid rgb(238, 238, 238);
border-radius: 3px;
box-shadow: rgb(0 0 0 / 10%) 0px 2px 2px;
padding: 15px;
background-color: rgb(250, 250, 250);
margin-bottom: 10px;
`
export const ICONS = styled.span`
font-size: 22px;
position: relative;
top: -2px;
color: rgb(170, 170, 170);
cursor:pointer;
&:hover{
    color: rgb(51, 51, 51);

}
`

export const AuthWrapper = styled.div`
padding: 20px 0px 80px;
background: rgb(255, 255, 255);
`
export const AuthHeading = styled.div`
color: rgb(0, 0, 0);
line-height: 1.3em;
font-weight: 900;
font-size: 42px;
text-align: center;
margin-top: 30px;
font-family: 'Lato', sans-serif;
`
export const AuthSubHeading = styled.div`
font-weight: 500;
font-size: 21px;
line-height: 1.3em;
color: rgb(0, 0, 0);
text-align: center;
margin-top: 15px;
margin-bottom: ${(props: { noText: any }) => props.noText ? '60px' : '20px'};

`