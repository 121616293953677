import FormFloatingLabel from "Components/FloatingInput";

const LeadValue = (props: { dispatch: any; settings: any; setSettings: any; }) => {
    const { dispatch, settings, setSettings } = props;

    return (
        <>
            <div className='mb-3'>
                <FormFloatingLabel label={"Lead Value, $"} defaultValue={settings.LeadValue} onInput={(value) => dispatch(setSettings({ LeadValue: value }))} />

            </div>
        </>
    )
}

export default LeadValue;