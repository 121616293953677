import { createSlice } from '@reduxjs/toolkit';
import { defaultSettingsOptions } from 'commonJSON';
import { ActiveNavBarReducer, ActiveStepReducer, AttachResponseToCurrentOptionsReducer, AttachResponseToStepReducer, DesignOptionsReducer, LoaderReducer, removeStepReducer, saveStepReducer, setAsEntryStepReducer, setStepOptionsInnerReducer, SetStepsReducer, setTargetStepAsCurrentReducer } from '../Reducers';

const initialState: FormsEditorOptions = {
  activeNavBar: 'Design',
  isLoading: false,
  responseOpen: false,
  defaultResponses: [],
  stepOptions: {},
  steps: [],//FormEditorStep
  designOptions: {
    templateStyle: {
      templateStyle: { value: 'EnhancedForm', label: 'Enhanced Form' },
      templateBgColor: "",
      templateBgimg: "",
    },
    header: {
      hideHeader: false,
      headlineText: "",
      headlineBgColor: "",
      headlineBgimg: "",
      headlineFontColor: "#000",
      headlineFontSize: '',
      headlineFontWeight: { value: 700, label: '700' },
    },
    stepHeadingAndSubHeading: {
      stepHeadingFontColor: "",
      stepHeadingFontSize: "",
      stepHeadingFontWeight: { value: 600, label: '600' },
      stepSubHeadingFontColor: "",
      stepSubHeadingFontSize: "",
      stepSubHeadingFontWeight: { value: 700, label: '700' },
    },
    responseStyling: {
      responseGridWidth: { value: 12, label: '12' },
      responseFontSize: '',
      responseTextAlign: { value: 'center', label: 'Center' },
      responseFontWeight: { value: 400, label: '400' },
      responseMarginBottom: '',
      responseVerticalPadding: '',
      responseBgColor: '',
      responseHoverBgColor: '',
      responseBorderColor: '',
      responseFontColor: '',
      responseHoverFontColor: '',
      responseBoxMaxWidth: "",
      responseBoxMaxWidthOnMobile: "",
      multiselectColor:"",
      multiselectTextColor:"",
      multiselectSelectedColor:"",
      multiselectSeletedTextColor:""
    },
    loadingSpinner: {
      loadingSpinnerColor: "#fff",
      loadingSpinnerSzie: "",
      loadingSpinnerThickness: ""
    },
    callUsBar: {
      topCallUsBar: false,
      bottomCallUsBar: false,
      callUsBarText: '',
      callUsBarPhone: '',
      callUsBarBgColor: '',
      callUsBarFontColor: '',
      callUsBarFontSize: '',
      callUsBarFontWeight: { value: 400, label: '400' },
    },
    backButton: {
      backBtn: false,
      backBtnBgColor: "",
      backBtnHoverBgColor: "",
      backBtnBorderRadius: "",
      backBtnVerticalPadding: "",
      backBtnWidthAuto: false,
      backBtnWidth: "100",
      backBtnHorizontalPadding: "",
      backBtnHorizontalPosition: { value: "center", label: 'Middle' },
      backBtnVerticalPosition: { value: "top", label: 'Top' },
      backBtnFontColor: "",
      backBtnText: "Back",
      backBtnFontSize: "",
      backBtnFontWeight: { value: 400, label: '400' },
    },
    onSiteMessage: {
      onSiteMessageMaxWidth: "",
      onSiteMessageMaxWidthOnMobile: "",
    },
    leadFormDimensions: {
      formHeight_popup: "",
      formWidth_popup: "",
      formHeightonMobile_popup: "",
      formWidthonMobile_popup: "",
    },
    offerHeadline: {
      offerHeadlineText: "",
      offerHeadlineFontColor: "",
      offerHeadlineFontSize: "",
      offerHeadlineFontWeight: { value: 400, label: '400' }
    },
    submitButton: {
      submitButtonWidth: "",
      submitButtonWidthMobile: "",
      submitButtonColor: "",
      submitButtonColorOnHover: "",
      submitButtonBorderColor: "",
      submitButtonTextColor: "",
      submitButtonTextColorOnHover: "",
      submitButtonFontSize: "",
      submitButtonFontSizeOnMobile: "",
      submitButtonFontWeight: { value: 400, label: '400' },
      submitButtonAdditionalTextColor: "",
      submitButtonBorderRadius: "",
    },
    thankyouStep: {
      thankyouStepFontSize: "",
      thankyouStepFontSizeOnMobile: "",
      thankyouStepFontWeight: { value: 400, label: '400' },
      thankyouStepFontColor: "",
      thankyouStepDarkCloseButton: false,
    },
    progressBar: {
      progressBar: false,
      progressBarColor: "",
      progressBarBgColor: "",
      progressBarFontColor: "",
    },
    otherFontStyling: {
      linkColor: "",
      generalTextColor: "",
      socialProof: false,
      socialProofDefaultCount: "",
      socialProofLabel: "",
      socialProofText: "",
      socialProofTextColor: "",
      tcpNoticeTitleColor: "",
      tcpNoticeHandlerColor: "",
      tcpNoticeTextBackgroundColor: "",
      tcpNoticeTextColor: "",
      tcpNoticeTextAlign: { value: 'center', label: 'Center' },
    }

  },
  settings: defaultSettingsOptions,
  leadDelivery: {
    emailAddress: [],
    mobilePhoneNumber: [],
    // salesBaboonConfig: {
    //   enabled: false,
    //   apiKey: ''
    // }
    doplacCRMConfig: {
      enabled: false,
      webhookUrl: ''
    }
  }
};

export const EditFormSlice = createSlice({
  name: 'EditForm',
  initialState,
  reducers: {
    setisLoading: LoaderReducer,
    setActiveStep: ActiveStepReducer,
    setActivenavbar: ActiveNavBarReducer,
    setDesignOptions: DesignOptionsReducer,
    reOrderSteps: SetStepsReducer,
    saveStepfromJSON: SetStepsReducer,
    attachResponseToStep: AttachResponseToStepReducer,
    attachResponseToStepOptions: AttachResponseToCurrentOptionsReducer,
    setStepOptionsInner: setStepOptionsInnerReducer,
    setAsEntryStep: setAsEntryStepReducer,
    removeStep: removeStepReducer,
    setEntryStepAsCurrent: setTargetStepAsCurrentReducer,
    saveStep: saveStepReducer,
    setAddEditResponseWindow: (state, action: IAction<boolean>) => {
      state.responseOpen = action.payload;
    },
    setDesignOptionsFromJSON: (state, action: IAction<FormsDesignOptions>) => {
      state.designOptions = { ...action.payload }
    },
    setSettings: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setStepOptions: (state, action: IAction<FormSteps | undefined>) => {
      if (action.payload) {
        state.stepOptions = { ...state.stepOptions, ...action.payload };
      } else {
        state.stepOptions = undefined;
      }
    },
    addResponseList: (state, action) => {
      const index = state.steps.findIndex(x => x.id === String(action.payload.stepId));
      if (index !== -1) {
        const current_obj: any = state.steps[index];
        state.steps[index] = { ...current_obj, responses: [...current_obj.responses, { ...action.payload.response_obj }] };
      }
    },
    removeResponseFromList: (state, action) => {
      const index = state.steps.findIndex(x => x.id === String(action.payload.stepId));
      if (index !== -1) {
        const current_obj = state.steps[index];
        current_obj.responses = current_obj.responses?.filter((x: any) => x.id !== action.payload.currentID);
        state.stepOptions = current_obj;
      }
    },

    RefreshIconInReduxState: (state, action: IAction<{
      stepID: string
      responseID: string,
      data: IconsData
    }>) => {
      if (state.stepOptions) {
        const prevState = state.stepOptions.responses || [];
        const current_objIndex = prevState.findIndex(x => x.id == action.payload.responseID);
        if (current_objIndex > -1) {
          const current_obj = prevState[current_objIndex];
          current_obj.iconData = action.payload.data;
          AttachResponseToStepReducer(state, { payload: { stepId: action.payload.stepID, newResponse: current_obj }, type: action.type });
        }
      }
    },

    removeFieldFromList: (state: FormsEditorOptions, action: IAction<{
      stepId: string,
      currentID: string
    }>) => {
      const index = state.steps.findIndex(x => x.id == action.payload.stepId);
      if (index !== -1) {
        const current_obj = state.steps[index];
        current_obj.responses = current_obj.responses?.filter((x) => x.id != action.payload.currentID);
      }
    },
    setLeadDelivery: (state, action: IAction<FormLeadDelivery>) => {
      state.leadDelivery = action.payload
    }
  },
});

export const {
  removeFieldFromList,
  removeResponseFromList,
  addResponseList,
  saveStep,
  setActivenavbar,
  setDesignOptions,
  setStepOptions,
  setStepOptionsInner,
  reOrderSteps,
  setSettings,
  setActiveStep,
  setDesignOptionsFromJSON,
  setisLoading,
  saveStepfromJSON,
  RefreshIconInReduxState,
  attachResponseToStep,
  attachResponseToStepOptions,
  setAsEntryStep,
  removeStep,
  setEntryStepAsCurrent,
  setAddEditResponseWindow,
  setLeadDelivery
} = EditFormSlice.actions

export default EditFormSlice.reducer;
