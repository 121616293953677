import TextField from '@mui/material/TextField';
import { FieldValidators, RequiredRule } from 'Helpers';
import { UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { setAccountEmail, setIsAccountEmail } from 'Store/slices/EmailFormSlice';

// const theme = createTheme({
//     components: {
//       MuiTextField: {
//         variants: [
//           {
//             props: { variant: 'outlined' },
//             style: {
//                 "& .MuiOutlinedInput-root": {
//                     "& > fieldset": { borderColor: "orange" },
//             },
//           },
//         ],
//       },
//     },
//   });
// const TextFieldCustom = withStyles({
//     root: {
//       '& label.Mui-focused': {
//         color: 'white',
//       },
//       '& .MuiInput-underline:after': {
//         borderBottomColor: 'yellow',
//       },
//       '& .MuiOutlinedInput-root': {
//         '& fieldset': {
//           borderColor: 'white',
//         },
//         '&:hover fieldset': {
//           borderColor: 'white',
//         },
//         '&.Mui-focused fieldset': {
//           borderColor: 'yellow',
//         },
//       },
//     },
//   })(TextField);
export const Text = (props: {
    data: FormStepResponses,
    formMain: UseFormReturn<any, any>
    currentSlice: StoreSlices,
    as: 'input' | 'textarea'
}) => {
    const { data, formMain: { register, watch, formState: { errors } }, currentSlice, as } = props;
    const settings = useSelector((state: AppStore) => state[currentSlice].settings);
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (data.isAccountEmail == true && data.validationType == "Email") {
         //Capture this field value and compare it with second field
            console.log(watch(String(data.id)))
             console.log(data.id);
             dispatch(setAccountEmail(watch(String(data.id))));
             dispatch(setIsAccountEmail(true));
        }
    },[watch(String(data.id)),data.validationType == "Email"])

    const accountemail = useSelector((state: AppStore) => state.emailForm.email);
    const isaccountemail = useSelector((state: AppStore) => state.emailForm.isAccountEmail);
    const emailId = useSelector((state: AppStore) => state.emailForm.emailId);

    return (
        <div>
            {
                <TextField
                    className='w-100 mb-2'
                    label={data?.fieldLabel}
                    multiline={as == 'textarea'}
                    rows={4}
                    error={!!errors[String(data.id)]}
                    defaultValue=""
                    color="primary"
                    variant="filled"
                    sx={{
                        backgroundColor: "white"
                        // "& .MuiInputLabel-root": {color: 'green'},
                        // "&:hover .MuiInputLabel-root": {color: 'red'},
                        // "&:focus .MuiInputLabel-root": {color: 'brown'},
                        // "& .MuiOutlinedInput-root": {
                        //   "& > fieldset": { borderColor: "orange" },
                        // },
                        // "&:hover .MuiOutlinedInput-root": {
                        //     "& > fieldset": { borderColor: "red" },
                        //   },
                        //   "&:focus .MuiOutlinedInput-root": {
                        //     "& > fieldset": { borderColor: "brown" },
                        //   },
                        //   "&: .MuiOutlinedInput-root:after": {
                        //     "& > fieldset": { borderColor: "yellow" },
                        //   },
                    }}
                    type={parseInputType(data.validationType)}
                    {...register(String(data.id), {
                        shouldUnregister: true,
                        validate: v => FieldValidators(v, String(data.id), emailId, isaccountemail, accountemail, data.validationType || 'Text', settings),
                        required: RequiredRule(data.isRequired, settings.formField_Required_ErrorText),
                    })}
                />

            }
            {errors[String(data.id)] && <span className='showError text-danger small'>{errors[String(data.id)]?.message?.toString()}</span>}
        </div>
    );
};
const parseInputType = (validationType?: ValidationTypes) => {
    switch (validationType) {
        case 'Email':
            return 'email';
        case 'Number':
            return 'number';
        case 'Zipcode':
            return 'number';
        case 'Password':
            return 'password';
        default:
            return 'text';
    }
}
