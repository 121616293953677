import styled from 'styled-components';
import Menu from './Menu';
import Userdropdown from './Userdropdown';


const Sidebarbg = styled.div`
    background-color: #1a1a1e;
    padding: 10px;
    padding-top:20px;
    height:100%;
    width:100%;
`

const Sidebar = () => {
    return (
        <Sidebarbg>
            <div className='text-center'>
                <h2 className='text-white'>
                    Lead Page Magnet
                </h2>
                <Userdropdown />
                <Menu />
            </div>
        </Sidebarbg>
    );
}

export default Sidebar;