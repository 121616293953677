import { defaultstepOptions } from "commonJSON";

export const ActiveNavBarReducer = (state: FormsEditorOptions, action: IAction<FormActiveNav>) => {
    state.activeNavBar = action.payload;
}
export const LoaderReducer = (state: FormsEditorOptions, action: IAction<boolean>) => {
    state.isLoading = action.payload;
}
export const ActiveStepReducer = (state: FormsEditorOptions, action: IAction<FormSteps | undefined>) => {
    state.stepOptions = action.payload;
}
export const DesignOptionsReducer = (state: FormsEditorOptions, action: IAction<{
    key: keyof FormsDesignOptions,
    data: any
}>) => {
    const prevState = state.designOptions[action.payload.key];
    state.designOptions[action.payload.key] = { ...prevState, ...action.payload.data }
    state.designOptions = { ...state.designOptions }
}
export const SetStepsReducer = (state: FormsEditorOptions, action: IAction<FormSteps[]>) => {
    state.steps = [...action.payload];
}
export const AttachResponseToStepReducer = (state: FormsEditorOptions, action: IAction<{
    stepId: string,
    newResponse?: FormStepResponses
}>) => {
    const index = state.steps.findIndex(x => x.id === action.payload.stepId);
    if (action.payload.newResponse) {
        const responsesCopy = AddOrReplaceResponse(Object.assign<FormStepResponses[], FormStepResponses[]>([], state.steps[index].responses || []), action.payload.newResponse);
        const stepsCopy = Object.assign<FormSteps[], FormSteps[]>([], state.steps);
        const stepCopy = Object.assign({}, stepsCopy[index]);
        stepCopy.responses = responsesCopy;
        stepsCopy[index] = stepCopy;
        state.stepOptions = stepCopy;
        state.steps = stepsCopy;
    }
}
export const AttachResponseToCurrentOptionsReducer = (state: FormsEditorOptions, action: IAction<FormStepResponses>) => {
    const options = { ...state.stepOptions };
    options.responses = AddOrReplaceResponse(options.responses || [], action.payload);
    state.stepOptions = options;
}
const AddOrReplaceResponse = (responses: FormStepResponses[], response: FormStepResponses) => {
    const index = responses.findIndex(x => x.id == response.id);
    const newResponses = [...responses];
    if (index > -1) {
        newResponses[index] = response;
    } else {
        newResponses.push(response);
    }
    return newResponses;
}
export const setStepOptionsInnerReducer = (state: FormsEditorOptions, action: IAction<{
    [n in keyof FormSteps]?: FormSteps[n];
}>) => {
    state.stepOptions = { ...state.stepOptions, ...action.payload }
};

export const setAsEntryStepReducer = (state: FormsEditorOptions, action: IAction<{ stepId: string }>) => {
    state.steps.forEach(s => {
        if (s.id == action.payload.stepId) s.isEntryStep = true;
        else s.isEntryStep = false;
    });
    if (state.stepOptions)
        state.stepOptions.isEntryStep = true;
}
export const removeStepReducer = (state: FormsEditorOptions, action: IAction<{ stepId: string }>) => {
    const index = state.steps.findIndex(x => x.id == action.payload.stepId);
    state.steps.splice(index, 1);
};
export const setTargetStepAsCurrentReducer = (state: FormsEditorOptions, action: IAction<undefined>) => {
    const step = state.steps.find(x => x.isEntryStep);
    if (step) {
        state.stepOptions = step;
    } else if (state.steps.length > 0) {
        const step = state.steps[0];
        state.stepOptions = step;
    } else {
        state.stepOptions = { ...defaultstepOptions }
    }
}
export const saveStepReducer = (state: FormsEditorOptions, action: IAction<FormSteps>) => {
    const step = state.steps.findIndex(x => x.id == action.payload.id);
    if (step > -1) {
        state.steps[step] = action.payload;
    } else {
        state.steps = [...state.steps, action.payload];
    }
}