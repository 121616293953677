import { BlueBtn, CancelBtn } from "Components/Common";
import { v4 as uuid } from "uuid";
import { useFormik } from 'formik';
import { CallAxios, FalseResponse, FocusOnView } from 'Helpers';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  //addFormField,
  attachResponseToStep,
  attachResponseToStepOptions,
  setAddEditResponseWindow
} from "Store";
import * as Yup from 'yup';
import AddEditFormField from './AddEditFormField';
import { FormFieldsList } from './FormFieldList';

const AddNewformfield = () => {
  const { id, formId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const StepsList = useSelector((state: AppStore) => state.editor.steps);
  const name = useSelector((state: AppStore) => state.editor.stepOptions?.name);
  const addField = useSelector((state: AppStore) => state.editor.responseOpen);


  const formik = useFormik<FormStepResponses>({
    initialValues: {
      id: undefined,
      stepId: String(id),
      formId: String(formId),
      fieldValue: '',
      iconData: undefined,
      isLinkButton: false,
      isAccountEmail:false,
      isPhoneButton: false,
      variableName: '',
      targetStepId: undefined,
      linkButton: '',
      phoneButton: '',
      isRequired: false,
      responseOrFieldName: '',
      displayOrder:0,
      personallyIdentifiableInformation: false,
      fieldLabel: '',
      fieldType: 'Text',
      validationType: 'Text',
      createdOptions: [{
        id: uuid(),
        label: '',
        value: ''
      }],
      slider: {
        Show_field_label: false,
        labelColor: "#fff",
        sliderbarColor: "#fff",
        sliderValueColor: "#fff",
        sliderValueType: 'Numeric',
        sliderOptions: [],
        sliderStartValue: 1,
        sliderEndValue: 100,
        startAndEndlabelsColor: "#fff",
        sliderStepValue: 1,
        sliderStartValueFormat: "",
        sliderEndValueFormat: "",
        sliderValueFormat: "",
        sliderValueNumberFormat: { value: 'Plain', label: "100000" },
      }
    },
    validationSchema: formFieldSchema,
    onSubmit: (values, { resetForm }) => {
      if (id) {
        const variableName = name?.replaceAll(" ", "_") + '_' + values.responseOrFieldName.replaceAll(" ", "_");
        // const response_obj: FormStepResponses = {
        //   ...values,
        //   variableName
        // };
        //dispatch(addFormField({ stepId: id, response_obj }));
        CallAxios('/api/Response/SaveResponse|POST',
          {
            ...values,
            variableName,
            stepId: id,
            sliderData: JSON.stringify(values.slider),
            createdOptions: JSON.stringify(values.createdOptions) as any
          },
          undefined,
          undefined,
          navigate,
          dispatch).then(res => {
            toast(res?.data?.message, { type: res?.data.success ? 'success' : 'warning' });
            if (res?.data.success) {
              const newResponse = {
                ...res.data.data,
                slider: typeof res.data.data.sliderData == 'string' ? JSON.parse(res.data.data.sliderData || '{}') : res.data.data.sliderData,
                createdOptions: typeof res.data.data.createdOptions == "string" ? JSON.parse(res.data.data.createdOptions || '[]') : res.data.data.createdOptions
              };

              if (res?.data.data) {
                dispatch(attachResponseToStep({
                  stepId: String(id),
                  newResponse: newResponse
                }));
                //dispatch(attachResponseToStepOptions(newResponse));
              }

              dispatch(setAddEditResponseWindow(false));
              resetForm();
              FocusOnView('responseWrapper');
            } else {
              FalseResponse(res?.data.message);
            }
          }).catch(_ => {
            FalseResponse();
          });
      }
      else {
        FalseResponse();
      }
    }
  });

  return (
    <div className='responseWrapper text-start'>

      <FormFieldsList setAddField={e => dispatch(setAddEditResponseWindow(Boolean(e)))} formik={formik} list={StepsList} />

      {
        addField && <AddEditFormField formik={formik} />
      }

      {
        !addField ? <div className='mt-2 px-3'>
          <BlueBtn disabled={!formik.isValid}
            onClick={() => {
              dispatch(setAddEditResponseWindow(true));
              FocusOnView('editor_main');
            }}>
            + Add form field
          </BlueBtn>
        </div>
          : <div className='d-flex justify-content-between align-items-center px-5'>
            <BlueBtn
              onClick={() => formik.handleSubmit()}
              type='submit'
              className="me-3">
              Save form field
            </BlueBtn>

            <CancelBtn onClick={() => {
              formik.resetForm();
              dispatch(setAddEditResponseWindow(false));
              FocusOnView('responseWrapper');
            }}>
              Cancel
            </CancelBtn>
          </div>
      }

    </div>
  );
}

export default AddNewformfield;
const formFieldSchema = Yup.object().shape({
  responseOrFieldName: Yup.string().required('This field may not be blank.'),
  fieldLabel: Yup.string().required('This field may not be blank.'),
});