import { toast } from "react-toastify";

export const FocusOnView = (id: 'responseWrapper' | 'editor_main') => {
    setTimeout(() => {
        const responseWrapper = document.getElementById(id);
        if (responseWrapper)
            window.scrollTo(0, responseWrapper.offsetTop - 120);
        const autofocusable = document.getElementById('autofocusable') as HTMLInputElement | undefined;
        if (autofocusable) {
            const end = autofocusable.value.length;
            autofocusable.setSelectionRange(end, end);
            autofocusable.focus();
        }
    }, 250);
}
export const FalseResponse = (message = 'Something went wrong. Try again later') => {
    toast(message, { type: 'error' });
};