import { reorder } from "Components/Common";
import FormFloatingLabel from "Components/FloatingInput";
import { FieldArray, FormikProvider } from "formik";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { FaBars } from "react-icons/fa";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

const CreateOptionsForSelectBox = (props: {
  formik: FormikType<FormStepResponses>;
}) => {
  const { formik } = props;

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    reorder(
      formik.values.createdOptions || [],
      result.source.index,
      result.destination.index
    );
  };
  // useMemo(() => {
  //     if (!formik.values.createdOptions || formik.values.createdOptions.length == 0) {
  //         formik.setFieldValue('createdOptions', [{ id: uuidv4(), label: '', value: "" }]);
  //     }
  // }, [formik.values.createdOptions]);
  return (
    <div>
      {(formik.values.createdOptions?.length || 0) === 0 && (
        <AddBtn
          className="float-end"
          onClick={(e) =>
            formik.setFieldValue(`createdOptions`, [
              { id: uuidv4(), label: "", value: "" },
            ])
          }
        >
          +
        </AddBtn>
      )}
      <FormikProvider value={formik}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <FieldArray
                  name="createdOptions"
                  render={(arrayHelpers) =>
                    (formik.values.createdOptions || []).map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={"id_" + item.id + index}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="ListItem"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <li className="row mx-0">
                              <div className="col-1 px-0 align-self-center">
                                <span
                                  className="DragHandlers"
                                  {...provided.dragHandleProps}
                                >
                                  <FaBars fontSize={14} />
                                </span>
                              </div>
                              <div className="col-11">
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <FormFloatingLabel
                                      onInput={(value) =>
                                        formik.setFieldValue(
                                          `createdOptions.${index}.label`,
                                          value
                                        )
                                      }
                                      label={"Option"}
                                      defaultValue={item.label}
                                    />
                                  </div>
                                  <div className="ms-2">
                                    <FormFloatingLabel
                                      className="ms-1"
                                      onInput={(value) =>
                                        formik.setFieldValue(
                                          `createdOptions.${index}.value`,
                                          value
                                        )
                                      }
                                      label={"Webhook Field Value"}
                                      defaultValue={item.value}
                                    />
                                  </div>
                                  <div className="buttonsGroupsHere align-items-center ms-1">
                                    {formik.values.createdOptions?.length ===
                                      index + 1 && (
                                      <AddBtn
                                        className="AddBtn mb-1"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            id: uuidv4(),
                                            label: "",
                                            value: "",
                                          })
                                        }
                                      >
                                        +
                                      </AddBtn>
                                    )}
                                    <RemoveBtn
                                      className="removeBtn"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </RemoveBtn>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </FormikProvider>
    </div>
  );
};

export default CreateOptionsForSelectBox;

const AddBtn = styled.span`
  border: none;
  border-radius: 3px;
  text-align: center;
  color: rgb(255, 255, 255);
  background: rgb(65, 112, 251);
  cursor: pointer;
  height: 27px;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  width: 27px;
  display: block;
`;
const RemoveBtn = styled.span`
  border: none;
  border-radius: 3px;
  text-align: center;
  color: rgb(255, 255, 255);
  background: rgb(251, 104, 93);
  cursor: pointer;
  height: 27px;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  width: 27px;
  display: block;
`;
