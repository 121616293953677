import {
    Button,
    Card,
    CardContent,
    FormControlLabel,
    IconButton, Tabs,
    TextField
} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AxiosLite, CallAxios } from 'Helpers';
import * as React from 'react';
import { Badge } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { confirmAlert } from 'react-confirm-alert';
import { Controller, useForm } from 'react-hook-form';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ESTemplatesList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selected, setSelected] = React.useState<"Email" | "SMS" | "Notification">('SMS');
    const [rows, setRows] = React.useState<ESTemplates[]>([]);
    const [show, setShow] = React.useState(false);

    const { register, formState: { errors },  handleSubmit, reset, control } = useForm<ESTemplates>({
        defaultValues: {
            id: undefined,
            name: '',
            content: '',
            published: false,
            templateType: 'SMS'
        },
        shouldFocusError: true,
    });

    const handleClose = () => {
        reset();
        setShow(false);
    }
    const handleShow = () => {
        reset({});
        setShow(true);
    }

    const loadData = () => {
        AxiosLite(
            '/api/ESTemplates/List|GET',
            { type: selected },
            navigate,
            dispatch
        ).then(res => {
            setRows(res?.data.list || []);
        });
    };

    React.useEffect(() => {
        loadData();
    }, [selected]);
    const Delete = (id?: string) => {
        confirmAlert({
            closeOnClickOutside: false,
            closeOnEscape: false,
            title: 'Are you sure?',
            message: 'This action can\'t be undone.',
            buttons: [
                {
                    label: 'Yes',
                    className: 'bg-danger',
                    onClick: () => {
                        AxiosLite('/api/ESTemplates/Delete|DELETE',
                            {
                                id: id
                            },
                            navigate,
                            dispatch).then(res => {
                                loadData();
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }
    return (
        <div>
            <Card style={{ boxShadow: '0px 1px 15px 1px rgb(69 65 78 / 8%)' }}>
                <Button
                    className='float-end me-2 mt-2'
                    onClick={handleShow}
                    variant='contained'
                    color='primary'>Add {selected} Template</Button>
                <CardContent>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selected} onChange={(_, nv) => {
                            setSelected(nv);
                        }} >
                            <Tab label="SMS" value="SMS" />
                            <Tab label="Email" value="Email" />
                        </Tabs>
                    </Box>
                    <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
                        <Table sx={{ boxShadow: 'none' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    {
                                        selected === 'Email' &&
                                        <TableCell>Subject</TableCell>
                                    }
                                    <TableCell>Created On</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Modified On</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows.map((row) =>
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            {
                                                selected === 'Email' &&
                                                <TableCell>{row.subject}</TableCell>
                                            }
                                            <TableCell>{row.createdDateUtc}</TableCell>
                                            <TableCell>
                                                <Badge bg={row.published ? 'success' : 'danger'} children={row.published ? 'Active' : 'In-Active'} />
                                            </TableCell>
                                            <TableCell>{row.lastModifiedDateUtc}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={e => {
                                                    reset();
                                                    handleShow();
                                                    reset(row);
                                                }}>
                                                    <MdEdit />
                                                </IconButton>
                                                <IconButton onClick={e => Delete(row.id)}>
                                                    <MdDeleteForever className='text-danger' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Modal
                show={show}
                centered
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit {selected} Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(values => {
                        CallAxios('/api/ESTemplates/Save|POST',
                            {
                                ...values,
                                templateType: selected,
                                published: Boolean(values.published)
                            },
                            undefined,
                            undefined,
                            navigate,
                            dispatch)
                            .then(res => {
                                toast(res?.data.message, {
                                    type: res?.data.success ? 'success' : 'error'
                                });
                                if (res?.data.success) {
                                    reset();
                                    loadData();
                                    handleClose();
                                }
                            })
                    })}>
                        <TextField
                            className='w-100 mb-2'
                            label='Name'
                            {...register('name', {
                                required: { message: 'Required', value: true }
                            })}
                            error={!!errors['name']?.message} />
                        {
                            selected === 'Email' &&
                            <TextField
                                className='w-100 mb-2'
                                label='Subject'
                                {...register('subject', {
                                    required: { message: 'Required', value: true }
                                })}
                                error={!!errors['subject']?.message} />
                        }

                        <TextField
                            className='w-100 mb-2'
                            label='Content'
                            multiline
                            rows={4}
                            {...register('content', {
                                required: { message: 'Required', value: true }
                            })}
                            error={!!errors['content']?.message} />

                        <Controller
                            control={control}
                            name='published'
                            rules={{}}
                            render={({ field }) =>
                                <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label="Is Active" />
                            } />

                        <Button
                            variant="contained"
                            color='success'
                            className='float-end'
                            type='submit'>
                            Save
                        </Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={handleClose} color='error'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};