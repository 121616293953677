import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setStepOptionsInner } from 'Store';
import CustomSelect from '../CustomSelect';

export const StepsSelect = (props: {
    defaultValue?: string;
}) => {
    const { defaultValue } = props;
    const dispatch = useDispatch();
    const [data, setData] = useState<LabelValuePair<string>[]>([]);
    const steps = useSelector((state: AppStore) => state.editor.steps);
    useEffect(() => {
        const newList: LabelValuePair<string>[] = [];
        (steps || []).map(x => {
            if (x.id) {
                return newList.push({ label: x?.name || '', value: x.id });
            } else {
                throw new Error('Step: ' + x.name + ', Has step order ' + x.stepOrder);
            }
        });
        setData(newList)
    }, [steps]);
    return (
        <>
            <label className='mb-2'>Select a target step:</label>
            <CustomSelect data={data}
                onChange={(value) => dispatch(setStepOptionsInner({ targetStepId: value?.value }))}
                defaultValue={data.find(x => x.value === defaultValue)} name={"target"} />
        </>
    );
}