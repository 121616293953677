import { FormatNumberToString } from 'Helpers';
import { useEffect, useState } from 'react';
import { UseFormReturn } from "react-hook-form";
import { Label, PrettoSlider, SliderLabels } from "./Shared";

export const NumericSlider = (props: {
    data: FormStepResponses,
    formMain: UseFormReturn<any, any>
    currentSlice: StoreSlices,
}) => {
    const { data, currentSlice, formMain, formMain: { register, getValues, formState: { errors } } } = props;
    // const min = Number(data.slider?.sliderStartValue) || 1;
    const min = data.slider?.sliderStartValue != null ? Number(data.slider.sliderStartValue) : 1;
    const max = Number(data.slider?.sliderEndValue) || 100;
    const step = Number(data.slider?.sliderStepValue) || 1;
    const [value, setValue] = useState(min);
    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
        }
    };
    useEffect(() => {
        setValue(Number(getValues(String(data.id))) || min);
    }, [data, data.id, min, max, step]);
    return (
        <div className='mb-3'>
            {
                data.slider?.Show_field_label &&
                <Label textColor={data.slider?.labelColor}>{data.fieldLabel}</Label>
            }
            <Label style={{ fontSize: 18 }} textColor={data.slider?.sliderValueColor} className='font-fix'>
                {(data.slider?.sliderValueFormat || '{}')
                    .replace('{}', FormatNumberToString(value, (data.slider?.sliderValueNumberFormat?.value || 'Plain')))}
            </Label>
            <div>
                <PrettoSlider
                    sx={{
                        color: data.slider?.sliderbarColor,
                        '& .MuiSlider-valueLabel': {
                            backgroundColor: data.slider?.sliderValueColor
                        }
                    }}
                    {...register(String(data.id), { shouldUnregister: true })}
                    size="medium"
                    value={value}
                    min={min}
                    max={max}
                    step={step}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={n => (data.slider?.sliderValueFormat || '{}').replace('{}', n.toLocaleString())}
                />
                <div className='d-flex justify-content-between align-items-center'>
                    <SliderLabels textColor={data.slider?.startAndEndlabelsColor} className='font-fix'>
                        {(data.slider?.sliderStartValueFormat || '{}').replace('{}', min.toLocaleString())}
                    </SliderLabels>
                    <SliderLabels textColor={data.slider?.startAndEndlabelsColor} className='font-fix'>
                        {(data.slider?.sliderEndValueFormat || '{}').replace('{}', max.toLocaleString())}
                    </SliderLabels>
                </div>
            </div>
        </div>
    )
}