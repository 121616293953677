import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import InputPrefix from "Components/PrefixInput";
import ToolTip from 'Components/Tooltip';

const LeadFormDimensions = () => {


    return (
        <>

            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Form Height (Popup)
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the height of the popup form only. The form height does not apply to the embedded form.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"%"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Form Width (Popup)
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the width of the popup form only. The form width does not apply to the embedded form.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"%"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Form Width On Mobile (Popup)
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the width of the popup form on mobile devices only. The form width does not apply to the embedded form.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"%"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Form Height On Mobile (Popup)
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the height of the popup form on mobile devices only. The form height does not apply to the embedded form.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"%"} />


                    </div>

                </div>

            </TopBottomPadding>


        </>
    );
}

export default LeadFormDimensions;