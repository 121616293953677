import  { useState, useEffect } from 'react';
import Switch from "react-switch";

const MaterialDesignSwitch = (props: {
  defaultchecked?: boolean,
  id?: string,
  checked: (e: boolean) => void
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(props.defaultchecked || false)
  }, [props.defaultchecked]);
  return (
    <Switch
      checked={checked}
      onChange={(checked) => { setChecked(checked); props.checked(checked) }}
      onColor="#7ccac3"
      onHandleColor="#009688"
      offColor="#eeeeee"
      offHandleColor="#bdbdbd"
      handleDiameter={22}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={17}
      width={48}
      className="react-switch"
      id={props.id}
    />
  );
}

export default MaterialDesignSwitch;