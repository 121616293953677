import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import ToolTip from 'Components/Tooltip';

import { useDispatch, useSelector } from 'react-redux';
import { setDesignOptions } from "Store/slices/EditFormSlice";

const ProgressBar = () => {
    const dispatch = useDispatch();
    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);

    return (
        <>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Progress Bar
                        <div className='ms-2'>
                            <ToolTip text={`With this feature you can either enable or disable a progress bar. If enabled, you'll see the progress bar start on step two of your forms.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <MaterialDesignSwitch defaultchecked={designOptions.progressBar.progressBar} id={"ProgressBar"} checked={(checked) => dispatch(setDesignOptions({ key: "progressBar", data: { progressBar: checked } }))} />


                    </div>

                </div>

            </TopBottomPadding>
            {designOptions.progressBar.progressBar === true ? <div>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Progress Bar Color
                            <div className='ms-2'>
                                <ToolTip text={`This changes the part of the progress bar that shows progress is being made. For example, if you set the progress bar color to green, then the bar will turn green as the prospect advances in the form.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                dispatch(setDesignOptions({ key: "progressBar", data: { progressBarColor: color } }))
                            }} defaultValue={designOptions.progressBar.progressBarColor} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Progress Bar Background Color
                            <div className='ms-2'>
                                <ToolTip text={`This changes the background color of the progress bar. This is the part of the progress bar that becomes less visible as progress is being made.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                dispatch(setDesignOptions({ key: "progressBar", data: { progressBarBgColor: color } }))
                            }} defaultValue={designOptions.progressBar.progressBarBgColor} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Progress Bar Text Color
                            <div className='ms-2'>
                                <ToolTip text={`This changes the text color of the progress bar. For example if set to blue, then a percent such as 25% will appear blue.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                dispatch(setDesignOptions({ key: "progressBar", data: { progressBarFontColor: color } }))
                            }} defaultValue={designOptions.progressBar.progressBarFontColor} />


                        </div>

                    </div>

                </TopBottomPadding>
            </div> :
                null}
        </>
    );
}

export default ProgressBar;