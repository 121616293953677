import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import CustomSelect from "Components/CustomSelect";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import InputPrefix from "Components/PrefixInput";

import { useDispatch, useSelector } from 'react-redux';
import { setDesignOptions } from "Store/slices/EditFormSlice";

const BackButton = () => {
    const dispatch = useDispatch();
    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);

    const backButtonTextWeight = [
        { value: 400, label: '400' },
        { value: 500, label: '500' },
        { value: 600, label: '600' },
        { value: 700, label: '700' },
        { value: 900, label: '900' },
    ];
    const backButtonHorizontalPosition = [
        { value: "left", label: 'Left' },
        { value: "center", label: 'Middle' },
        { value: "right", label: 'Right' },
    ];
    const backButtonVerticalPosition = [
        { value: "top", label: 'Top' },
        { value: "bottom", label: 'Bottom' },
    ];
    return (
        <>

            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Back Button
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch defaultchecked={designOptions.backButton.backBtn} id={"backButton"} checked={(checked) => dispatch(setDesignOptions({ key: "backButton", data: { backBtn: checked } }))} />

                    </div>

                </div>

            </TopBottomPadding>
            {designOptions.backButton.backBtn === true ? <div>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Color
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                dispatch(setDesignOptions({ key: "backButton", data: { backBtnBgColor: color } }))
                            }} defaultValue={designOptions.backButton.backBtnBgColor} />

                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Color On Hover
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                dispatch(setDesignOptions({ key: "backButton", data: { backBtnHoverBgColor: color } }))
                            }} defaultValue={designOptions.backButton.backBtnHoverBgColor} />

                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Border Radius

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <InputPrefix defaultValue={designOptions.backButton.backBtnBorderRadius} onInput={(value) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnBorderRadius: value } }))} prefix={"pixels"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Vertical Padding
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <InputPrefix defaultValue={designOptions.backButton.backBtnVerticalPadding} onInput={(value) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnVerticalPadding: value } }))} prefix={"pixels"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Width Auto (By Content Width)

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform `}>
                            <MaterialDesignSwitch defaultchecked={designOptions.backButton.backBtnWidthAuto} id={"backButtonWidthAuto"} checked={(checked) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnWidthAuto: checked } }))} />

                        </div>

                    </div>

                </TopBottomPadding>
                {designOptions.backButton.backBtnWidthAuto === false ?
                    <TopBottomPadding>

                        <div className='d-flex justify-content-between align-items-center'>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                Back Button Width

                            </CollapsedAreaLabels>
                            <div className={`itemsPerform  `}>
                                <InputPrefix defaultValue={designOptions.backButton.backBtnWidth} onInput={(value) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnWidth: value } }))} prefix={"%"} />


                            </div>

                        </div>

                    </TopBottomPadding>
                    :
                    <TopBottomPadding>

                        <div className='d-flex justify-content-between align-items-center'>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                Back Button Horizontal Padding

                            </CollapsedAreaLabels>
                            <div className={`itemsPerform  `}>
                                <InputPrefix defaultValue={designOptions.backButton.backBtnHorizontalPadding} onInput={(value) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnHorizontalPadding: value } }))} prefix={"pixels"} />


                            </div>

                        </div>

                    </TopBottomPadding>
                }
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Horizontal Position
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  w-50`}>
                            <CustomSelect defaultValue={designOptions.backButton.backBtnHorizontalPosition} onChange={(selected) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnHorizontalPosition: selected } }))} data={backButtonHorizontalPosition} name={"backButtonHorizontalPosition"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Vertical Position
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  w-50`}>
                            <CustomSelect defaultValue={designOptions.backButton.backBtnVerticalPosition} onChange={(selected) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnVerticalPosition: selected } }))} data={backButtonVerticalPosition} name={"backButtonVerticalPosition"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Text Color
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {
                                dispatch(setDesignOptions({ key: "backButton", data: { backBtnFontColor: color } }))
                            }} defaultValue={designOptions.backButton.backBtnFontColor} />

                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Text

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  w-50`}>
                            <InputPrefix width={150} defaultValue={designOptions.backButton.backBtnText} onInput={(value) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnText: value } }))} prefix={" "} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Text Size

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <InputPrefix defaultValue={designOptions.backButton.backBtnFontSize} onInput={(value) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnFontSize: value } }))} prefix={"pixels"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Text Size On Mobile

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <InputPrefix prefix={"pixels"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Back Button Text Weight
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  w-50`}>
                            <CustomSelect defaultValue={designOptions.backButton.backBtnFontWeight} onChange={(selected) => dispatch(setDesignOptions({ key: "backButton", data: { backBtnFontWeight: selected } }))} data={backButtonTextWeight} name={"backButtonTextWeight"} />


                        </div>

                    </div>

                </TopBottomPadding>
            </div>
                : null}
        </>
    );
}

export default BackButton;