import { useState } from 'react';
import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import CustomSelect from "Components/CustomSelect";
import ToolTip from 'Components/Tooltip';

import FormFloatingLabel from "Components/FloatingInput";


const OtherFontStyling = () => {
    const [TCPATEXTALIGN, setTCPATEXTALIGN] = useState([
        { value: 'left', label: 'Left' },
        { value: 'center', label: 'Center' },
        { value: 'right', label: 'Right' }
    ]);

    const [socialProof, setsocialProof] = useState(false);

    return (
        <>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Link Color
                        <div className='ms-2'>
                            <ToolTip text={`This setting will adjust the color of any links that you add to the form. For example, setting the link color to "green" will turn all links green.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker defaultValue={"#fff"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        General Text Color
                        <div className='ms-2'>
                            <ToolTip text={`This setting changes any other text color that's not addressed by one of the settings above.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker defaultValue={"#fff"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Social Proof
                        <div className='ms-2'>
                            <ToolTip text={`The social proof feature allows you to add real-time social proof to your forms to help boost conversions.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <MaterialDesignSwitch defaultchecked={socialProof} id={"socialProof"} checked={(checked) => setsocialProof(checked)} />


                    </div>

                </div>

            </TopBottomPadding>
            {socialProof === true ? <div className='socialProofDiv'>
                <TopBottomPadding>

                    <FormFloatingLabel label={"Social Proof Default Count"} />

                </TopBottomPadding>
                <TopBottomPadding>

                    <FormFloatingLabel label={"Social Proof Label"} />

                </TopBottomPadding>
                <TopBottomPadding>

                    <FormFloatingLabel label={"Social Proof Text"} />

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Social Proof Text Color
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker defaultValue={"#fff"} />


                        </div>

                    </div>

                </TopBottomPadding>
            </div>
                : null}


            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        TCPA Notice Title Color

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker defaultValue={"#fff"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        TCPA Notice Handler Color

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker defaultValue={"#fff"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        TCPA Notice Text Background Color


                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker defaultValue={"#fff"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        TCPA Notice Text Color


                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker defaultValue={"#fff"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        TCPA Notice Text Align


                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  w-50`}>
                        <CustomSelect data={TCPATEXTALIGN} name={"TCPATEXTALIGN"} />



                    </div>

                </div>

            </TopBottomPadding>


        </>
    );
}

export default OtherFontStyling;