import { FieldValidators, RequiredRule } from 'Helpers';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';

export const PhoneNumber = (props: {
  data: FormStepResponses,
  formMain: UseFormReturn<any, any>
  currentSlice: StoreSlices
}) => {
  const { data, formMain: { register, formState: { errors } }, currentSlice } = props;
  const [number, setNumber] = useState('');
  const [isValid, setIsValid] = useState(true);
  const settings = useSelector((state: AppStore) => state[currentSlice].settings);
  return (
    <div>
      <div className='mb-2 position-relative'>
        <PhoneInput
          inputProps={{
            ...register(String(data.id), {
              required: RequiredRule(data.isRequired, settings.formField_Required_ErrorText),
              shouldUnregister: true,
              validate: (e) => FieldValidators(e,String(data.id),"",false,"", data.validationType || 'PhoneNumberValidation', settings),
              onChange: (e) => {
                const value = e.target.value;
                setNumber(value);
                if (value) {
                  setIsValid(isValidPhoneNumber(value));
                }
              }
            })
          }}
          isValid={isValid}
          value={number}
          country={'us'}
          inputClass="CustomInputClassforPhone w-100" />
        <label className='forPhone'>{data?.fieldLabel}</label>
      </div>
      {
        errors[String(data.id)]?.message &&
        <p className='text-danger mb-2'>{errors[String(data.id)]?.message?.toString()}</p>
      }
    </div>
  );
}
