import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { NylasProvider } from '@nylas/nylas-react';
import { App } from 'App';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { AppStore } from 'Store';
import './index.css';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <NylasProvider serverBaseUrl="https://localhost:7013">
      <Provider store={AppStore}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <App />
          </LocalizationProvider>
        </BrowserRouter>
      </Provider>
    </NylasProvider>
  );
}
