import { FieldValidationTypeOptions } from 'Helpers/Global';
import Select, { ActionMeta, SingleValue } from 'react-select';

export const ValidationTypeSelect = (props: {
    onChange?: (selected: ValidationTypes) => void,
    defaultValue?: ValidationTypes,
    name?: string,
    id?: string
}) => {
    const options: OptionsType[] = FieldValidationTypeOptions
    const handleChange: ((newValue: SingleValue<OptionsType>, actionMeta: ActionMeta<OptionsType>) => void) = (selected) => {
        if (props.onChange) {
            props.onChange(selected?.value || 'Text');
        }
    }

    return (
        <Select
            options={options}
            className="basic-single"
            id={props.id}
            classNamePrefix="select"
            value={options.find(x => x.value === props.defaultValue) || options[0]}
            isSearchable={true}
            onChange={handleChange}
            name={props.name}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}

        />
    );
}
interface OptionsType {
    label: string,
    value: ValidationTypes
}