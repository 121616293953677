import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ICONS, ResponseLi, ResponseVariableName } from "Components/Common";
import { AxiosLite, FocusOnView, ParseObject } from "Helpers";
import { BsImage } from 'react-icons/bs';
import { FaBars } from "react-icons/fa";
import { IoCopyOutline } from "react-icons/io5";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { attachResponseToStepOptions, attachResponseToStep, removeResponseFromList } from "Store";

const ResponseList = (props: {
    list: FormSteps;
    formik: FormikType<FormStepResponses>;
    setAddResponse: (c: boolean) => void;
    setAddicon: (c: boolean) => void;
    setCurrentResponseId: (s: string) => void;
    setIconData: (s?: IconsData) => void;
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, formId } = useParams();
    const { list, formik, setAddResponse, setAddicon, setCurrentResponseId, setIconData } = props;
    const stepsList = useSelector((state: AppStore) => state.editor.steps);
    const name = stepsList?.find(x => x.id === String(id))?.name?.replaceAll(" ", "_");
    const removeResponse = async (currentID: string) => {
        const stepId = id;
        await AxiosLite('/api/Response/DeleteResponse|DELETE',
            {
                id: currentID,
                stepId,
                formId: formId
            },
            navigate,
            dispatch);
        dispatch(removeResponseFromList({ stepId, currentID }));
    }
    const duplicateRecord = async (responseId: string) => {
        if (responseId && id) {
            const resp = await AxiosLite('/api/Response/CopyResponse|POST',
                {
                    id: responseId
                },
                navigate,
                dispatch);
            if (resp?.data) {
                dispatch(attachResponseToStep({ stepId: id, newResponse: resp.data.data }));
                dispatch(attachResponseToStepOptions({ ...resp.data.data, stepId: id }));
            }
        }
    }

    return (
        <ul className='p-0 px-3'>
            {list?.responses?.map((item, j) =>
                <ResponseLi key={'res_lli_' + j}>
                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        <div className='d-flex justify-content-start align-items-center'>
                            <FaBars />
                            <div className='ms-3'>
                                <div>{item.responseOrFieldName}</div>
                                <ResponseVariableName className='text-break'>Variable name:{`{${name}_${!!item.variableName ? item.variableName : ""}}`}</ResponseVariableName>
                            </div>
                        </div>

                        <div>
                            <ICONS>
                                <Tooltip title='Duplicate response'>
                                    <IconButton onClick={() => item.id && duplicateRecord(item.id)}>
                                        <IoCopyOutline />
                                    </IconButton>
                                </Tooltip>
                            </ICONS>
                            <ICONS className='ms-2'>
                                <Tooltip title='Add image'>
                                    <IconButton onClick={() => {
                                        setAddResponse(false);
                                        setAddicon(true);
                                        setIconData(ParseObject<IconsData>(item.iconData))
                                        if (item.id)
                                            setCurrentResponseId(item.id)
                                        FocusOnView('editor_main');
                                    }}>
                                        <BsImage />
                                    </IconButton>
                                </Tooltip>
                            </ICONS>
                            <ICONS className='mx-2'>
                                <Tooltip title='Edit'>
                                    <IconButton onClick={() => {
                                        setAddResponse(true);
                                        setAddicon(false);
                                        formik.setValues(item);
                                        FocusOnView('editor_main');
                                    }}>
                                        <MdEdit />
                                    </IconButton>
                                </Tooltip>
                            </ICONS>
                            <ICONS>
                                <Tooltip title='Delete forever'>
                                    <IconButton onClick={() => item.id && removeResponse(item.id)}>
                                        <MdDeleteForever className='text-danger' />
                                    </IconButton>
                                </Tooltip>
                            </ICONS>
                        </div>
                    </div>
                </ResponseLi>
            )}
        </ul>
    )
}
export default ResponseList;