
import React, { useEffect, useState } from 'react';
import { ChromePicker, Color, ColorChangeHandler } from 'react-color';
import styled from 'styled-components';

const ColorPicker = (props: {
  defaultValue?: string,
  getColor?: (color: string) => void
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setcolor] = useState<Color | string>({
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  });
  const [setDivColor, SetDivColor] = useState<string>();
  const styles: Styles = {
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: `${setDivColor}`,
    },
    swatch: {
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
  };

  useEffect(() => {
    if (!!props.defaultValue) {

      setcolor(props.defaultValue);
      SetDivColor(props.defaultValue)
    } else {
      setcolor('rgba(255,255,255,1)');
      SetDivColor('rgba(255,255,255,1)')

    }
  }, [props.defaultValue]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const handleChange: ColorChangeHandler = (color) => {
    setcolor(color.rgb)
    SetDivColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
    if (!!props.getColor) {

      props.getColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
    }
  };

  return (
    <div>
      <div className='d-flex align-items-center'>
        <div style={styles.swatch} onClick={handleClick}>
          <div style={styles.color} />
        </div>
        <ClearBtn className="ms-2" onClick={() => { setcolor("#fff"); if (!!props.getColor) { props.getColor("") }; SetDivColor(`rgba(255,255,255,1)`) }}>Clear</ClearBtn>
      </div>
      {displayColorPicker ? <div style={styles.popover}>
        <div style={styles.cover} onClick={handleClose} />
        <ChromePicker color={color} onChange={handleChange} />
      </div> : null}

    </div>
  );
}

export default ColorPicker;
const ClearBtn = styled.span`
display: inline-block;
margin-left: 10px;
color: rgb(187, 187, 187);
position: relative;
font-size: 13px;
cursor: pointer;
&:hover{
  color: rgb(153, 153, 153);
}
`
interface Styles {
  color: React.CSSProperties,
  swatch: React.CSSProperties,
  popover: React.CSSProperties,
  cover: React.CSSProperties

}