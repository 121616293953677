import { useState } from 'react';
import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import InputPrefix from "Components/PrefixInput";
import CustomSelect from "Components/CustomSelect";
import ToolTip from 'Components/Tooltip';

import { useDispatch, useSelector } from 'react-redux';
import { setDesignOptions } from "Store/slices/EditFormSlice";

const StepHeadingAndSubHeading = () => {
    const dispatch = useDispatch();
    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);

    const [StepheadlineFontWeight, setStepHeadlineFontWeight] = useState([
        { value: 400, label: '400' },
        { value: 500, label: '500' },
        { value: 600, label: '600' },
        { value: 700, label: '700' },
        { value: 900, label: '900' },
    ]);
    return (
        <>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Step Heading Font Color
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the color of the main question text in your form.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "stepHeadingAndSubHeading", data: { stepHeadingFontColor: color } }))
                        }} defaultValue={designOptions.stepHeadingAndSubHeading.stepHeadingFontColor} />

                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Step Heading Font Size
                        <div className='ms-2'>
                            <ToolTip text={`Use this setting to adjust the font size of your form's question text`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions.stepHeadingAndSubHeading.stepHeadingFontSize} onInput={(value) => dispatch(setDesignOptions({ key: "stepHeadingAndSubHeading", data: { stepHeadingFontSize: value } }))} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Step Heading Font Size Mobile
                        <div className='ms-2'>
                            <ToolTip text={`Use this setting to adjust the font size of your form's question text on mobile only.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Step Heading Font Weight
                        <div className='ms-2'>
                            <ToolTip text={`Use this setting to adjust the font thickness of your form's question text.`} />
                        </div>

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  w-50`}>
                        <CustomSelect defaultValue={designOptions.stepHeadingAndSubHeading.stepHeadingFontWeight} onChange={(selected) => dispatch(setDesignOptions({ key: "stepHeadingAndSubHeading", data: { stepHeadingFontWeight: selected } }))} data={StepheadlineFontWeight} name={"StepheadlineFontWeight"} />


                    </div>

                </div>

            </TopBottomPadding>
            {designOptions.templateStyle.templateStyle.value === 'EnhancedForm' && <>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Step Sub-Heading Font Color
                            <div className='ms-2'>
                                <ToolTip text={`The sub-header text is an optional line of text that can appear below the question text of your form. This setting adjusts the color of the sub-header text.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <ColorPicker getColor={(color) => {

                                dispatch(setDesignOptions({ key: "stepHeadingAndSubHeading", data: { stepSubHeadingFontColor: color } }))
                            }} defaultValue={designOptions.stepHeadingAndSubHeading.stepSubHeadingFontColor} />

                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Step Sub-Heading Font Size
                            <div className='ms-2'>
                                <ToolTip text={`The sub-header text is an optional line of text that can appear below the question text of your form. This setting adjusts the size of the sub-header text.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <InputPrefix defaultValue={designOptions.stepHeadingAndSubHeading.stepSubHeadingFontSize} onInput={(value) => dispatch(setDesignOptions({ key: "stepHeadingAndSubHeading", data: { stepSubHeadingFontSize: value } }))} prefix={"pixels"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Step Sub-Heading Font Size Mobile
                            <div className='ms-2'>
                                <ToolTip text={`The sub-header text is an optional line of text that can appear below the question text of your form. This setting adjusts the size of the sub-header text on mobile only.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  `}>
                            <InputPrefix prefix={"pixels"} />


                        </div>

                    </div>

                </TopBottomPadding>
                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Step Sub-Heading Font Weight

                        </CollapsedAreaLabels>
                        <div className={`itemsPerform  w-50`}>
                            <CustomSelect defaultValue={designOptions.stepHeadingAndSubHeading.stepSubHeadingFontWeight} onChange={(selected) => dispatch(setDesignOptions({ key: "stepHeadingAndSubHeading", data: { stepSubHeadingFontWeight: selected } }))} data={StepheadlineFontWeight} name={"StepheadlineFontWeight"} />


                        </div>

                    </div>

                </TopBottomPadding>
            </>}
        </>
    );
}

export default StepHeadingAndSubHeading;