import React, { useState } from 'react';
import { CommonArea, TopBottomPadding, CollapsedAreaLabels } from 'Components/Common';
import BackgroundImage from "Components/BackgroundImage";
import ColorPicker from "Components/ColorPicker";
import CustomSelect from "Components/CustomSelect";
import ToolTip from 'Components/Tooltip';
import { BiChevronRight } from "react-icons/bi"
import { useSelector, useDispatch } from 'react-redux';
import { setDesignOptions } from "Store/slices/EditFormSlice"


const TemplateStyle = () => {
    const dispatch = useDispatch();
    const designOptions = useSelector<AppStore, FormsDesignOptions>(state => state.editor.designOptions);

    const [TemplateStyle, setTemplateStyle] = useState([
        { value: 'EnhancedForm', label: 'Enhanced Form' },
        { value: 'ChatStyle', label: 'Chat Style' },
    ]);
    const [showBgImg, setShowBgImg] = useState(false);
    return (
        <>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Choose Tempalte Style:

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform w-50 `}>
                        <CustomSelect defaultValue={designOptions.templateStyle.templateStyle} onChange={(selected) => dispatch(setDesignOptions({ key: "templateStyle", data: { templateStyle: selected } }))} data={TemplateStyle} name={"TemplateStyle"} />


                    </div>

                </div>

            </TopBottomPadding>
            {designOptions.templateStyle.templateStyle.value === "EnhancedForm" &&
                <>
                    <TopBottomPadding>

                        <div className='d-flex justify-content-between align-items-center'>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                Background Color
                                <div className='ms-2'>
                                    <ToolTip text={"You can change the background color of your form by adjusting the bottom background color setting."} />
                                </div>
                            </CollapsedAreaLabels>
                            <div className={`itemsPerform `}>
                                <ColorPicker getColor={(color) => {

                                    dispatch(setDesignOptions({ key: "templateStyle", data: { templateBgColor: color } }))
                                }} defaultValue={designOptions.templateStyle?.templateBgColor} />


                            </div>

                        </div>

                    </TopBottomPadding>
                    <TopBottomPadding>

                        <div onClick={() => setShowBgImg(!showBgImg)} className='d-flex justify-content-between align-items-center'>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                Background Image
                                <div className='ms-2'>
                                    <ToolTip text={`To add a background image to your form, simply expand the field and upload an image and click Save. NOTE!! To see the image you may have to adjust the transparency of your bottom background color.`} />
                                </div>
                            </CollapsedAreaLabels>
                            <div className={`itemsPerform  `}>

                                <CommonArea className={`${showBgImg === true ? "rotateit" : ""}`}>
                                    <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                                </CommonArea>
                            </div>

                        </div>

                    </TopBottomPadding>
                    {
                        showBgImg === true ?
                            <TopBottomPadding>
                                <BackgroundImage getimg={(img) => dispatch(setDesignOptions({ key: "templateStyle", data: { templateBgimg: img } }))} preview={designOptions.templateStyle.templateBgimg} />
                            </TopBottomPadding>
                            : null
                    }
                </>
            }
        </>
    );
}

export default TemplateStyle;