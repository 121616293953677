import { CollapseTrigger, CommonArea, TopBottomPadding } from 'Components/Common';
import { useState } from 'react';
import styled from 'styled-components';

import LoadingButton from '@mui/lab/LoadingButton';
import { BiChevronRight } from "react-icons/bi";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const CollapseTriggerHeading = styled.div`
font-size: 17px;

`
const CollapsedArea = styled.div`
padding: 0px 35px;
    position: relative;
    font-size: 16px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
    background: rgb(247, 247, 247);
`

const ScriptDiv = styled.div`
padding: 8px 15px;
    font-size: 14px;
    overflow: hidden;
    white-space: pre-wrap;
    position: relative;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-weight:600
`


const PixelScript = () => {
    const [showDiv, setShowDiv] = useState(false);
    const [copying, setCopying] = useState(false);

    const { formId } = useParams();
    const code = `
<script src="${window.location.origin}/cdn-lib.js" type="text/javascript"></script>
<script type="text/javascript">
    const cdn = new LeadPageMagnet({
        formRef: '${formId}' 
    });
    cdn.Load(document.getElementById('lead-page-root-element'));
</script>`;

    return (
        <>
            <CollapseTrigger cursor='default' onClick={() => setShowDiv(!showDiv)}>
                <TopBottomPadding>
                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapseTriggerHeading className='d-flex '>
                            Pixel Script (Required)
                        </CollapseTriggerHeading>

                        <CommonArea className={`${showDiv === true ? 'rotateit' : ''}`}>
                            <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                        </CommonArea>

                    </div>
                </TopBottomPadding>
            </CollapseTrigger>
            {showDiv === true ? <CollapsedArea id={"example-collapse-text_ss"}>
                <TopBottomPadding>
                    <p>LeadForms Pixel:</p>
                    <p className='small'>{`Add the code to all pages of your website in the <head> section:`}</p>
                    <ScriptDiv className='mb-2'>{code.trim()}</ScriptDiv>
                    <ScriptDiv className='mb-3'>
                        <LoadingButton
                            variant='contained'
                            color='primary'
                            loading={copying}
                            onClick={async e => {
                                setCopying(true);
                                await navigator.clipboard.writeText(code);
                                setCopying(false);
                                toast('Copied');
                            }}>Copy Code</LoadingButton>
                    </ScriptDiv>
                </TopBottomPadding>

            </CollapsedArea>
                : null}
        </>
    );
}

export default PixelScript