import { AxiosLite, CallAxios } from 'Helpers';
import { config } from 'Helpers/Config';
import React, { useState, useRef, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const BackgroundImage = (props: {
    recommendedSize?: string,
    preview?: string,
    getimg: (path: string) => void
}) => {
    const { formId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fileinput = useRef<HTMLInputElement>(null);
    // const [selectedFile, setSelectedFile] = useState<File>();
    const [preview, setPreview] = useState<string | null>(props.preview || null);

    const UploadFileBtn = () => {
        if (!!fileinput.current) {
            fileinput.current.click();
        }
    }

    const readFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            //setSelectedFile(undefined)
            setPreview(null)
            return;
        }
        const newFile = e.target.files[0];
        if (newFile && formId) {
            const formData = new FormData();
            formData.append('file', newFile);
            formData.append('formId', formId);
            CallAxios('/api/General/SaveImage|POST', formData, undefined, undefined, navigate, dispatch)
                .then(res => {
                    if (res?.data.success
                        && res.data.data) {
                        props.getimg(res.data.data);
                    }
                });
        }
        //setSelectedFile(newFile)
        //const reader = new FileReader();
        //reader.addEventListener("load", () => {
        //setPreview(reader.result);
        //if (props.getimg && reader.result) {
        //props.getimg(reader.result)
        //}
        //});
        //reader.readAsDataURL(newFile);
    }

    useEffect(() => {
        if (props.preview) {
            setPreview(props.preview)
        }
    }, [props.preview]);

    const clearEverything = () => {
        confirmAlert({
            closeOnClickOutside: false,
            closeOnEscape: true,
            title: 'Are you sure?',
            message: `This will remove the image.\r\nPlease make sure to save changes after deleting the image else you may face unexpected issues.`,
            buttons: [
                {
                    label: 'Yes',
                    className: 'bg-danger',
                    onClick: _ => {
                        if (preview?.startsWith('data:')) {
                            setPreview(null);
                            props.getimg("");
                            return;
                        }
                        setPreview(null)
                        if (props.getimg) {
                            AxiosLite('/api/General/DeleteImage|DELETE', { formId: formId, path: preview }, navigate, dispatch)
                                .then(res => {
                                    if (res?.data.success && res?.data.data) {
                                        props.getimg("");
                                    }
                                });
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: _ => {
                        return;
                    }
                }
            ]
        });
    }

    return (
        <div className='row'>
            <div className='col-md-10'>
                <div className='row'>
                    <div className='col-4'>
                        {
                            !!preview ?
                                <GreyBtn onClick={clearEverything}>
                                    Clear
                                </GreyBtn> : <GreyBtn onClick={UploadFileBtn}>
                                    Upload
                                </GreyBtn>
                        }

                    </div>
                    {/* {
                        selectedFile && <div className='col-4'>
                            <Filenametext title={selectedFile.name}>
                                {selectedFile.name}
                            </Filenametext>
                        </div>
                    } */}
                </div>
                <input type="file" className='d-none' onChange={(e) => readFile(e)} id="upload-btn" ref={fileinput} />
                {!!props.recommendedSize ? <SmallText>{props.recommendedSize}</SmallText> :
                    <SmallText className="sc-jKVCRD hOFgPf">Minimum recommended size: 975X575px.<br />*You may need to adjust the background color transparency settings to make the background visible.</SmallText>}
            </div>
            <div className='col-md-2'>
                <PreviewDiv>
                    {!!preview ? <Image src={config.ICON_IMAGES_URL + formId + '/' + preview?.toString()} /> : null}
                </PreviewDiv>
            </div>
        </div>
    );
}

export default BackgroundImage;

const GreyBtn = styled.label`
        display: block;
        border: 0px;
        text-align: center;
        border-radius: 5px;
        box-shadow: rgb(0 0 0 / 40%) 0px 2px 3px;
        text-transform: none;
        cursor: pointer;
        font-size: 12px;
        word-spacing: 0.1em;
        transition: all 0.1s ease-in-out 0s;
        background-color: rgb(236, 236, 236);
        color: rgb(102, 102, 102);
        padding: 7px 12px;
        outline: none !important;
    &:hover{
        background-color: rgb(153, 153, 153);
        color: rgb(255, 255, 255);
    }
    `;
const SmallText = styled.div`
    font-size: 12px;
    color: rgb(187, 187, 187);
    margin-top: 10px;
    line-height: 14px;
    `;
const PreviewDiv = styled.div`
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 25px;
    background: rgb(238, 238, 238);
    overflow: hidden;
    `;
const Image = styled.img`
    width: 50px;
    display: block;
    `;
// const Filenametext = styled.div`
//         font-size: 14px;
//         color: rgb(153, 153, 153);
//         white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     `;