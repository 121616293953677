const production = process.env.NODE_ENV !== 'development';

export const config = {
    production,
    API_URL: process.env.REACT_APP_API_URL || '',
    ICON_IMAGES_URL: process.env.REACT_APP_API_URL + '/FormIcons/',
    TOKEN_URL: process.env.REACT_APP_TOKEN_URL || '',
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID || '',
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET || '',
    GRANT_TYPE: process.env.REACT_APP_GRANT_TYPE || '',
    RESPONSE_TYPE: process.env.REACT_APP_RESPONSE_TYPE || '',
}