import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Cardheader } from 'Components/Common';
import { CallAxios } from 'Helpers';
import { SignInManager } from 'Helpers/Authentication/SignInManager';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const AddUser = (props: {
    justMyProfile: boolean
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userId } = useParams();
    const myId = new SignInManager().ParseJwt.sub;

    const { register, handleSubmit, reset, formState: { errors }, control } = useForm<UserModel>({
        defaultValues: {
            role: 'Client'
        },
        shouldFocusError: true,
        mode: 'all',
        resolver: yupResolver(!!userId || (props.justMyProfile && myId) ? userSchemaNoPassword : userSchema)
    });

    useEffect(() => {
        if (userId || (props.justMyProfile && myId))
            CallAxios('/api/Clients/GetById|GET',
                undefined,
                {
                    id: userId || myId
                },
                undefined,
                navigate,
                dispatch).then(res => {
                    if (res?.data.success && res?.data.data) {
                        reset({ ...res.data.data, password: '' })
                        //setValue([{ email: '' }]);
                    } else {
                        toast(res?.data.message, { type: 'error' });
                    }
                });
    }, [userId, props.justMyProfile]);
    return (
        <Card style={{ boxShadow: '0px 1px 15px 1px rgb(69 65 78 / 8%)' }}>
            <Cardheader>
                <Typography>
                    {props.justMyProfile ? 'My Profile' : 'Add/Edit sub user'}
                </Typography>
                <div>
                    <Button className='text-capitalize' variant="contained" color='primary' onClick={(e) => navigate(-1)}>Back</Button>
                </div>
            </Cardheader>
            <CardContent>
                <form className='row' onSubmit={handleSubmit(async v => {
                    const res = await CallAxios('/api/Clients/Save|POST',
                        v,
                        undefined,
                        undefined,
                        navigate,
                        dispatch);
                    if (res?.data.success) {
                        toast(res.data.message);
                        navigate(-1);
                    } else {
                        toast(res?.data.message, { type: 'error' });
                    }
                })}>
                    <div className=' mt-3 col-md-6'>
                        <FormControl fullWidth>
                            <TextField {...register('name')} label='Name' autoComplete='name' InputLabelProps={{ shrink: true }} />
                            <ErrorMessage name="name" errors={errors} render={({ message }) => <span className='text-danger'>{message}</span>} />
                        </FormControl>
                    </div>
                    <div className='mt-3 col-md-6'>
                        <FormControl fullWidth>
                            <TextField {...register('email')} label='Email' autoComplete='email' InputLabelProps={{ shrink: true }} />
                            <ErrorMessage name="email" errors={errors} render={({ message }) => <span className='text-danger'>{message}</span>} />
                        </FormControl>
                    </div>
                    <div className='mt-3 col-md-6'>
                        <FormControl fullWidth>
                            <TextField {...register('phone')} label='Phone' type={'tel'} autoComplete='tel' InputLabelProps={{ shrink: true }} />
                            <ErrorMessage name="phone" errors={errors} render={({ message }) => <span className='text-danger'>{message}</span>} />
                        </FormControl>
                    </div>
                    {
                        (!props.justMyProfile) &&
                        <div className='mt-3 col-md-6'>
                            <FormControl fullWidth>
                                <TextField {...register('password')} label='Password' type={'password'} autoComplete='new-password' InputLabelProps={{ shrink: true }} />
                                <ErrorMessage name="password" errors={errors} render={({ message }) => <span className='text-danger'>{message}</span>} />
                            </FormControl>
                            {/* {
                            (userId || props.justMyProfile) &&
                            <p className='font-12 text-danger'>Leave blank if you want to use same password.</p>
                        } */}
                        </div>
                    }


                    {new SignInManager().UserRole == 'Admin' && !props.justMyProfile &&
                        <div className='mt-3 col-md-6'>
                            <FormControl fullWidth>
                                <InputLabel>Role</InputLabel>
                                <Controller control={control} name='role' render={({ field }) =>
                                    <Select label='Role' {...field} >
                                        <MenuItem value="">Please Select</MenuItem>
                                        <MenuItem value="Admin">Admin</MenuItem>
                                        <MenuItem value="Client">Client</MenuItem>

                                    </Select>
                                } />
                                <ErrorMessage name="role" errors={errors}
                                    render={({ message }) => <span className='text-danger'>{message}</span>}
                                />
                            </FormControl>
                        </div>}
                    {new SignInManager().UserRole == 'Client' && !props.justMyProfile &&
                        <div className='mt-3 col-md-6'>
                            <FormControl fullWidth>
                                <InputLabel>Role</InputLabel>
                                <Controller control={control} name='role' render={({ field }) =>
                                    <Select label='Role' {...field}>
                                        <MenuItem value="">Please Select</MenuItem>
                                        <MenuItem value="Agent">Agent</MenuItem>
                                    </Select>
                                } />
                                <ErrorMessage name="role" errors={errors}
                                    render={({ message }) => <span className='text-danger'>{message}</span>}
                                />
                            </FormControl>
                        </div>}

                    <div className='mt-3 col-md-6'>
                        <FormControl fullWidth>
                            <TextField {...register('address')} label='Address' autoComplete="street-address" InputLabelProps={{ shrink: true }} />
                            <ErrorMessage name="address" errors={errors}
                                render={({ message }) => <span className='text-danger'>{message}</span>}
                            />
                        </FormControl>
                    </div>
                    <div className='mt-3 text-center'>
                        <Button
                            variant='contained'
                            color="primary"
                            type='submit'>
                            {
                                false ? <>
                                    <RotatingLines width='20' /> Submitting...</>
                                    : 'Submit'
                            }
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    );
}

export default AddUser;

const userSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().required('Required').email('In-Valid Email'),
    password: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
});
const userSchemaNoPassword = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().required('Required').email('In-Valid Email'),
    phone: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
});