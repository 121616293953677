import { useFormik } from 'formik';
import { CallAxios, FocusOnView } from 'Helpers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { attachResponseToStep, setAddEditResponseWindow } from 'Store';
import * as Yup from 'yup';
import { BlueBtn, CancelBtn } from "Components/Common";
import AddEditResponses from './AddEditResponse';
import AddEditResponseIcon from './AddIcon';
import ResponseList from './ResponsesList';

export const ResponseMain = () => {
  const { id, formId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const steps = useSelector((state: AppStore) => state.editor.steps);

  const addResponse = useSelector((state: AppStore) => state.editor.responseOpen);
  const [addicon, setAddicon] = useState(false);

  const [schemaIndex, setschemaIndex] = useState(0);
  const SchemaArr = [responseFieldSchema, responseFieldSchema1];
  const [currentResponseId, setCurrentResponseId] = useState<string>('');
  const [iconData, setIconData] = useState<any>("");

  const formik = useFormik<FormStepResponses>({
    initialValues: {
      isLinkButton: false,
      isPhoneButton: false,
      isAccountEmail:false,
      formId: String(formId),
      stepId: String(id),
      targetStepId: undefined,
      variableName: '',
      fieldLabel: '',
      fieldType: 'Text',
      fieldValue: '',
      iconData: undefined,
      isRequired: false,
      linkButton: '',
      personallyIdentifiableInformation: false,
      phoneButton: '',
      responseOrFieldName: '',
      displayOrder:0,
    },
    validationSchema: SchemaArr[schemaIndex],
    onSubmit: values => {
      if (id) {
        dispatch(setAddEditResponseWindow(false));
        CallAxios('/api/Response/SaveResponse|POST',
          {
            ...values,
            sliderData: '{}',
            stepId: id,
            iconData: typeof values.iconData == 'object' ? JSON.stringify(values.iconData) : values.iconData
          },
          undefined,
          undefined,
          navigate,
          dispatch
        ).then(res => {
          toast(res?.data?.message, { type: res?.data.success ? 'success' : 'warning' });
          dispatch(attachResponseToStep({ stepId: id, newResponse: res?.data.data }));
          formik.resetForm();
        });
      }
    },
  });

  useEffect(() => {
    if (formik.values.isPhoneButton && formik.values.isLinkButton) {
      setschemaIndex(0)
    } else {
      setschemaIndex(1)

    }
  }, [formik.values.isPhoneButton, formik.values.isLinkButton]);




  return (
    <div className='responseWrapper text-start'>
      <ResponseList setIconData={setIconData} setCurrentResponseId={setCurrentResponseId} setAddicon={setAddicon} setAddResponse={e => dispatch(setAddEditResponseWindow(e))} list={steps?.find(x => String(x.id) === String(id)) || {}} formik={formik} />
      {addResponse && <AddEditResponses formik={formik} />}
      {addicon && <AddEditResponseIcon iconData={iconData} responseID={currentResponseId} setAddicon={setAddicon} />}
      {addicon === false && <div className='px-3'>
        {
          addResponse ?
            <div className='d-flex justify-content-between align-items-center px-5'>
              <BlueBtn onClick={() => {
                formik.handleSubmit();
                FocusOnView('responseWrapper');
              }}
                className="me-3">Save response</BlueBtn>
              <CancelBtn onClick={() => {
                formik.resetForm();
                dispatch(setAddEditResponseWindow(false));
                setAddicon(false);
                FocusOnView('responseWrapper');
              }}>Cancel</CancelBtn>
            </div>
            :
            <div className='mt-2'>
              <BlueBtn onClick={() => {
                dispatch(setAddEditResponseWindow(true));
                FocusOnView('editor_main');
              }}>+ Add response</BlueBtn>
            </div>
        }
      </div>
      }
    </div>
  );
}

const responseFieldSchema = Yup.object().shape({
  responseOrFieldName: Yup.string().required('This field may not be blank.'),
  variableName: Yup.string().required('This field may not be blank.'),
});
const responseFieldSchema1 = Yup.object().shape({
  responseOrFieldName: Yup.string().required('This field may not be blank.'),
});