import { CollapseTrigger, CommonArea, TopBottomPadding } from 'Components/Common';
import { useState } from 'react';
import styled from 'styled-components';

import ToolTip from 'Components/Tooltip';
import { BiChevronRight } from "react-icons/bi";

import MaterialDesignSwitch from "Components/MaterialSwitch";

import FormFloatingLabel from "Components/FloatingInput";
import { FieldArray, FormikProvider } from 'formik';

const CollapseTriggerHeading = styled.div`
font-size: 17px;

`
const CollapsedArea = styled.div`
padding: 0px 35px;
    position: relative;
    font-size: 16px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
    background: rgb(247, 247, 247);
`
const RemoveBtn = styled.span`
border: none;
border-radius: 3px;
text-align: center;
color: rgb(255, 255, 255);
background: rgb(251, 104, 93);
cursor: pointer;
height: 27px;
font-weight: 500;
font-size: 15px;
line-height: 27px;
width: 27px;

`
const BlueBtn = styled.span`
border: none;
border-radius: 3px;
text-align: center;
color: rgb(255, 255, 255);
background: rgb(65, 112, 251);
cursor: pointer;
height: 27px;
font-weight: 500;
font-size: 15px;
line-height: 27px;
width: 51px;

`

const Targetpages = (props: { formik: any; }) => {
    const { formik } = props;
    const [showDiv, setShowDiv] = useState(false);
    const [showonallpage, setShowonallpage] = useState(false);
    return (
        <>
            <FormikProvider value={formik}>
                <CollapseTrigger cursor='default' onClick={() => setShowDiv(!showDiv)}>
                    <TopBottomPadding>
                        <div className='d-flex justify-content-between align-items-center'>
                            <CollapseTriggerHeading className='d-flex '>
                                Target Pages (where should it appear?)
                            </CollapseTriggerHeading>

                            <CommonArea className={`${showDiv === true ? 'rotateit' : ''}`}>
                                <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                            </CommonArea>

                        </div>
                    </TopBottomPadding>
                </CollapseTrigger>
                {showDiv === true ? <CollapsedArea id={"example-collapse-text_ss"}>
                    <TopBottomPadding>

                        <CollapseTriggerHeading className='d-flex mb-3'>

                            <div className='me-2'>

                                <ToolTip text={`  Include the "*" character at the end to display the form on any sub-path.`} />

                            </div>
                            <span className="small"> Include the "*" character at the end to display the form on any sub-path.</span>
                        </CollapseTriggerHeading>
                        <FieldArray
                            name="url"
                            render={arrayHelpers => (
                                formik.values.url.map((item: any, index: any) =>
                                    <div className='row mb-3' key={index}>
                                        <div className='col-md-10'>
                                            <FormFloatingLabel onInput={(value) => formik.setFieldValue(`url.${index}`, value)} label={"URL"} defaultValue={formik.values.url[index]} />
                                        </div>
                                        <div className='col-md-2 px-0 align-self-center'>
                                            <div className='d-flex align-items-center'>
                                                {formik.values.url.length === index + 1 && <BlueBtn onClick={() => arrayHelpers.insert(0, "")}>+ URL</BlueBtn>}
                                                {formik.values.url.length === index + 1 && index !== 0 && <RemoveBtn className='removeBtn ms-1' onClick={() => arrayHelpers.remove(index)}>-</RemoveBtn>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        />
                        {/* <div className='row mb-3'>
                    <div className='col-8'>
                        <FormFloatingLabel label={"URL"} defaultValue={""} />
                    </div>
                    <div className='col-4 d-flex align-items-center justify-content-center'>
                        <button className='btn btn-primary btn-sm'>+ URL</button>
                    </div>
                </div> */}
                        <div className='mb-2'>
                            <p className="mb-2">Show on all sub pages:</p>
                            <MaterialDesignSwitch defaultchecked={formik.values.showOnAllSubPage} id={"showonallpage"} checked={(checked) => formik.setFieldValue("showOnAllSubPage", checked)} />

                        </div>
                    </TopBottomPadding>

                </CollapsedArea>
                    : null}
            </FormikProvider>
        </>
    );
}

export default Targetpages