import { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from '@mui/material/Button';
import { Cardheader } from 'Components/Common';
import { Link, useNavigate } from 'react-router-dom';
import { CallAxios } from 'Helpers';
import { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { BiEditAlt } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, CardContent, Typography } from '@mui/material';

const Subusers = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [list, setList] = useState<UserModel[]>();

    useEffect(() => {
        CallAxios('/api/Clients/ListUsers|GET',
            undefined,
            undefined,
            undefined,
            navigate,
            dispatch)
            .then(res => {
                setList(res?.data?.list || []);
            });
    }, []);
    const deleteUser = (v: UserModel) => {
        confirmAlert({
            closeOnEscape: true,
            message: `Do you really want to delete user with email '${v.email}'?\r\nThis action can't be undone.`,
            closeOnClickOutside: false,
            title: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes, Delete',
                    className: 'bg-danger',
                    onClick: () => {
                        CallAxios('/api/Clients/DeleteUser|DELETE',
                            undefined,
                            {
                                id: v.id
                            },
                            undefined,
                            navigate,
                            dispatch).then(res => {
                                toast(`User '${v.email}' deleted.`);
                                const newList = list || [];
                                newList.splice(newList.indexOf(v), 1);
                                setList([...newList]);
                            })
                    }
                },
                {
                    label: 'No, Cancel',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }
    return (
        <Card style={{ boxShadow: '0px 1px 15px 1px rgb(69 65 78 / 8%)' }}>
            <Cardheader>
                <Typography>
                    {list?.length || 0} of {list?.length || 0} Sub Users
                </Typography>
                <div>
                    <Button className='text-capitalize' variant="contained" color='primary' onClick={(e) => navigate("/adduser")}>Add Sub User</Button>
                </div>
            </Cardheader>
            <CardContent>
                <Row>
                    <Col className='pt-1' md={12}>
                        <Table striped className='border'>
                            <thead>
                                <tr>
                                    <th>Sr.</th>
                                    <th>Name</th>
                                    <th>Role</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list?.map((v, i) => (
                                        <tr key={'sub_user_' + i}>
                                            <td>{i + 1}</td>
                                            <td>{v.name}</td>
                                            <td>{v.role}</td>
                                            <td>{v.email}</td>
                                            <td>{v.phone}</td>
                                            <td><Badge bg={v.isActive ? 'success' : 'danger'} pill text='white'>{v.isActive ? 'Active' : 'In-Active'}</Badge></td>
                                            <td>
                                                <p>
                                                    <Link className='text-success me-2' to={'/adduser/' + v.id}>
                                                        <BiEditAlt style={{ fontSize: 24 }} />
                                                    </Link>
                                                    <MdDeleteForever className='cursorPointer text-danger' style={{ fontSize: 24 }} onClick={e => deleteUser(v)} />
                                                </p>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

            </CardContent>
        </Card>
    );
}

export default Subusers;