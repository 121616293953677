export const ParseObject = <T extends {}>(obj?: string | T) => {
    if (obj && typeof obj == 'string') {
        return JSON.parse(obj);
    }
    else {
        return obj;
    }
}

export const ExecuteParsers = (obj: FormStepResponses) => {
    const newObj = Object.assign({}, obj);
    if (newObj?.sliderData) {
        newObj.slider = typeof obj.sliderData == 'string' ? JSON.parse(obj.sliderData) : obj.sliderData;
    }
    if (newObj?.iconData) {
        newObj.iconData = typeof obj.iconData == 'string' ? JSON.parse(obj.iconData) : obj.iconData;
    }
    return newObj;
}