import Tooltip from '@mui/material/Tooltip';
import { defaultstepOptions } from "commonJSON";
import { reorder } from "Components/Common";
import { CallAxios } from 'Helpers';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { FaBars } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { reOrderSteps, setActivenavbar, setActiveStep, setStepOptions } from "Store/slices/EditFormSlice";
import styled from 'styled-components';

const StepsView = () => {
    const { formId, id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const StepsList = useSelector((state: AppStore) => state.editor.steps);

    const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items: any[] = [];
        reorder(
            StepsList,
            result.source.index,
            result.destination.index
        ).forEach((v, i) => {
            items.push({ ...v, order: i + 1 });
        });
        dispatch(reOrderSteps(items))
        await CallAxios('/api/Steps/UpdateStepOrder|POST',
            items.map(s => s.id),
            undefined,
            undefined,
            navigate,
            dispatch);
    }

    const addNewStep = () => {
        dispatch(setStepOptions({ ...defaultstepOptions, formId: String(formId) }));
        dispatch(setActiveStep({ ...defaultstepOptions, formId: String(formId) }));
        dispatch(setActivenavbar('Steps'));
        navigate(`/editForm/${formId}`);
    }

    return (
        <div className='addBodyPadding ps-0 sticky-sidebar'>
            <StepsWrapper>
                <Heading>List of Steps</Heading>
                <div className='listWrapper mt-2'>
                    <div className='overflow-auto'>
                        <div className='p-0 m-0 addBorderBottomonList'>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            key={"ab__" + snapshot.draggingFromThisWith + snapshot.isDraggingOver}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}>
                                            {StepsList?.map((item, index) => (
                                                <Draggable key={"abc__" + item.id + item.stepOrder}
                                                    draggableId={item.id?.toString() || ''}
                                                    index={index}>
                                                    {(provided, _) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            className={"ListItem " + (item.id === String(id) ? 'active' : '')}
                                                            {...provided.draggableProps}>
                                                            <Tooltip title={item?.questionText || item?.formTitle} arrow placement="right">
                                                                <ListItem className='row mx-0' onClick={() => navigate(`/editForm/${formId}/${item?.id}`)}>
                                                                    <div className='col-2 px-0'>
                                                                        <span className='DragHandlers' {...provided.dragHandleProps}>
                                                                            <FaBars fontSize={14} />
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-8'>{item?.name}</div>
                                                                    <div className='col-2'>
                                                                        <MdModeEditOutline className='editicos' />
                                                                    </div>
                                                                </ListItem>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                    <div className='p-2 text-center'>
                        <AddStepBtn onClick={addNewStep}>Add step +</AddStepBtn>
                    </div>
                </div>
            </StepsWrapper>
            <Instruction>*Changing the order of the steps in the list does not change order of the live form.</Instruction>
        </div>
    );
}

export default StepsView;

const StepsWrapper = styled.div`
background-color:#fff;
`
const Heading = styled.div`
font-weight: bold;
font-size: 18px;
margin: 0px 0px 0px 15px;
padding-top: 10px;`

const ListItem = styled.li`


`
const AddStepBtn = styled.a`
color: rgb(11, 123, 227);
cursor: pointer;
transition: all 0.5s ease 0s;
display: inline-block;
border: 1px dashed rgb(89, 129, 252);
padding: 7px 10px;
text-decoration:none;
&:hover{
    transform: scale(1.1);

}
`
const Instruction = styled.p`
color: rgb(119, 118, 118);
margin-top: 10px;
text-align: center;
font-size: 15px;
`