import { CallAxios } from "Helpers";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPaperPlane } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


function SendSms(props: {
    templates: ESTemplates[],
    phone?: string
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { templates, phone } = props;

    const { register, handleSubmit, setValue } = useForm<SmsModel>({
        defaultValues: {
            phone: phone
        }
    });

    useEffect(() => {
        setValue('phone', phone || '');
    }, [phone]);
    return (
        <form onSubmit={handleSubmit(v => {
            CallAxios('/api/CRM/SendSms|POST', v, undefined, undefined, navigate, dispatch)
                .then(s => {
                    toast(s?.data.message, { type: s?.data.success ? 'success' : 'error' });
                });
        })}>
            <div className="send_sms_tab">
                <div className="row">
                    <div className="col-md-6">
                        <input type="tel" placeholder="Phone" className="form-control" {...register('phone', { required: true })} />
                    </div>
                    <div className="col-md-6">
                        <select className="form-select mb-4"
                            onChange={e => {
                                setValue('content', e.target.value);
                            }}>
                            <option value="">Please Select Template</option>
                            {
                                templates.filter(s => s.templateType.toLowerCase() === 'sms' && s.published).map((v, i) =>
                                    <option key={'tem1_' + i} value={v.content}>{v.name}</option>
                                )
                            }
                        </select>
                        <span className="ValSmsTemp text-danger d-none">Required</span>
                    </div>
                </div>

                <div className="show_textarea ">
                    <textarea className="form-control"
                        {...register('content', {
                            required: true
                        })}
                        rows={10}
                        placeholder="Write Your SMS Here"></textarea>
                    <span className="ValSmsBody text-danger d-none">Required</span>
                </div>
                <div className="text-end mt-3">
                    <button className="btn btn-success  BtnSendSms"> <FaPaperPlane className="me-2" /> SEND</button>
                </div>

            </div>
        </form>
    );
}

export default SendSms;