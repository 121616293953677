import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from 'Components/ColorPicker';
import CustomSelect from 'Components/CustomSelect';
import MaterialDesignSwitch from 'Components/MaterialSwitch';
import SliderTypeNumeric from './SliderTypeNumeric';
import SliderTypeOptions from './SliderTypeOptions';

const SliderSettings = (props: {
    formik: FormikType<FormStepResponses>
}) => {
    const formik = props.formik;

    const SliderValuetype = [
        { value: "OptionsList", label: "Options List" },
        { value: "Numeric", label: "Numeric" }
    ];
    return (
        <div>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Show field label?

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch
                            defaultchecked={formik.values.slider?.Show_field_label}
                            id={"showFieldLabel"}
                            checked={(checked) => formik.setFieldValue('slider.Show_field_label', checked)} />
                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Label Color
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker
                            getColor={(color) => formik.setFieldValue('slider.labelColor', color)}
                            defaultValue={formik.values.slider?.labelColor} />
                    </div>
                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Slider Bar Color
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker
                            getColor={(color) => formik.setFieldValue('slider.sliderbarColor', color)}
                            defaultValue={formik.values.slider?.sliderbarColor} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Slider Value Color
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker
                            getColor={(color) => formik.setFieldValue('slider.sliderValueColor', color)}
                            defaultValue={formik.values.slider?.sliderValueColor} />
                    </div>
                </div>
            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Slider Value Type
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform w-50`}>
                        <CustomSelect data={SliderValuetype}
                            onChange={(selected) => formik.setFieldValue("slider.sliderValueType", selected?.value)}
                            defaultValue={SliderValuetype.find(x => x.value == formik.values.slider?.sliderValueType)}
                            name={"SliderValuetype"}
                            id={"SliderValuetype"} />
                    </div>

                </div>

            </TopBottomPadding>
            {formik.values.slider?.sliderValueType === "OptionsList" ? <SliderTypeOptions formik={formik} /> : <SliderTypeNumeric formik={formik} />}

        </div>
    );
}

export default SliderSettings;