export const DesignOptions = [
    {
        label: "Template Style",
        tooltip: { hasTooltip: true, title: "You have two types of design styles to choose from. 1) Chat style: This template is highly personalized and looks and feels more like a chat. 2) Enahnced Form: This is more like a standard multi step form with additional styling capabilities." },
        isOpen: false,

    },
    {
        label: "Header",
        tooltip: { hasTooltip: true, title: `The header is typically a large white block that spans across the top of the form. It typically includes text like "Get a free quote", "free consultation", etc... You can disable this block of text from appearing by clicking "hide header"` },
        isOpen: false,

    },
    {
        label: "Step Heading and Sub-Heading",
        tooltip: { hasTooltip: true, title: "This adjusts the width of the onsite message that appears in the bottom right or bottom left of the page." },
        isOpen: false,

    },
    {
        label: "Response Styling",
        tooltip: { hasTooltip: true, title: "You have two types of design styles to choose from. 1) Chat style: This template is highly personalized and looks and feels more like a chat. 2) Enahnced Form: This is more like a standard multi step form with additional styling capabilities." },
        isOpen: false,

    },
    {
        label: "Loading spinner",
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    },
    {
        label: `"Call us" bar`,
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    },
    {
        label: "Back button",
        tooltip: { hasTooltip: true, title: "Enable this feature if you'd like to add a back button to your form." },
        isOpen: false,

    },
    {
        label: "Onsite Message",
        tooltip: { hasTooltip: true, title: "This adjusts the width of the onsite message that appears in the bottom right or bottom left of the page." },
        isOpen: false,

    },
    {
        label: "Lead form dimensions",
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    },
    {
        label: "Offer Headline",
        tooltip: { hasTooltip: true, title: `The offer headline works for the "chat style form" only. The headline is a line of text that will appear at the top of the form. We recommend using this text because it reminds the prospect why they are completing your form in the first place. Examples: "Get a free quote", "Free Consultation", "Speak with an Expert", "Save 25% Today!"` },
        isOpen: false,

    },
    {
        label: "Submit Button",
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    },
    {
        label: `"Thank You" Step`,
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    },
    {
        label: "Progress Bar",
        tooltip: { hasTooltip: true, title: "With this feature you can either enable or disable a progress bar. If enabled, you'll see the progress bar start on step two of your forms." },
        isOpen: false,

    },
    {
        label: "Other Font Styling",
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    },
];

export const DesignOptionsChatStyle = [
    {
        label: "Template Style",
        tooltip: { hasTooltip: true, title: "You have two types of design styles to choose from. 1) Chat style: This template is highly personalized and looks and feels more like a chat. 2) Enahnced Form: This is more like a standard multi step form with additional styling capabilities." },
        isOpen: false,

    },

    {
        label: "Step Heading and Sub-Heading",
        tooltip: { hasTooltip: true, title: "This adjusts the width of the onsite message that appears in the bottom right or bottom left of the page." },
        isOpen: false,

    },
    {
        label: "Response Styling",
        tooltip: { hasTooltip: true, title: "You have two types of design styles to choose from. 1) Chat style: This template is highly personalized and looks and feels more like a chat. 2) Enahnced Form: This is more like a standard multi step form with additional styling capabilities." },
        isOpen: false,

    },

    {
        label: `"Call us" bar`,
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    },
    {
        label: "Back button",
        tooltip: { hasTooltip: true, title: "Enable this feature if you'd like to add a back button to your form." },
        isOpen: false,

    },
    {
        label: "Onsite Message",
        tooltip: { hasTooltip: true, title: "This adjusts the width of the onsite message that appears in the bottom right or bottom left of the page." },
        isOpen: false,

    },

    {
        label: "Offer Headline",
        tooltip: { hasTooltip: true, title: `The offer headline works for the "chat style form" only. The headline is a line of text that will appear at the top of the form. We recommend using this text because it reminds the prospect why they are completing your form in the first place. Examples: "Get a free quote", "Free Consultation", "Speak with an Expert", "Save 25% Today!"` },
        isOpen: false,

    },
    {
        label: "Submit Button",
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    },
    {
        label: `"Thank You" Step`,
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    },
    {
        label: "Progress Bar",
        tooltip: { hasTooltip: true, title: "With this feature you can either enable or disable a progress bar. If enabled, you'll see the progress bar start on step two of your forms." },
        isOpen: false,

    },
    {
        label: "Other Font Styling",
        tooltip: { hasTooltip: false, title: "" },
        isOpen: false,

    }
];

export const QuestionBlockSwithcs = [
    {
        label: `Enable Multi-Select`,
        tooltip: { hasTooltip: true, title: `This setting turns the step into a multi-select step. Use this feature if you'd like the prospect to provide more than one response in this step. You can style the multi-select button within the "design" section of the form.` },
        isSwitch: false,
        children: [
            {
                label: "Submit Button Label",
                defaultValue: ''
            }
        ]
    },
    {
        label: `TCPA Notice`,
        tooltip: { hasTooltip: true, title: `This setting enables a TCPA banner at the bottom of the form field. When the TCPA notice is clicked, the field will expand and provide additional information to the prospect.` },
        isSwitch: false,
        children: [
            {
                label: "TCPA Notice Title",
                defaultValue: ''
            },
            {
                label: "TCPA Notice Text",
                defaultValue: ''
            },
            {
                label: "TCPA Notice Webhook URL",
                defaultValue: ''
            },
        ]
    },
    {
        label: `Include in SMS?`,
        tooltip: { hasTooltip: true, title: `Enable this field if you'd like to include this step in an SMS lead notification. You'll also need to enable "SMS Notifications" in the lead delivery section of your dashboard.` },
        isSwitch: false,
        children: []
    },
];

export const QuestionBlockCollapse = [
    {
        label: `Webhook Settings`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
        children: [
            {
                label: "Webhook Field Name",
                defaultValue: ''
            }
        ]
    },
    {
        label: `Mailchimp`,
        tooltip: { hasTooltip: true, title: `If sending leads into an email service or CRM such as Mailchimp, add your Mailchimp tag here. You'll also need to enable Mailchimp in the Lead Delivery section of the form.` },
        isCollapsed: false,
        children: [
            {
                label: "Mailchimp Field Name",
                defaultValue: ''
            }
        ]
    },
    {
        label: `ActiveCampaign`,
        tooltip: { hasTooltip: true, title: `If sending leads into an email service or CRM such as ActiveCampaign, add your ActiveCampaign field here. You'll also need to enable ActiveCampaign in the Lead Delivery section of the form.` },
        isCollapsed: false,
        children: [
            {
                label: "ActiveCampaign Field Name",
                defaultValue: ''
            }
        ]
    },
    {
        label: `LeadsPedia`,
        tooltip: { hasTooltip: true, title: `If sending leads into a CRM such as Leadspedia, add your Leadspedia field here. You'll also need to enable Leadspedia in the Lead Delivery section of the form.` },
        isCollapsed: false,
        children: [
            {
                label: "LeadsPedia Field Name",
                defaultValue: ''
            },
            {
                label: "LeadsPedia Campaign ID",
                defaultValue: ''
            },
            {
                label: "LeadsPedia Campaign Key",
                defaultValue: ''
            },
        ]
    },
    {
        label: `HubSpot`,
        tooltip: { hasTooltip: true, title: `If sending leads to a CRM like Hubspot, you'll need to add your Hubspot Property here. You'll also need to enable Hubspot in the lead delivery section of your form.` },
        isCollapsed: false,
        children: [
            {
                label: "HubSpot Field Name",
                defaultValue: ''
            }
        ]
    },
    {
        label: `Step Layout Override`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
        children: []
    },
    {
        label: `Step Teaser Text`,
        tooltip: { hasTooltip: true, title: `The teaser text is a line of text that appears below the form. For example, you can use this area to say something like "your almost there!" or to provide an additional incentive to keep the prospect moving through your form.` },
        isCollapsed: false,
        children: []
    },
    {
        label: `Lead Notification Description`,
        tooltip: { hasTooltip: true, title: `This setting adds an additional line of text to the lead notification that's sent through email. You should only need to use this field if you need to add more clarity about a step in your form.` },
        isCollapsed: false,
        children: [
            {
                label: "Lead Notification Description",
                defaultValue: ''
            }
        ]
    },
];

export const FormAdvanceOptions = [

    {
        label: `TCPA Notice`,
        tooltip: { hasTooltip: true, title: `This setting enables a TCPA banner at the bottom of the form field. When the TCPA notice is clicked, the field will expand and provide additional information to the prospect.` },
        isSwitch: false,
        children: [
            {
                label: "TCPA Notice Title",
                defaultValue: ''
            },
            {
                label: "TCPA Notice Text",
                defaultValue: ''
            },
            {
                label: "TCPA Notice Webhook URL",
                defaultValue: ''
            },
        ]
    },
    {
        label: `Step Teaser Text`,
        tooltip: { hasTooltip: true, title: `The teaser text is a line of text that appears below the form. For example, you can use this area to say something like "your almost there!" or to provide an additional incentive to keep the prospect moving through your form.` },
        isSwitch: false,
        children: []
    },

];

export const FormAdvanceOptionsCollapse = [

    {
        label: `ConvertKit`,
        tooltip: { hasTooltip: true, title: `If pushing leads into a CRM such as ConvertKit, add your ConvertKit tag here. You'll also need to enable ConvertKit in the Lead Delivery section of the form.` },
        isCollapsed: false,
        children: [
            {
                label: "ConvertKit Tags",
                defaultValue: ''
            },

        ]
    },
    {
        label: `LeadsPedia`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
        children: [
            {
                label: "Leadspedia Campaign ID",
                defaultValue: ''
            },
            {
                label: "Leadspedia Campaign Key",
                defaultValue: ''
            },

        ]
    },

    {
        label: `Lead Notification Description`,
        tooltip: { hasTooltip: true, title: `This setting adds an additional line of text to the lead notification that's sent through email. You should only need to use this field if you need to add more clarity about a step in your form.` },
        isCollapsed: false,
        children: [
            {
                label: "Lead Notification Description",
                defaultValue: ''
            },


        ]
    },


];

export const SettingsCollapse = [
    {
        label: `General`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
    },
    {
        label: `Form Mode (Popup or Embedded)`,
        tooltip: { hasTooltip: true, title: `Use this setting to adjust your form's display mode. Choose "popup" if you'd like to use the timed popup, exit popup, or click popup features. Choose "embedded" if you'd like to embed your form right into the page. Choose "all" if you'd like your form to be embedded AND popup.` },
        isCollapsed: false,
    },
    {
        label: `Popup behavior`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
    },
    {
        label: `Onsite Message`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
    },

    {
        label: `Avatar`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
    },
    {
        label: `Auto-Fill Zip Code`,
        tooltip: { hasTooltip: false, title: `` },
        isSwitch: false,
    },
    {
        label: `Google Analytics`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
    },
    {
        label: `Lead Value`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
    },
    {
        label: `reCAPTCHA`,
        tooltip: { hasTooltip: true, title: `This setting enables Google Recaptcha. You can enter your Google Recaptcha site key here. After your site key is added, you'll need to enable recaptcha at the step level. We recommend enabling recaptcha on your last form field step.` },
        isCollapsed: false,
    },
    {
        label: `Customize Error Text`,
        tooltip: { hasTooltip: false, title: `` },
        isCollapsed: false,
    },
    {
        label: `Drop-off Mode`,
        tooltip: { hasTooltip: true, title: `Drop-off mode allows you to capture the prospect's info when they abandon the form. Drop-off mode will only go into effect when you have a form field such as a phone number or email address followed by a number of steps after. If you're collecting all of the contact details at the end of the form then DO NOT use drop-off mode.` },
        isSwitch: false,
    },
];

export const defaultDesignOptions: FormsDesignOptions =
{
    templateStyle: {
        templateStyle: { value: 'EnhancedForm', label: 'Enhanced Form' },
        templateBgColor: "",
        templateBgimg: "",
    },
    header: {
        hideHeader: false,
        headlineText: "",
        headlineBgColor: "",
        headlineBgimg: "",
        headlineFontColor: "#000",
        headlineFontSize: '',
        headlineFontWeight: { value: 700, label: '700' },
    },
    stepHeadingAndSubHeading: {
        stepHeadingFontColor: "",
        stepHeadingFontSize: "",
        stepHeadingFontWeight: { value: 600, label: '600' },
        stepSubHeadingFontColor: "",
        stepSubHeadingFontSize: "",
        stepSubHeadingFontWeight: { value: 700, label: '700' },
    },
    responseStyling: {
        responseGridWidth: { value: 12, label: '12' },
        responseFontSize: '',
        responseTextAlign: { value: 'center', label: 'Center' },
        responseFontWeight: { value: 400, label: '400' },
        responseMarginBottom: '',
        responseVerticalPadding: '',
        responseBgColor: '',
        responseHoverBgColor: '',
        responseBorderColor: '',
        responseFontColor: '',
        responseHoverFontColor: '',
        responseBoxMaxWidth: "",
        responseBoxMaxWidthOnMobile: "",
        multiselectColor:"",
        multiselectTextColor:"",
        multiselectSelectedColor:"",
        multiselectSeletedTextColor:""
    },
    loadingSpinner: {
        loadingSpinnerColor: "#fff",
        loadingSpinnerSzie: "",
        loadingSpinnerThickness: ""
    },
    callUsBar: {
        topCallUsBar: false,
        bottomCallUsBar: false,
        callUsBarText: '',
        callUsBarPhone: '',
        callUsBarBgColor: '',
        callUsBarFontColor: '',
        callUsBarFontSize: '',
        callUsBarFontWeight: { value: 400, label: '400' },
    },
    backButton: {
        backBtn: false,
        backBtnBgColor: "",
        backBtnHoverBgColor: "",
        backBtnBorderRadius: "",
        backBtnVerticalPadding: "",
        backBtnWidthAuto: false,
        backBtnWidth: "100",
        backBtnHorizontalPadding: "",
        backBtnHorizontalPosition: { value: "center", label: 'Middle' },
        backBtnVerticalPosition: { value: "top", label: 'Top' },
        backBtnFontColor: "",
        backBtnText: "Back",
        backBtnFontSize: "",
        backBtnFontWeight: { value: 400, label: '400' },
    },
    onSiteMessage: {
        onSiteMessageMaxWidth: "",
        onSiteMessageMaxWidthOnMobile: "",
    },
    leadFormDimensions: {
        formHeight_popup: "",
        formWidth_popup: "",
        formHeightonMobile_popup: "",
        formWidthonMobile_popup: "",
    },
    offerHeadline: {
        offerHeadlineText: "",
        offerHeadlineFontColor: "",
        offerHeadlineFontSize: "",
        offerHeadlineFontWeight: { value: 400, label: '400' }
    },
    submitButton: {
        submitButtonWidth: "",
        submitButtonWidthMobile: "",
        submitButtonColor: "",
        submitButtonColorOnHover: "",
        submitButtonBorderColor: "",
        submitButtonTextColor: "",
        submitButtonTextColorOnHover: "",
        submitButtonFontSize: "",
        submitButtonFontSizeOnMobile: "",
        submitButtonFontWeight: { value: 400, label: '400' },
        submitButtonAdditionalTextColor: "",
        submitButtonBorderRadius: "",
    },
    thankyouStep: {
        thankyouStepFontSize: "",
        thankyouStepFontSizeOnMobile: "",
        thankyouStepFontWeight: { value: 400, label: '400' },
        thankyouStepFontColor: "",
        thankyouStepDarkCloseButton: false,
    },
    progressBar: {
        progressBar: false,
        progressBarColor: "",
        progressBarBgColor: "",
        progressBarFontColor: "",
    },
    otherFontStyling: {
        linkColor: "",
        generalTextColor: "",
        socialProof: false,
        socialProofDefaultCount: "",
        socialProofLabel: "",
        socialProofText: "",
        socialProofTextColor: "",
        tcpNoticeTitleColor: "",
        tcpNoticeHandlerColor: "",
        tcpNoticeTextBackgroundColor: "",
        tcpNoticeTextColor: "",
        tcpNoticeTextAlign: { value: 'center', label: 'Center' },
    }

};

export const defaultSettingsOptions = {
    profileEmail: { value: '', label: '' },
    FormName: "",
    chooseMode: { value: 'popup', label: 'Popup' },
    timedPopup: false,
    popupOnDevices: { value: 'all', label: 'All' },
    popupDelay: 1,
    mobileSpecificDelay: false,
    mobilePopupDelay: "",
    popupFrequency: { value: 'every_visit', label: 'Every Visit' },
    exitPopup: false,
    onSiteMessage: false,
    onSiteMessageOnMobile: false,
    onSiteMessagePosition: { value: 'bottom_right', label: 'Bottom Right' },
    displayMessage: false,
    appearanceDelay: "",
    displayMessageOnMobile: false,
    appearanceDelayOnMobile: "",
    displayMessageText: "",
    hideAvatarhere: false,
    hideAvatarAfterStepOne: false,
    hideAvatar_Embedded: false,
    AvatarImage: "",
    autofillZipCode: false,
    dropoffMode: false,
    googleAnalyticsTrackingID: "",
    ga4MeasurementID: "",
    LeadValue: "",
    reCAPTCHASiteKey: "",
    questionPromptText: "",
    formField_Required_ErrorText: "",
    formField_WrongEmail_ErrorText: "",
    formField_WrongZipCode_ErrorText: "",
    formField_WrongCanadianPostalCode_ErrorText: "",
    formField_WrongUKPostalCode_ErrorText: "",
    formField_WrongPhone_ErrorText: "",
    formField_WrongFullName_ErrorText: "",
    formField_WrongPassword_ErrorText:""
};

export const defaultstepOptions: FormSteps = {
    id: undefined,
    formId: '',
    responses: [],
    stepOrder: 0,
    targetStepId: undefined,
    textBlockAdditionalText: '',
    stepTeaserText: '',
    textBlockText: '',
    stepType: 'Question',
    name: "",
    questionText: "",
    subTitle: "",
    includeSMS: true,
    formTitle: "",
    submitButtonLabel: "",
    sendLead: false
};