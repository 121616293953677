import { SideBarHeaderWrapper } from 'Components/Common';
import { useFormik } from 'formik';
import { CallAxios } from 'Helpers';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import BlockedPages from "./Sidebars/PublishTab/BlockedPages";
import EmbedLeadForm from "./Sidebars/PublishTab/EmbedLeadForm";
import PixelScript from "./Sidebars/PublishTab/PixelScript";
import Targetpages from "./Sidebars/PublishTab/Targetpages";
import TriggerWithButtonClick from "./Sidebars/PublishTab/TriggerWithButtonClick";



const StepConfigName = styled.div`
background: rgb(247, 247, 247);
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    padding: 18px 25px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
`

const PublishTab = () => {
    const { formId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formik = useFormik<FormEditorPublishType>({
        initialValues: {
            url: [""],
            blockUrl: [""],
            customElementClass: "",
            showOnAllSubPage: false
        },
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
            CallAxios('/api/Forms/SaveStepData|PATCH',
                {
                    Data: JSON.stringify(values),
                    FormId: formId,
                    Step: 'Publish'
                },
                undefined,
                undefined,
                navigate,
                dispatch
            ).then(res => {
                toast(res?.data?.message, { type: res?.data.success ? 'success' : 'warning' });
            })
        },
    })
    return (
        <>
            <SideBarHeaderWrapper>
                <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='mb-0 fw-bold'>Add To Site</h6>
                    <button className='btn btn-primary btn-sm' onClick={formik.handleSubmit as any}> Save changes</button>
                </div>
            </SideBarHeaderWrapper>
            <div className='CollapseAbleWrapper'>
                <StepConfigName>Step 1: Configuration</StepConfigName>
                <PixelScript />
                <Targetpages formik={formik} />
                <BlockedPages formik={formik} />
            </div>
            <div className='CollapseAbleWrapper'>
                <StepConfigName>Step 2: Choose Behavior Options</StepConfigName>
                <EmbedLeadForm />
                <TriggerWithButtonClick formik={formik} />
            </div>
        </>
    );
}

export default PublishTab;






