import { useNylas } from "@nylas/nylas-react";
import React from "react";

const base_url = 'https://api.nylas.com';
const client_id = '1mgb8tnp72qh968sykohoznlw';
const redirect_uri = 'http://localhost:3000/login-result';
export const Nylas = () => {
    const { authWithRedirect, exchangeCodeFromUrlForToken } = useNylas();
    const [email, setEmail] = React.useState('');//U80vVt4rCyOB84IM8U6Ku8ZcFW15M8

    return (
        <div className="App">
            <section style={{ width: '80vw', margin: "10vh auto" }}>
                <h1>Connect your email</h1>
                <p>Authenticate your email to read</p>
                <div style={{ marginTop: "30px" }}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            window.location.href = `${base_url}/oauth/authorize?client_id=${client_id}&response_type=code&scopes=email calendar&login_hint=${email}&redirect_uri=${redirect_uri}&state`;
                            //authWithRedirect({ emailAddress: email, successRedirectUrl: "/success" })
                        }}>
                        <input
                            required
                            type="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button type="submit">Connect</button>
                    </form>
                </div>
            </section>
        </div>
    );
}
