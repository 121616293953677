import FormFloatingLabel from "Components/FloatingInput";


const GoogleAnalytics = (props: { dispatch: any; settings: any; setSettings: any; }) => {
    const { dispatch, settings, setSettings } = props;

    return (
        <>
            <div className='mb-3'>
                <FormFloatingLabel label={"Google Analytics Tracking ID"} defaultValue={settings.googleAnalyticsTrackingID} onInput={(value) => dispatch(setSettings({ googleAnalyticsTrackingID: value }))} />

            </div>
            <div className=''>
                <FormFloatingLabel label={"GA4 Measurement ID"} defaultValue={settings.ga4MeasurementID} onInput={(value) => dispatch(setSettings({ ga4MeasurementID: value }))} />

            </div>
        </>
    )
}

export default GoogleAnalytics