import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CommonArea, CollapseTrigger, TopBottomPadding } from 'Components/Common';
import { BiChevronRight } from "react-icons/bi"
import FormFloatingLabel from "Components/FloatingInput"

const CollapseTriggerHeading = styled.div`
font-size: 17px;

`
const CollapsedArea = styled.div`
padding: 0px 35px;
    position: relative;
    font-size: 16px;
    border-bottom: 1px dotted rgba(153, 153, 153, 0.6);
    background: rgb(247, 247, 247);
`

const ScriptDiv = styled.div`
padding: 8px 15px;
    font-size: 14px;
    overflow: hidden;
    position: relative;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-weight:600
`

const TriggerWithButtonClick = (props: { formik: any; }) => {
    const { formik } = props;
    const [showDiv, setShowDiv] = useState(false);

    return (
        <>
            <CollapseTrigger cursor='default' onClick={() => setShowDiv(!showDiv)}>
                <TopBottomPadding>
                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapseTriggerHeading className='d-flex '>
                            Trigger With Button Click

                        </CollapseTriggerHeading>

                        <CommonArea className={`${showDiv === true ? 'rotateit' : ''}`}>
                            <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                        </CommonArea>

                    </div>
                </TopBottomPadding>
            </CollapseTrigger>
            {showDiv === true ? <CollapsedArea id={"example-collapse-text_ss"}>
                <TopBottomPadding>
                    <div className='mb-2'>
                        <p className='small'>{`Below here are the example control element(s) you can put anywhere on the page to trigger the active form window appearance by clicking on them. Any html element with the class "leadforms-trigger-el" will work as a trigger element`}</p>
                        <ScriptDiv className='mb-2'>{`<button class="leadforms-trigger-el">Open form</button>`}</ScriptDiv>
                        <ScriptDiv className='mb-3'>                    <button className='btn btn-primary btn-sm'> Copy Code</button></ScriptDiv>
                    </div>
                    <div className='mb-2'>
                        <ScriptDiv className='mb-2'>{`<a href="#" class="leadforms-trigger-el">Open form</a>`}</ScriptDiv>
                        <ScriptDiv className='mb-3'>                    <button className='btn btn-primary btn-sm'> Copy Code</button></ScriptDiv>
                    </div>
                    <div className='mb-2'>
                        <ScriptDiv className='mb-2'>{`<span class="leadforms-trigger-el">Open form</span>`}</ScriptDiv>
                        <ScriptDiv className='mb-3'>                    <button className='btn btn-primary btn-sm'> Copy Code</button></ScriptDiv>
                    </div>
                    <div className='mb-2'>
                        <ScriptDiv className='mb-2'>{`<div class="leadforms-trigger-el">Open form</div>`}</ScriptDiv>
                        <ScriptDiv className='mb-3'>                    <button className='btn btn-primary btn-sm'> Copy Code</button></ScriptDiv>
                    </div>
                    <div className='mb-2'>
                        <FormFloatingLabel label={"Custom Element Classes"} onInput={(value) => formik.setFieldValue(`customElementClass`, value)} defaultValue={formik.values.customElementClass} />

                    </div>
                </TopBottomPadding>
            </CollapsedArea>
                : null}
        </>
    );
}

export default TriggerWithButtonClick