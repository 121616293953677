import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDesignOptions } from 'Store/slices/EditFormSlice';
import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from "Components/ColorPicker";
import CustomSelect from "Components/CustomSelect";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import InputPrefix from "Components/PrefixInput";
import ToolTip from 'Components/Tooltip';

const ThankYouStep = () => {
    const ThankYouStepFontWeight = [
        { value: 400, label: '400' },
        { value: 500, label: '500' },
        { value: 600, label: '600' },
        { value: 700, label: '700' },
        { value: 900, label: '900' },
    ];

    const [DarkCloseButton, setDarkCloseButton] = useState(false);
    const dispatch = useDispatch();

    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);

    return (
        <>

            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        "Thank You" Step Font Size
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the font size of the thank you step.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions?.thankyouStep?.thankyouStepFontSize} onInput={(value) => dispatch(setDesignOptions({ key: "thankyouStep", data: { thankyouStepFontSize: value } }))} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        "Thank You" Step Font Size Mobile
                        <div className='ms-2'>
                            <ToolTip text={`This adjusts the font size of the thank you step on mobile only.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <InputPrefix defaultValue={designOptions?.thankyouStep?.thankyouStepFontSizeOnMobile} onInput={(value) => dispatch(setDesignOptions({ key: "thankyouStep", data: { thankyouStepFontSizeOnMobile: value } }))} prefix={"pixels"} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        "Thank You" Step Font Weight

                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <CustomSelect defaultValue={designOptions?.thankyouStep?.thankyouStepFontWeight} onChange={(selected) => dispatch(setDesignOptions({ key: "thankyouStep", data: { thankyouStepFontWeight: selected } }))} data={ThankYouStepFontWeight} name={"ThankYouStepFontWeight"} />



                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        "Thank You" Step Font Color
                        <div className='ms-2'>
                            <ToolTip text={`This setting changes any other text color that's not addressed by one of the settings above.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {

                            dispatch(setDesignOptions({ key: "thankyouStep", data: { thankyouStepFontColor: color } }))
                        }} defaultValue={designOptions?.thankyouStep?.thankyouStepFontColor} />


                    </div>

                </div>

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Dark "Close" Button
                        <div className='ms-2'>
                            <ToolTip text={`This setting replaces the light close button with a solid dark close button on the thank you step.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <MaterialDesignSwitch defaultchecked={designOptions?.thankyouStep?.thankyouStepDarkCloseButton}
                            checked={(checked) => {
                                dispatch(setDesignOptions({ key: "thankyouStep", data: { thankyouStepDarkCloseButton: checked } }));
                            }} id={"DarkCloseButton"} />


                    </div>

                </div>

            </TopBottomPadding>

        </>
    );
}

export default ThankYouStep;