import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomSelect from './CustomSelect';

const TargetStep = (props: {
    formik: FormikType<FormSteps> | FormikType<FormStepResponses>
}) => {
    const { formik } = props;
    const [data, setData] = useState<LabelValuePair<string>[]>([]);
    const steps = useSelector((state: AppStore) => state.editor.steps);
    useEffect(() => {
        const newList: LabelValuePair<string>[] = [];
        steps.map(x => newList.push({ label: x.name || '', value: String(x.id) }));
        setData(newList)
    }, [steps]);
    return (
        <>
            <label className='mb-2'>Select a target step:</label>
            <CustomSelect data={data} onChange={(value) => formik.setFieldValue("targetStepId", value?.value)} defaultValue={data.find(x => x.value === formik?.values?.targetStepId)} name={"targetStepId"} />
        </>
    );
}

export default TargetStep;