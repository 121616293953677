import { ExecuteParsers } from 'Helpers';
import { config } from 'Helpers/Config';
import { AnswerDiv, LeadformAnswerWrapper, Responses } from 'Pages/Forms/Builder/Components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from "styled-components";

const ImageTextDiv = styled.div`
position: relative;
height: 0;
margin-top: -44px;
z-index: 999999;
padding: 15px 15px 30px;
cursor: pointer;
background:${(props: {
    textStyle: IconsData
}) => !!props?.textStyle?.iconButtonColor ? props?.textStyle?.iconButtonColor : "rgba(107,96,163,0.8)"};
text-align: center;
color: ${props => !!props?.textStyle?.iconButtonTextColor ? props?.textStyle?.iconButtonTextColor : "#fff"};

&:hover{
    color:${props => !!props?.textStyle?.iconButtonTextColorOnHover ? props?.textStyle?.iconButtonTextColorOnHover : "#000"};
    background:${props => !!props?.textStyle?.iconButtonColorOnHover ? props?.textStyle?.iconButtonColorOnHover : "#000"}
}
`
export const ResponseChatStyle = (props: {
    action?: FormPreviewActions,
    currentSlice: StoreSlices
}) => {
    const { id } = useParams();
    const { action, currentSlice } = props;
    const Defaultresponses = useSelector((state: AppStore) => state.editor.defaultResponses);
    const designOptions = useSelector((state: AppStore) => state.editor.designOptions);
    const steps = useSelector((state: AppStore) => state.editor.steps);
    const responses = steps.find(x => x.id === String(id));

    return (

        <LeadformAnswerWrapper>

            {id ?
                (responses?.responses && responses?.responses?.length > 0 ?
                    responses?.responses.map((x, i) =>

                        <AnswerDiv key={'re_' + i}>
                            <Responses
                                type={'ChatStyle'}
                                fc={designOptions.responseStyling?.responseFontColor}
                                bgColor={designOptions.responseStyling.responseBgColor}
                                bgColorOnHover={designOptions.responseStyling.responseHoverBgColor}
                                fcOnHover={designOptions.responseStyling?.responseHoverFontColor}
                                onClick={() => action?.changeStep(x)}
                            >
                                {/* {x.responseOrFieldName} */}
                                {LoadIconWithText(x)}
                            </Responses>
                        </AnswerDiv>

                    )
                    :
                    Defaultresponses.map((x, i) =>

                        <AnswerDiv key={'re_' + i}>
                            <Responses
                                type={'ChatStyle'}
                                fc={designOptions.responseStyling?.responseFontColor}
                                bgColor={designOptions.responseStyling.responseBgColor}
                                bgColorOnHover={designOptions.responseStyling.responseHoverBgColor}
                                fcOnHover={designOptions.responseStyling?.responseHoverFontColor}>
                                {x}
                            </Responses>
                        </AnswerDiv>
                    ))
                :
                Defaultresponses.map((x, i) =>

                    <AnswerDiv key={'re_' + i}>
                        <Responses
                            type={'ChatStyle'}
                            fc={designOptions.responseStyling?.responseFontColor}
                            bgColor={designOptions.responseStyling.responseBgColor}
                            bgColorOnHover={designOptions.responseStyling.responseHoverBgColor}
                            fcOnHover={designOptions.responseStyling?.responseHoverFontColor}>
                            {x}
                        </Responses>
                    </AnswerDiv>
                )
            }
        </LeadformAnswerWrapper>
    );
}
const LoadIconWithText = (x: FormStepResponses) => {
    if (!x) return;

    x = ExecuteParsers(x);
    const data = x.iconData;
    if (typeof data == 'object' && data?.imgsrc) {
        return showIcon(data, x)
    } else {
        return (
            <div>
                {x.responseOrFieldName}
            </div>
        )
    }

}
export const ResponsesList = (props: {
    action?: FormPreviewActions,
    currentSlice: StoreSlices
}) => {
    const { action, currentSlice } = props;
    const Defaultresponses = useSelector((state: AppStore) => state[currentSlice].defaultResponses);
    const designOptions = useSelector((state: AppStore) => state[currentSlice].designOptions);
    const stepOptions = useSelector((state: AppStore) => state[currentSlice].stepOptions);

    return (
        <LeadformAnswerWrapper className="ans-wrapper-responsive">
            {
                stepOptions?.responses && stepOptions?.responses?.length > 0 ?
                    stepOptions?.responses.map((x, i) =>
                        <AnswerDiv className="ans-div-responsive" key={i} responseGridWidth={designOptions.responseStyling.responseGridWidth}>
                            <Responses
                                type={'EnhancedForm'}
                                py={designOptions.responseStyling.responseVerticalPadding}
                                fw={designOptions.responseStyling.responseFontWeight}
                                fs={designOptions.responseStyling.responseFontSize}
                                mb={designOptions.responseStyling.responseMarginBottom}
                                fc={designOptions.responseStyling.responseFontColor}
                                textAlign={designOptions.responseStyling.responseTextAlign}
                                bgColor={designOptions.responseStyling.responseBgColor}
                                bgColorOnHover={designOptions.responseStyling.responseHoverBgColor}
                                borderColor={designOptions.responseStyling.responseBorderColor}
                                fcOnHover={designOptions.responseStyling.responseHoverFontColor}
                                onClick={() => action?.changeStep(x)}>
                                {LoadIconWithText(x)}

                            </Responses>
                        </AnswerDiv>)
                    :
                    Defaultresponses.map((x, i) =>
                        <AnswerDiv className="ans-div-responsive"  key={i} responseGridWidth={designOptions.responseStyling.responseGridWidth}>
                            <Responses
                                type={'EnhancedForm'}
                                py={designOptions.responseStyling.responseVerticalPadding}
                                fw={designOptions.responseStyling.responseFontWeight}
                                fs={designOptions.responseStyling.responseFontSize}
                                mb={designOptions.responseStyling.responseMarginBottom}
                                fc={designOptions.responseStyling.responseFontColor}
                                textAlign={designOptions.responseStyling.responseTextAlign}
                                bgColor={designOptions.responseStyling.responseBgColor}
                                bgColorOnHover={designOptions.responseStyling.responseHoverBgColor}
                                borderColor={designOptions.responseStyling.responseBorderColor}
                                fcOnHover={designOptions.responseStyling.responseHoverFontColor}>
                                {x}
                            </Responses>
                        </AnswerDiv>
                    )

            }
        </LeadformAnswerWrapper>
    );
}




const showIcon = (data: IconsData, x: FormStepResponses) => {

    const imgPosition: any = {
        "top": <div className={data?.contentAlignment.value === "left" ? "text-start" : data?.contentAlignment.value === "center" ? "text-center" : data?.contentAlignment.value === "right" ? "text-end" : ""}>
            <div>
                <img src={config.ICON_IMAGES_URL + x.formId + '/' + data?.imgsrc} style={{
                    width: "auto",
                    height: data?.iconHeight ? data?.iconHeight + "px" : "50px",
                    margin: "0px 0px 5px",
                }} />
                {data?.iconButtontext && iconButtonTextContent(data)}
            </div>
            {x?.responseOrFieldName}
        </div>,
        "bottom": <div className={data?.contentAlignment.value === "left" ? "text-start" : data?.contentAlignment.value === "center" ? "text-center" : data?.contentAlignment.value === "right" ? "text-end" : ""}>
            {x.responseOrFieldName}
            <div>
                <img src={config.ICON_IMAGES_URL + x.formId + '/' + data?.imgsrc} style={{
                    width: "auto",
                    height: data?.iconHeight ? data?.iconHeight + "px" : "50px",
                    margin: "5px 0px 0px",
                }} />
                {data?.iconButtontext && iconButtonTextContent(data)}
            </div>

        </div>,
        "left": <div className={`d-flex align-items-center ${data?.contentAlignment.value === "left" ? "text-start" : data?.contentAlignment.value === "center" ? "text-center" : data?.contentAlignment.value === "right" ? "text-end" : ""}`}>

            <div>
                <img src={config.ICON_IMAGES_URL + x.formId + '/' + data?.imgsrc} style={{
                    width: "auto",
                    height: data?.iconHeight ? data?.iconHeight + "px" : "50px",
                    margin: marginInIcon(data),
                }} />
                {data?.iconButtontext && iconButtonTextContent(data)}
            </div>
            {x.responseOrFieldName}
        </div>,
        "right": <div className={`d-flex align-items-center ${data?.contentAlignment.value === "left" ? "text-start" : data?.contentAlignment.value === "center" ? "text-center" : data?.contentAlignment.value === "right" ? "text-end" : ""}`}>
            {x.responseOrFieldName}
            <div>
                <img src={config.ICON_IMAGES_URL + x.formId + '/' + data?.imgsrc} style={{
                    width: "auto",
                    height: data?.iconHeight ? data?.iconHeight + "px" : "50px",
                    margin: marginInIcon(data),
                }} />
                {data?.iconButtontext && iconButtonTextContent(data)}
            </div>

        </div>,
    }

    return imgPosition[data?.iconPosition.value];

}

const iconButtonTextContent = (data: IconsData) => {

    return (
        <ImageTextDiv textStyle={data}>
            {data?.iconButtontext}
        </ImageTextDiv>
    )

}

const marginInIcon = (data: IconsData) => {

    if (data.iconRightPadding || data.iconLeftPadding) {
        return `0px ${data.iconRightPadding || 0}px 0px ${data.iconLeftPadding || 0}px`
    } else {
        return `0px`
    }
}