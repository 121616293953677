import { createSlice } from "@reduxjs/toolkit"

const initialState: EmailFormSlice = {
    isAccountEmail:false,
    email:"",
    emailId:""
}

const emailSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {
        setAccountEmail: (state: EmailFormSlice, action: IAction<string>) => {
            state.email = action.payload
        },
        setIsAccountEmail: (state: EmailFormSlice, action: IAction<boolean>) => {
            state.isAccountEmail = action.payload
        },
        setAccountEmailId: (state: EmailFormSlice, action: IAction<string>) => {
            state.emailId = action.payload
        },
    },
});

export const {
    setAccountEmail,setIsAccountEmail, setAccountEmailId
} = emailSlice.actions;

export default emailSlice.reducer