export const FieldValidationTypeOptions: LabelValuePair<ValidationTypes>[] = [
    { value: 'Text', label: 'Text' },
    { value: 'Number', label: 'Numeric' },
    { value: 'FirstName', label: 'First Name' },
    { value: 'LastName', label: 'Last Name' },
    { value: 'Email', label: 'Email' },
    { value: 'Zipcode', label: 'Zip Code' },
    { value: 'Password', label: 'Password' },
    { value: 'PhoneNumberValidation', label: 'Text (Phone Number validation)' },
];
export const GetFieldValidationTypeOption = (type: ValidationTypes) => {
    return FieldValidationTypeOptions.find(x => x.value === type);
}

export const FieldTypeOptions: LabelValuePair<ResponseFieldType>[] = [
    { value: 'Text', label: 'Text' },
    { value: 'FirstName', label: 'FirstName' },
    { value: 'LongText', label: 'Long text' },
    { value: 'Select', label: 'Select' },
    { value: 'MultiSelect', label: 'Multi Select' },
    { value: 'Slider', label: 'Slider' },
    { value: 'Calendar', label: 'Calendar' },
    { value: 'Phone', label: 'Phone' },
    { value: 'Date', label: 'Date' },
];
export const GetFieldTypeOption = (type: ResponseFieldType) => {
    return FieldTypeOptions.find(x => x.value === type);
}

export const StepTypeOptions: LabelValuePair<FormStepType>[] = [
    { value: 'Question', label: 'Question' },
    { value: 'Form', label: 'Form' },
    { value: 'TextBlock', label: 'Text block' },
]
export const GetStepTypeOption = (type: FormStepType) => {
    return StepTypeOptions.find(x => x.value === type);
}