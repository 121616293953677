import { FieldTypeSelect, ValidationTypeSelect } from 'Components';
import { AddResponseTExt, CollapsedAreaLabels, ResponseVariableName, SubtitleText, TitleText, TopBottomPadding } from 'Components/Common';
import FormFloatingLabel from "Components/FloatingInput";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import ToolTip from 'Components/Tooltip';
import { useSelector } from 'react-redux';
import CreateOptionsForSelectBox from './FieldSelectOptions';
import SliderSettings from './SliderSettings';
import React from 'react';
const AddEditFormField = (props: {
    formik: FormikType<FormStepResponses>
}) => {
    const { formik } = props;
    const name = useSelector((state: AppStore) => state.editor.stepOptions?.name);

    const [showBlock, setShowBlock] = React.useState(false);
    React.useEffect(() => {
        // Check if fieldType is "Text" and validationType is "Email"
        if (formik.values.fieldType === "Text" && formik.values.validationType === "Email") {
            setShowBlock(true);
        } else {
            setShowBlock(false);
        }
    }, [formik.values.fieldType, formik.values.validationType]);
    return (
        <div id='editor_main'>
            <AddResponseTExt>
                <TitleText>
                    Add Form Field
                </TitleText>
                <SubtitleText>
                    (Save the form field to reflect the changes in the real-time demo)
                </SubtitleText>
            </AddResponseTExt>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Field Is Required?
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch defaultchecked={formik.values.isRequired} id={"isRequired"} checked={(checked) => formik.setFieldValue('isRequired', checked)} />

                    </div>

                </div>
            </TopBottomPadding>
            <div className="mb-3 text-start">
                <FormFloatingLabel onInput={(value) => { formik.setFieldValue("responseOrFieldName", value); }} label={"Field Name"} defaultValue={formik.values.responseOrFieldName} />
                {
                    formik.errors.responseOrFieldName
                    && formik.touched.responseOrFieldName
                    && (<small className="text-danger">{formik.errors.responseOrFieldName}</small>)
                }
                <div className='d-flex  align-items-center'>
                    <ResponseVariableName className='ResponseVariableName'>Variable name: {`{${name?.replaceAll(" ", "_")}_${formik.values.responseOrFieldName?.replaceAll(" ", "_")}}`}</ResponseVariableName>
                    <div className='ms-2'>
                        <ToolTip text={`Put it as "{${name?.replaceAll(" ", "_")}_${formik.values.responseOrFieldName?.replaceAll(" ", "_")}}" in any input field to replace the variable name with the actual value of the response from the step.`} />
                    </div>
                </div>
            </div>
            <div className="mb-3 text-start">
                <FormFloatingLabel onInput={(value) => formik.setFieldValue("fieldLabel", value)} label={"Field label"} defaultValue={formik.values.fieldLabel} />
                {
                    formik.errors.fieldLabel
                    && formik.touched.fieldLabel && (<small className="text-danger">{formik.errors.fieldLabel}</small>)
                }
            </div>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Personally Identifiable Information?
                        <div className='ms-2'>
                            <ToolTip text={`This setting will remove the response from any email that's added to the GDPR friendly lead notifications in the lead delivery section. For example, if you enable this setting for the "email address" field then the email address will not pass through to any lead notifications.`} />
                        </div>
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform `}>
                        <MaterialDesignSwitch
                            defaultchecked={formik.values.personallyIdentifiableInformation}
                            id={"personallyIdentifiableInformation"}
                            checked={(checked) => formik.setFieldValue('personallyIdentifiableInformation', checked)}
                        />

                    </div>

                </div>

            </TopBottomPadding>
            
                {
                    showBlock && 
                    <TopBottomPadding>
                        <div className='d-flex justify-content-between align-items-center'>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                Is Account Email?
                                <div className='ms-2'>
                                    <ToolTip text={`This setting turns that email field will be used to create user account on lead form.`} />
                                </div>
                            </CollapsedAreaLabels>
                            <div className={`itemsPerform `}>
                                <MaterialDesignSwitch defaultchecked={formik.values.isAccountEmail} id={"isAccountEmail"} checked={(checked) => { formik.setFieldValue('isAccountEmail', checked) }} />

                            </div>

                        </div>

                    </TopBottomPadding>
                }

                <TopBottomPadding>

                    <div className='d-flex justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Field Type
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform w-50`}>
                            <FieldTypeSelect
                                onChange={(selected) => formik.setFieldValue("fieldType", selected)}
                                defaultValue={formik.values.fieldType}
                                name={"fieldType"}
                                id={"fieldType"} />
                        </div>

                    </div>

                </TopBottomPadding>
                {
                    (formik.values.fieldType === "Select"
                        || formik.values.fieldType === "MultiSelect")
                    && <TopBottomPadding>
                        <CreateOptionsForSelectBox formik={formik} />
                    </TopBottomPadding>
                }
                {
                    formik.values.fieldType === "Slider"
                    && <TopBottomPadding>
                        <SliderSettings formik={formik} />
                    </TopBottomPadding>
                }

                {
                    formik.values.fieldType !== "Select"
                    && formik.values.fieldType !== "MultiSelect"
                    && formik.values.fieldType !== "Slider"
                    && formik.values.fieldType !== "Calendar"
                    && <TopBottomPadding>

                        <div className='d-flex justify-content-between align-items-center'>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                Value Type (Validation)

                            </CollapsedAreaLabels>
                            <div className={`itemsPerform w-50`}>
                                <ValidationTypeSelect onChange={(selected) => formik.setFieldValue("validationType", selected)} defaultValue={formik.values.validationType} name={"validationType"} id={"validationType"} />
                            </div>

                        </div>

                    </TopBottomPadding>
                }
        </div>
    );
}
export default AddEditFormField;

