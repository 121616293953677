import { CollapsedAreaLabels, TopBottomPadding } from 'Components/Common';
import ColorPicker from 'Components/ColorPicker';
import FormFloatingLabel from 'Components/FloatingInput';
import CustomSelect from 'Components/CustomSelect';
import Tooltip from 'Components/Tooltip';
import { ValueNumberFormatOptions } from 'Helpers/Formatters';

const SliderTypeNumeric = (props: {
    formik: FormikType<FormStepResponses>
}) => {
    const formik = props.formik;


    return (
        <>
            <TopBottomPadding>
                <FormFloatingLabel type={'number'} onInput={(value) => formik.setFieldValue(`slider.sliderStartValue`, value)} label={"Slider Start Value"} defaultValue={formik.values.slider?.sliderStartValue} />

            </TopBottomPadding>
            <TopBottomPadding>
                <FormFloatingLabel type={'number'} onInput={(value) => formik.setFieldValue(`slider.sliderEndValue`, value)} label={"Slider End Value"} defaultValue={formik.values.slider?.sliderEndValue} />

            </TopBottomPadding>
            <TopBottomPadding>
                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Start And End Labels Color
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform  `}>
                        <ColorPicker getColor={(color) => {
                            formik.setFieldValue(`slider.startAndEndlabelsColor`, color)
                        }} defaultValue={formik.values.slider?.startAndEndlabelsColor} />
                    </div>

                </div>
            </TopBottomPadding>
            <TopBottomPadding>
                <FormFloatingLabel type={'number'} onInput={(value) => formik.setFieldValue(`slider.sliderStepValue`, value)} label={"Slider Step Value"} defaultValue={formik.values.slider?.sliderStepValue} />

            </TopBottomPadding>
            <TopBottomPadding>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Start Value Format
                    <div className='ms-2'>
                        <Tooltip text={`For example: "$ {}", "{} days". The "{}" part will be replaced with the actual value.`} />
                    </div>
                </CollapsedAreaLabels>
                <FormFloatingLabel onInput={(value) => formik.setFieldValue(`slider.sliderStartValueFormat`, value)} label={"Slider Start Value Format"} defaultValue={formik.values.slider?.sliderStartValueFormat ? formik.values.slider?.sliderStartValueFormat : `{}`} />

            </TopBottomPadding>
            <TopBottomPadding>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    End Value Format
                    <div className='ms-2'>
                        <Tooltip text={`For example: "$ {}", "{} days". The "{}" part will be replaced with the actual value.`} />
                    </div>
                </CollapsedAreaLabels>
                <FormFloatingLabel onInput={(value) => formik.setFieldValue(`slider.sliderEndValueFormat`, value)} label={"Slider End Value Format"} defaultValue={formik.values.slider?.sliderEndValueFormat ? formik.values.slider?.sliderEndValueFormat : `{}`} />

            </TopBottomPadding>
            <TopBottomPadding>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Format
                    <div className='ms-2'>
                        <Tooltip text={`For example: "$ {}", "{} days". The "{}" part will be replaced with the actual value.`} />
                    </div>
                </CollapsedAreaLabels>
                <FormFloatingLabel onInput={(value) => formik.setFieldValue(`slider.sliderValueFormat`, value)} label={"Slider Value Format"} defaultValue={formik.values.slider?.sliderValueFormat ? formik.values.slider?.sliderValueFormat : `{}`} />

            </TopBottomPadding>
            <TopBottomPadding>

                <div className='d-flex justify-content-between align-items-center'>
                    <CollapsedAreaLabels className='d-flex align-items-center'>
                        Slider Value Number Format
                    </CollapsedAreaLabels>
                    <div className={`itemsPerform w-50`}>
                        <CustomSelect data={ValueNumberFormatOptions} onChange={(selected) => formik.setFieldValue("slider.sliderValueNumberFormat", selected)} defaultValue={formik.values.slider?.sliderValueNumberFormat} name={"SliderValueNumberFormat"} id={"SliderValueNumberFormat"} />
                    </div>

                </div>

            </TopBottomPadding>
        </>
    );
}

export default SliderTypeNumeric;