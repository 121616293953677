import { useState } from 'react';
import { BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CollapsedAreaLabels, CommonArea, ResponsesHeader, ResponsesHeaderSpan, TopBottomPadding } from 'Components/Common';
import { FormAdvanceOptions, FormAdvanceOptionsCollapse } from "commonJSON";
import FormFloatingLabel from "Components/FloatingInput";
import MaterialDesignSwitch from "Components/MaterialSwitch";
import { StepsSelect } from 'Components';
import ToolTip from 'Components/Tooltip';
import { setStepOptions, setStepOptionsInner } from "Store/slices/EditFormSlice";
import AddNewformfield from "../../FormFields/AddNewFormField";
import { StepTeaserText } from "./Questionblock";
import { Checkbox, FormControlLabel } from '@mui/material';

const FormBlock = (props: { formik: FormikType<FormSteps>; }) => {
    const { formik } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const stepOptions = useSelector((state: AppStore) => state.editor.stepOptions);

    const [FormAdvanceOptionsList, setFormAdvanceOptionsList] = useState(FormAdvanceOptions);
    const [FormAdvanceOptionsCollapseList, setFormAdvanceOptionsCollapseList] = useState(FormAdvanceOptionsCollapse);

    const showhiddenDivsOnSwitchChange = (index: number, checked: boolean) => {
        const newData = [...FormAdvanceOptionsList];
        newData[index].isSwitch = checked;
        setFormAdvanceOptionsList(newData);
    }

    const stepteaser = (index: number) => {
        const newData = [...FormAdvanceOptionsList];
        newData[index].isSwitch = !newData[index].isSwitch;
        setFormAdvanceOptionsList(newData);
    }

    const collapseDiv = (index: number) => {
        const newData = [...FormAdvanceOptionsCollapseList];
        newData[index].isCollapsed = !newData[index].isCollapsed;
        setFormAdvanceOptionsCollapseList(newData);
    }

    return (
        <>
            <div className='p-3'>
                <div className="mb-3">
                    <FormFloatingLabel label={"Step name*"} onInput={(value) => {
                        formik.setFieldValue("name", value);
                        dispatch(setStepOptions({ name: value }))
                    }} defaultValue={stepOptions?.name} />
                    {formik.errors.name && formik.touched.name && (
                        <small className="text-danger">{formik.errors.name}</small>
                    )}
                </div>
                <TopBottomPadding>
                    <div className='d-flex mb-3 justify-content-between align-items-center'>
                        <CollapsedAreaLabels className='d-flex align-items-center'>
                            Include in SMS?
                            <div className='ms-2'>
                                <ToolTip text={`Enable this field if you'd like to include this step in an SMS lead notification. You'll also need to enable "SMS Notifications" in the lead delivery section of your dashboard.`} />
                            </div>
                        </CollapsedAreaLabels>
                        <div className={`itemsPerform`}>
                            <MaterialDesignSwitch defaultchecked={stepOptions?.includeSMS} id={"IncludeinSMS"} checked={(checked) => dispatch(setStepOptionsInner({ includeSMS: checked }))} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <FormFloatingLabel label={"Form Title*"} onInput={(value) => { formik.setFieldValue("formTitle", value); dispatch(setStepOptionsInner({ formTitle: value })) }} defaultValue={stepOptions?.formTitle} />
                        {formik.errors.formTitle && formik.touched.formTitle && (
                            <small className="text-danger">{formik.errors.formTitle}</small>
                        )}
                    </div>
                    <div className="mb-3">
                        <FormFloatingLabel label={"Step Subtitle"} onInput={(value) => { dispatch(setStepOptionsInner({ subTitle: value })) }} defaultValue={stepOptions?.subTitle} />
                    </div>
                    <div className="mb-3">
                        <FormFloatingLabel label={"Progress"}
                            type='text'
                            regex={/^(0|[1-9][0-9]?|100)$/}
                            onInput={(value) => dispatch(setStepOptionsInner({ progress: Number(value) }))}
                            defaultValue={stepOptions?.progress || 0} />
                    </div>
                    <div className="mb-3">
                        <FormControlLabel control={<Checkbox onChange={e => dispatch(setStepOptionsInner({ isFinalStep: e.target.checked }))} checked={stepOptions?.isFinalStep || false} />} label="Mark as final step?" />
                    </div>
                    <div className="mb-3">
                        <FormFloatingLabel label={"Submit Button label*"} onInput={(value) => { formik.setFieldValue("submitButtonLabel", value); dispatch(setStepOptionsInner({ submitButtonLabel: value })) }} defaultValue={stepOptions?.submitButtonLabel} />
                        {formik.errors.submitButtonLabel && formik.touched.submitButtonLabel && (
                            <small className="text-danger">{formik.errors.submitButtonLabel}</small>
                        )}
                    </div>
                    <div className="mb-3">
                        <StepsSelect defaultValue={stepOptions?.targetStepId} />
                    </div>
                </TopBottomPadding>
                <ResponsesHeader>
                    <ResponsesHeaderSpan>FORM FIELDS</ResponsesHeaderSpan>
                </ResponsesHeader>
                <div className='py-3 text-center'>
                    {!id && <p className='text-muted mb-0'>- Save the step to start adding form fields -</p>}
                    {id && <AddNewformfield />}
                    {id && 
                    <TopBottomPadding>
                     <div className='d-flex mb-3 justify-content-between align-items-center px-3 pt-3'>
                     <CollapsedAreaLabels className='d-flex align-items-center'>
                          Is Repeatable
                         <div className='ms-2'>
                             <ToolTip text={`Repeat Whole Form`} />
                         </div>
                     </CollapsedAreaLabels>
                     <div className={`itemsPerform`}>
                         <MaterialDesignSwitch defaultchecked={stepOptions?.isRepeatable} id={"Custom_Repeatable_tes"} checked={(checked) => dispatch(setStepOptionsInner({ isRepeatable: checked }))} />
                     </div>
     
                 </div>
                 </TopBottomPadding>
                    }
                </div>
                <ResponsesHeader>
                    <ResponsesHeaderSpan>ADVANCED OPTIONS</ResponsesHeaderSpan>
                </ResponsesHeader>
                <div className='py-3'>
                    {FormAdvanceOptionsList.map((childitem, i) =>
                        <div key={'ab_' + childitem.label + i}>
                            <div onClick={() => childitem.label === "Step Teaser Text" ? stepteaser(i) : null} className='d-flex mb-3 justify-content-between align-items-center'>
                                <CollapsedAreaLabels className='d-flex align-items-center'>
                                    {childitem.label}
                                    <div className='ms-2'>
                                        {childitem.tooltip.hasTooltip === true ?
                                            <ToolTip text={childitem.tooltip.title} />
                                            : null}
                                    </div>
                                </CollapsedAreaLabels>
                                <div className={`itemsPerform`}>
                                    {childitem.label === "Step Teaser Text" ? <CommonArea className={`${childitem.isSwitch === true ? "rotateit" : ""}`}>
                                        <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                                    </CommonArea> :
                                        <MaterialDesignSwitch defaultchecked={childitem.isSwitch} id={"switch_" + i} checked={(checked) => showhiddenDivsOnSwitchChange(i, checked)} />
                                    }
                                </div>
                            </div>
                            {childitem.isSwitch === true ?
                                childitem.label === "Step Teaser Text" ? <StepTeaserText /> :
                                    <><TipText>Tip: Wrap the clickable text into two "*" characters, e.g. **Your clickable text** - for example: by clicking the next button you agree to our **TCPA consent**</TipText>
                                        {!!childitem.children && childitem.children.map(x => <div key={'frm1' + x.label + 1.5 * 5} className='mb-3'>  <FormFloatingLabel label={x.label} defaultValue={x.defaultValue} /> </div>)}</>
                                : null
                            }
                        </div>
                    )}
                    {AdditionalFormFields()}
                    {FormAdvanceOptionsCollapseList.map((childitem, i) =>
                        <div key={'ac_' + childitem.label + i} onClick={() => collapseDiv(i)} className='d-flex mb-3 justify-content-between align-items-center'>
                            <CollapsedAreaLabels className='d-flex align-items-center'>
                                {childitem.label}
                                <div className='ms-2'>
                                    {childitem.tooltip.hasTooltip === true ?
                                        <ToolTip text={childitem.tooltip.title} />
                                        : null}
                                </div>
                            </CollapsedAreaLabels>
                            <div className={`itemsPerform`}>
                                <CommonArea className={`${childitem.isCollapsed === true ? "rotateit" : ""}`}>
                                    <BiChevronRight className='rotateAngleIcon' fontSize={28} />
                                </CommonArea>
                            </div>

                            {childitem.isCollapsed === true ?
                                !!childitem.children && childitem.children.map(x => <div className='mb-3' key={'frm2' + x.label + 1.5 * 5}><FormFloatingLabel label={x.label} defaultValue={x.defaultValue} /> </div>)
                                : null
                            }
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default FormBlock;


const AdditionalFormFields = () => {
    const [includeCAPTCHA, setIncludeCAPTCHA] = useState(false);
    return (
        <>
            <div className='mb-3'>  <FormFloatingLabel label={"Additional Text"} defaultValue={''} /> </div>
            <div className='mb-3'>  <FormFloatingLabel label={"GDPR-checkbox Text"} defaultValue={''} /> </div>
            <div className='mb-3'>  <FormFloatingLabel label={'Redirect URL'} defaultValue={''} /> </div>
            <div className='d-flex mb-3 justify-content-between align-items-center'>
                <CollapsedAreaLabels className='d-flex align-items-center'>
                    Invisible reCAPTCHA
                    <div className='ms-2'>
                        <ToolTip text={`This setting enables Google recaptcha on the form field. For this to fully work, you will also need to add your recaptcha site key in the Settings section of your form.`} />
                    </div>
                </CollapsedAreaLabels>
                <div className={`itemsPerform`}>
                    <MaterialDesignSwitch defaultchecked={includeCAPTCHA} id={"includeCAPTCHA"} checked={(checked) => setIncludeCAPTCHA(checked)} />
                </div>
            </div>
        </>

    )
}
const TipText = styled.div`
color: rgb(102, 102, 102);
margin-bottom: 5px;
font-size: 14px;`;